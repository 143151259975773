import axios from "axios";
import { createContext, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

export const MainContext = createContext();

export const GeneralControl = ({ children }) => {
  // Navigate
  const navigate = useNavigate();

  /* ---------------------------------- State --------------------------------- */
  const [mainMenuVisible, setMainMenuVisible] = useState(false);
  const [hotelDetailActiveTab, setHotelDetailActiveTab] = useState("General");

  // SideBar Menu Visible
  const [priceListScheduleVisible, setPriceListScheduleVisible] =
    useState(false);

  const [exchangeRateVisible, setExchangeRateVisible] = useState(false);

  // SideBarVisible
  const [couponVisible, setCouponVisible] = useState(false);
  const [user, setUser] = useState({});

  /* ------------------------------- Page State ------------------------------- */
  //  Coupon Page
  const [couponData, setCouponData] = useState([]);
  const [selectCoupon, setSelectCoupon] = useState({
    coupon_name: "",
    coupon_codeL: "",
    coupon_active: "",
    coupon_start: "",
    coupon_end: "",
    coupon_percentage_value: "",
  });

  // Discount
  const [discountData, setDiscountData] = useState([]);
  const [discountFreeNightSideBar, setDiscountFreeNightSideBar] =
    useState(false);
  const [discountPersentageSideBar, setDiscountPersentageSideBar] =
    useState(false);
  const [selectDiscount, setSelectDiscount] = useState({
    offer_active: "",
    offer_name: "",
    offer_start: "",
    offer_end: "",
    offer_travel_dates_must_be_within_offer_dates: "",
    offer_recurring_period: "",
    offer_free_night_quantity: "",
    offer_percentage_value: "",
  });
  const [sepcialOfferSideBarVisible, setSepcialOfferSideBarVisible] =
    useState(false);
  const [selectSpecialOffer, setSelectSpecialOffer] = useState({
    id: "",
    offer_associated_entries: [],
    offer_active: "",
    offer_name: "",
    offer_public_name_az: "",
    offer_public_name_ru: "",
    offer_public_name_en: "",
    offer_start_month: "",
    offer_start_day: "",
    offer_end_month: "",
    offer_end_day: "",
  });
  const [specialPriceVisible, setSpecialPriceVisible] = useState(false);
  const [specialPriceSelect, setSpecialPriceSelect] = useState({
    entry_price_num_staying_guests: "",
    entry_price_staying_period: "",
    entry_price: "",
    id: "",
    type: "",
  });

  // ExchangeRate
  const [exchangeRateData, setExchangeRateData] = useState([]);

  // Newsletter
  const [newsletterData, setNewsletterData] = useState(null);
  const [selectNewsletter, setSelectNewsletter] = useState({
    member_email: "",
    member_locale: "",
  });
  const [newsletterVisible, setNewsletterVisible] = useState(false);

  // FIle Manage Page
  const [folderData, setFolderData] = useState([]);
  const [imgSideBarVisible, setImgSideBarVisible] = useState(false);
  const [folderSideBarVisible, setFolderSideBarVisible] = useState(false);
  const [folderErrorText, setFolderErrorText] = useState("");

  // Email Template Page
  const [emailData, setEmailData] = useState([]);
  const [selectEmail, setSelectEmail] = useState({
    template_associated_descriptions: [],
  });
  const [emailVisible, setEmailVisible] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  // Comment Page
  const [commentSideBarVisible, setCommentSideBarVisible] = useState(false);
  const [commentData, setCommentData] = useState(null);
  const [selectComment, setSelectComment] = useState({
    id: "",
    review_reviewer_image: "",
    review_reviewer_name: "",
    review_star_rating: "",
    review_reviewer_origin: "",
    review_text: "",
    review_locale: "",
  });
  const [commentActiveTab, setCommentActiveTab] = useState("Hotel reviews");

  // Home Page
  const [homeActiveTab, setHomeActiveTab] = useState("Promotion Banner");
  const [bannerSideBarVisible, setBannerSideBarVisible] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [selectBanner, setSelectBanner] = useState({
    banner_name: "",
    banner_url: "",
    banner_image: "",
  });
  const [naftalanHistoryEditorValue, setNaftalanHistoryEditorValue] = useState({
    az: "",
    en: "",
    ru: "",
  });
  const [diseaseData, setDiseaseData] = useState([]);
  const [selectDisease, setSelectDisease] = useState({
    disease_icon_path: "",
    disease_hero_az: "",
    disease_text_az: "",
    disease_characteristics_az: "",
    disease_hero_en: "",
    disease_text_en: "",
    disease_characteristics_en: "",
    disease_hero_ru: "",
    disease_text_ru: "",
    disease_characteristics_ru: "",
  });
  const [diseaseVisible, setDiseaseVisible] = useState(false);
  const [customPageAllListSideBarVisible, setCustomPageAllListSideBarVisible] =
    useState(false);
  const [customPageAllListData, setCustomPageAllListData] = useState(null);
  const [selectCustomPage, setSelectCustomPage] = useState({
    page_associated_section: "",
    page_name_az: "",
    page_name_en: "",
    page_name_ru: "",
    page_associated_hotels: [],
  });
  const [customPageSelectLocale, setCustomPageSelectLocale] = useState("");
  const [customPageEditorValue, setCustomPageEditorValue] = useState("");

  const [faqData, setFaqData] = useState(null);
  const [selectFaq, setSelectFaq] = useState({
    question_title_az: "",
    question_answer_az: "",
    question_title_ru: "",
    question_answer_ru: "",
    question_title_en: "",
    question_answer_en: "",
    id: "",
  });
  const [faqVisible, setFaqVisible] = useState(false);

  // Attribute Page
  const [attributeData, setAttributeData] = useState([]);
  const [selectAttribute, setSelectAttribute] = useState({
    attribute_associated_group: "",
    attribute_name_az: "",
    attribute_name_en: "",
    attribute_name_ru: "",
    id: "",
  });
  const [selectAttributeGroup, setSelectAttributeGroup] = useState({
    attribute_group: "",
    attribute_group_name_az: "",
    attribute_group_name_en: "",
    attribute_group_name_ru: "",
    id: "",
  });
  const [atributeActiveTab, setAtributeActiveTab] =
    useState("Hotel Attributes");
  const [attributeProccesType, setAttributeProccesType] = useState("");
  const [atributePageSideBarVisible, setAtributePageSideBarVisible] =
    useState(false);
  const [atributeGroupSideBarVisible, setAtributeGroupSideBarVisible] =
    useState(false);
  const [attributeprevNumber, setAttributePrevNumber] = useState(0);
  const [attributeActiveNumber, setAttributeActiveNumber] = useState(1);
  const [attributeNextNumber, setAttributeNextNumber] = useState(2);
  const [attributePaginationData, setAttributePaginationData] = useState(null);
  const [attributeGroupData, setAttributeGroupData] = useState([]);

  // Hotel
  const [hotelData, setHotelData] = useState(null);
  const [hotelCouponSideBarVisible, setHotelCouponSideBarVisible] =
    useState(false);
  const [selectRoomID, setSelectRoomID] = useState(null);
  const [priceListScheduleData, setPriceListScheduleData] = useState([]);
  const [priceList, setPriceList] = useState(null);
  const [minorVisible, setMinorVisible] = useState(false);
  const [standartVisible, setStandartVisible] = useState(false);
  const [additionVisible, setAdditionVisible] = useState(false);
  const [hotelMainPhoto, setHotelMainPhoto] = useState(null);
  const [hotelAdditionPhoto, setHotelAdditionPhoto] = useState([]);
  const [addPhotoVisible, setAddPhotoVisible] = useState({
    type: "",
    visible: false,
  });
  const [hotelDEscriptionLocale, setHotelDEscriptionLocale] = useState("az");
  const [hotelDescriptionEditorValue, setHotelDescriptionEditorValue] =
    useState("");
  const [roomDescriptionNoteEditorValue, setRoomDescriptionNoteEditorValue] =
    useState("");

  // Hotel Rooms
  const [roomData, setRoomData] = useState({
    suite_active: "",
    suite_min_capacity: "",
    suite_max_capacity: "",
    suite_additional_guest_after: "",
    suite_type: "",
    suite_pricing_type: "",
    suite_associated_hotel: "",
    suite_associated_main_image: "",
    suite_associated_images: "",
    suite_associated_attributes: "",
    suite_associated_special_offer_percentages: "",
    suite_associated_special_offer_free_nights: "",
    suite_associated_descriptions: [],
  });
  const [roomMainPhoto, setRoomMainPhoto] = useState(null);
  const [roomAdditionPhoto, setRoomAdditionPhoto] = useState([]);
  const [offerFreeNightVisible, setOfferFreeNightVisible] = useState(false);
  const [offerPersentageVisible, setOfferPersentageVisible] = useState(false);
  const [roomSpecialOfferVisible, setRoomSpecialOfferVisible] = useState(false);

  // Setting
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [socialMediaSideBarVisible, setSocialMediaSideBarVisible] =
    useState(false);
  const [selectSocialMedia, setSelectSocialMedia] = useState({
    social_media_path: "",
    social_url: "",
    id: "",
  });

  /* --------------------------------- Funtion -------------------------------- */
  // Get Hotel Attribute Data
  const getHotelAttribute = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_HOTEL_ATTRIBUTE_READ_ALL}?page_length=20&page_number=${page_number}`
        )
        .then((res) => {
          setAttributeData(res.data.page_data);
          setAttributePaginationData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                getHotelAttribute(page_number);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            navigate("/login");
          }
        });
    },
    [navigate, setAttributeData, setAttributePaginationData]
  );

  // Get Percentage Night Data
  const getSuitAttribute = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_HOTEL_SUIT_ATTRIBUTE_READ_ALL}?page_length=20&page_number=${page_number}`
        )
        .then((res) => {
          setAttributeData(res.data.page_data);
          setAttributePaginationData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                getSuitAttribute(page_number);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            navigate("/login");
          }
        });
    },
    [navigate, setAttributeData, setAttributePaginationData]
  );

  // All Hotel Attribute Group Get Request
  const getAllHottelAtributeGroup = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_SITE_HOTEL_ATTRIBUTE_GROUP_READ_ALL)
      .then((res) => setAttributeGroupData(res.data))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getAllHottelAtributeGroup();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  // Search Hotel Attribute Group Get Request
  const searchHotelAttributeGroup = useCallback(
    async (inputValue) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_HOTEL_ATTRIBUTE_GROUP_SEARCH}/${inputValue}`
        )
        .then((res) => setAttributeGroupData(res.data))
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                searchHotelAttributeGroup(inputValue);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            navigate("/login");
          }
        });
    },
    [navigate]
  );
  // All Hotel Attribute Group Get Request
  const getAllSuitAtributeGroup = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_SITE_SUIT_ATTRIBUTE_GROUP_READ_ALL)
      .then((res) => setAttributeGroupData(res.data))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getAllSuitAtributeGroup();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  // Search Hotel Attribute Group Get Request
  const searchSuitAttributeGroup = useCallback(
    async (inputValue) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_SUIT_ATTRIBUTE_GROUP_SEARCH}/${inputValue}`
        )
        .then((res) => setAttributeGroupData(res.data))
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                searchSuitAttributeGroup(inputValue);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            navigate("/login");
          }
        });
    },
    [navigate]
  );

  const globalStates = {
    // State
    mainMenuVisible,
    setMainMenuVisible,
    hotelDetailActiveTab,
    setHotelDetailActiveTab,
    /* ------------------------------- Page State ------------------------------- */

    //Coupon Data
    couponData,
    setCouponData,
    selectCoupon,
    setSelectCoupon,

    // DiscountData
    discountData,
    setDiscountData,
    selectDiscount,
    setSelectDiscount,
    discountFreeNightSideBar,
    setDiscountFreeNightSideBar,
    discountPersentageSideBar,
    setDiscountPersentageSideBar,
    sepcialOfferSideBarVisible,
    setSepcialOfferSideBarVisible,
    selectSpecialOffer,
    setSelectSpecialOffer,
    specialPriceVisible,
    setSpecialPriceVisible,
    specialPriceSelect,
    setSpecialPriceSelect,

    // ExchangeRate
    exchangeRateData,
    setExchangeRateData,

    // Newsletter
    newsletterData,
    setNewsletterData,
    selectNewsletter,
    setSelectNewsletter,
    newsletterVisible,
    setNewsletterVisible,

    // File Manager Page
    folderData,
    setFolderData,
    imgSideBarVisible,
    setImgSideBarVisible,
    folderSideBarVisible,
    setFolderSideBarVisible,
    folderErrorText,
    setFolderErrorText,

    // Email Page
    emailData,
    setEmailData,
    selectEmail,
    setSelectEmail,
    emailVisible,
    setEmailVisible,
    emailErrorText,
    setEmailErrorText,

    // Comment Page
    commentSideBarVisible,
    setCommentSideBarVisible,
    commentData,
    setCommentData,
    selectComment,
    setSelectComment,
    commentActiveTab,
    setCommentActiveTab,

    // Home Page
    homeActiveTab,
    setHomeActiveTab,
    bannerData,
    setBannerData,
    bannerSideBarVisible,
    setBannerSideBarVisible,
    selectBanner,
    setSelectBanner,
    diseaseData,
    setDiseaseData,
    selectDisease,
    setSelectDisease,
    diseaseVisible,
    setDiseaseVisible,
    customPageAllListSideBarVisible,
    setCustomPageAllListSideBarVisible,
    customPageAllListData,
    setCustomPageAllListData,
    selectCustomPage,
    setSelectCustomPage,
    hotelDEscriptionLocale,
    setHotelDEscriptionLocale,
    customPageSelectLocale,
    setCustomPageSelectLocale,
    customPageEditorValue,
    setCustomPageEditorValue,
    naftalanHistoryEditorValue,
    setNaftalanHistoryEditorValue,

    faqData,
    setFaqData,
    selectFaq,
    setSelectFaq,
    faqVisible,
    setFaqVisible,

    // SideBar Menu Visible
    priceListScheduleVisible,
    setPriceListScheduleVisible,
    exchangeRateVisible,
    setExchangeRateVisible,
    couponVisible,
    setCouponVisible,

    // Attribute PAge
    attributeData,
    setAttributeData,
    selectAttribute,
    setSelectAttribute,
    atributePageSideBarVisible,
    setAtributePageSideBarVisible,
    atributeGroupSideBarVisible,
    setAtributeGroupSideBarVisible,
    selectAttributeGroup,
    setSelectAttributeGroup,
    atributeActiveTab,
    setAtributeActiveTab,
    attributeProccesType,
    setAttributeProccesType,
    attributeprevNumber,
    setAttributePrevNumber,
    attributeActiveNumber,
    setAttributeActiveNumber,
    attributeNextNumber,
    setAttributeNextNumber,
    attributePaginationData,
    setAttributePaginationData,
    attributeGroupData,
    setAttributeGroupData,

    // Hotel
    hotelData,
    setHotelData,
    hotelCouponSideBarVisible,
    setHotelCouponSideBarVisible,
    selectRoomID,
    setSelectRoomID,
    priceListScheduleData,
    setPriceListScheduleData,
    priceList,
    setPriceList,
    minorVisible,
    setMinorVisible,
    standartVisible,
    setStandartVisible,
    additionVisible,
    setAdditionVisible,
    hotelMainPhoto,
    setHotelMainPhoto,
    hotelAdditionPhoto,
    setHotelAdditionPhoto,
    addPhotoVisible,
    setAddPhotoVisible,
    hotelDescriptionEditorValue,
    setHotelDescriptionEditorValue,

    // Hotel Room
    roomData,
    setRoomData,
    roomMainPhoto,
    setRoomMainPhoto,
    roomAdditionPhoto,
    setRoomAdditionPhoto,
    offerFreeNightVisible,
    setOfferFreeNightVisible,
    offerPersentageVisible,
    setOfferPersentageVisible,
    roomSpecialOfferVisible,
    setRoomSpecialOfferVisible,
    roomDescriptionNoteEditorValue,
    setRoomDescriptionNoteEditorValue,

    // Setting
    socialMediaData,
    setSocialMediaData,
    socialMediaSideBarVisible,
    setSocialMediaSideBarVisible,
    selectSocialMedia,
    setSelectSocialMedia,

    user,
    setUser,

    // Funtion
    getHotelAttribute,
    getSuitAttribute,
    getAllHottelAtributeGroup,
    searchHotelAttributeGroup,
    getAllSuitAtributeGroup,
    searchSuitAttributeGroup,
  };
  return (
    <MainContext.Provider value={globalStates}>{children}</MainContext.Provider>
  );
};
