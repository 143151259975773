// React
import { useContext } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Components

// Icon
import trash from "../../assets/images/icon/trash.svg";
import add from "../../assets/images/icon/add-plus.svg";
import { createRemoveAlert } from "../../utils/SweetAlert";
import axios from "axios";

import RoomSpecialOfferSideBar from "../../components/side-bar-menu/RoomSpecialOfferSideBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const RoomSpecialOffer = () => {
  // Global State
  const {
    // Visible State
    mainMenuVisible,
    setMainMenuVisible,
    roomSpecialOfferVisible,
    setRoomSpecialOfferVisible,
    selectRoomID,
    roomData,
    setRoomData,
  } = useContext(MainContext);

  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Delete Hotel
  const removeData = (dataId, offer) => {
    createRemoveAlert(
      "Delete Special offer!",
      `Are you sure you want to delete the ${offer} Special offer?`,
      "Yes, Remove",
      async () => {
        const formData = new FormData();
        let UpdatedData = roomData;

        const keys = Object.keys(UpdatedData);

        keys.forEach((key) => {
          formData.append(key, UpdatedData[key]);
        });

        formData.set(
          "suite_associated_main_image",
          roomData.suite_associated_main_image
            ? roomData.suite_associated_main_image.id
            : ""
        );
        formData.set(
          "suite_associated_images",
          roomData.suite_associated_images
            ? roomData.suite_associated_images.map((item) => item.id).join(",")
            : ""
        );

        formData.set(
          "suite_associated_special_offer_free_nights",
          roomData.suite_associated_special_offer_free_nights
            ? roomData.suite_associated_special_offer_free_nights
                .map((item) => item.id)
                .join(",")
            : ""
        );
        formData.set(
          "suite_associated_attributes",
          roomData.suite_associated_attributes
            ? roomData.suite_associated_attributes
                .map((item) => item.id)
                .join(",")
            : ""
        );
        formData.set(
          "suite_associated_special_offer_percentages",
          roomData.suite_associated_special_offer_percentages
            ? roomData.suite_associated_special_offer_percentages
                .map((item) => item.id)
                .join(",")
            : ""
        );
        formData.set("suite_associated_special_offer_special_price", "");
        // for (const [key, value] of formData.entries()) {
        //   console.log(key, value);
        // }

        await axios
          .put(
            `${process.env.REACT_APP_SITE_ROOM_UPTADE}${selectRoomID}`,
            formData
          )
          .then((res) => {
            if (res) {
              setRoomData({
                ...roomData,
                suite_associated_special_offer_special_price: "",
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId ? dataId : "", offer);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };
  return (
    <div className="artibute-area boxes-style">
      <div className="atribute-head">
        <div className="add-area">
          <h4 className="add-caption">
            {!roomData.suite_associated_special_offer_special_price
              ? "Add"
              : ""}{" "}
            Special Offer
          </h4>{" "}
          {!roomData.suite_associated_special_offer_special_price && (
            <button
              className="add-btn"
              onClick={() => setRoomSpecialOfferVisible(true)}
            >
              Add <img src={add} alt="add" />
            </button>
          )}
        </div>
      </div>
      <div className="atribute-body">
        <table className="table">
          <thead>
            <tr>
              <th className="first">id</th>
              <th className="first">Offer Name</th>
              <th className="first">Period </th>
              <th className="edit"> Delete </th>
            </tr>
          </thead>
          <tbody>
            {roomData.suite_associated_special_offer_special_price && (
              <tr
                key={roomData.suite_associated_special_offer_special_price.id}
              >
                <td className="first">
                  {roomData.suite_associated_special_offer_special_price.id}
                </td>
                <td className="first">
                  {
                    roomData.suite_associated_special_offer_special_price
                      .offer_name
                  }
                </td>
                <td className="first">
                  {
                    roomData.suite_associated_special_offer_special_price
                      .offer_start_day
                  }
                  -
                  {
                    roomData.suite_associated_special_offer_special_price
                      .offer_start_month
                  }
                  /
                  {
                    roomData.suite_associated_special_offer_special_price
                      .offer_end_day
                  }
                  -
                  {
                    roomData.suite_associated_special_offer_special_price
                      .offer_end_month
                  }
                </td>
                <td className="edit">
                  <div className="btn-area">
                    <button
                      onClick={() =>
                        removeData(
                          roomData.suite_associated_special_offer_special_price
                            .id,
                          roomData.suite_associated_special_offer_special_price
                            .offer_name
                        )
                      }
                    >
                      <img src={trash} alt="delete" />
                    </button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <RoomSpecialOfferSideBar />
      <div
        className={
          roomSpecialOfferVisible || mainMenuVisible
            ? "overlay-sub-menu active"
            : "overlay-sub-menu"
        }
        onClick={() => {
          mainMenuVisible
            ? setMainMenuVisible(false)
            : setRoomSpecialOfferVisible(false);
        }}
      ></div>
    </div>
  );
};

export default RoomSpecialOffer;
