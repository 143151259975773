// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { boolean, object, string } from "yup";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import arrow from "../../assets/images/icon/arrow-left.svg";
import add from "../../assets/images/icon/add-plus.svg";
import edit from "../../assets/images/icon/edit.svg";
import trash from "../../assets/images/icon/trash.svg";
import { createRemoveAlert } from "../../utils/SweetAlert";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const SpecialOfferSideBar = ({ type }) => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    discountData,
    setDiscountData,
    sepcialOfferSideBarVisible,
    setSepcialOfferSideBarVisible,
    selectSpecialOffer,
    setSelectSpecialOffer,
    setSpecialPriceVisible,
    setSpecialPriceSelect,
  } = useContext(MainContext);
  const { logout } = useAuth();

  //   Local State
  const [errorText, setErrorText] = useState("");

  // Yup schema
  const attributeSchema = object({
    offer_active_special: boolean().nullable().optional(),
    offer_name_special: string().required().trim(),
    offer_public_name_az: string().required().trim(),
    offer_public_name_en: string().required().trim(),
    offer_public_name_ru: string().required().trim(),
    offer_start_month: string().required().trim(),
    offer_start_day: string().required().trim(),
    offer_end_month: string().required().trim(),
    offer_end_day: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(attributeSchema),
  });

  useEffect(() => {
    setErrorText("");
    reset();
    if (sepcialOfferSideBarVisible && type === "edit") {
      setValue("offer_active_special", selectSpecialOffer.offer_active);
      setValue("offer_name_special", selectSpecialOffer.offer_name);
      setValue("offer_public_name_az", selectSpecialOffer.offer_public_name_az);
      setValue("offer_public_name_en", selectSpecialOffer.offer_public_name_en);
      setValue("offer_public_name_ru", selectSpecialOffer.offer_public_name_ru);
      setValue("offer_start_month", selectSpecialOffer.offer_start_month);
      setValue("offer_start_day", selectSpecialOffer.offer_start_day);
      setValue("offer_end_month", selectSpecialOffer.offer_end_month);
      setValue("offer_end_day", selectSpecialOffer.offer_end_day);
    }
  }, [
    reset,
    selectSpecialOffer.offer_active,
    selectSpecialOffer.offer_active_special,
    selectSpecialOffer.offer_end_day,
    selectSpecialOffer.offer_end_month,
    selectSpecialOffer.offer_name,
    selectSpecialOffer.offer_name_special,
    selectSpecialOffer.offer_public_name_az,
    selectSpecialOffer.offer_public_name_en,
    selectSpecialOffer.offer_public_name_ru,
    selectSpecialOffer.offer_start_day,
    selectSpecialOffer.offer_start_month,
    sepcialOfferSideBarVisible,
    setValue,
    type,
  ]);

  const submit = async (data) => {
    const body = new FormData();
    body.append("offer_name", data.offer_name_special);
    body.append("offer_active", data.offer_active_special);
    body.append("offer_public_name_az", data.offer_public_name_az);
    body.append("offer_public_name_en", data.offer_public_name_en);
    body.append("offer_public_name_ru", data.offer_public_name_ru);
    body.append("offer_start_month", data.offer_start_month);
    body.append("offer_start_day", data.offer_start_day);
    body.append("offer_end_month", data.offer_end_month);
    body.append("offer_end_day", data.offer_end_day);

    if (type === "edit") {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SITE_SPECIAL_OFFER_UPDATE}/${selectSpecialOffer.id}            `,
          body
        );
        setDiscountData((prevData) => {
          return prevData.map((item) => {
            if (item.id === res.data.id) {
              return res.data;
            }
            return item;
          });
        });

        setSepcialOfferSideBarVisible(false);
        setSelectSpecialOffer({
          id: "",
          offer_associated_entries: [],
          offer_active: "",
          offer_name: "",
          offer_public_name_az: "",
          offer_public_name_ru: "",
          offer_public_name_en: "",
          offer_start_month: "",
          offer_start_day: "",
          offer_end_month: "",
          offer_end_day: "",
        });
        notify("Special Offer Update!");
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              submit(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      }
    } else {
      await axios
        .post(process.env.REACT_APP_SITE_SPECIAL_OFFER_CREATE, body)
        .then((res) => {
          setDiscountData([res.data, ...discountData]);
          reset();
          setSepcialOfferSideBarVisible(false);
          setSelectSpecialOffer({
            id: "",
            offer_associated_entries: [],
            offer_active: "",
            offer_name: "",
            offer_public_name_az: "",
            offer_public_name_ru: "",
            offer_public_name_en: "",
            offer_start_month: "",
            offer_start_day: "",
            offer_end_month: "",
            offer_end_day: "",
          });
          notify("Special Offer Create!");
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                submit(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        });
    }
  };

  // Delete Coupon
  const removeData = (dataId) => {
    createRemoveAlert(
      "Delete Price!",
      "Are you sure you want to delete the Price?",
      "Yes, Remove",
      async () => {
        await axios
          .delete(
            `${process.env.REACT_APP_SITE_SPECIAL_OFFER_PRICE_DELETE}/${dataId}`
          )
          .then((res) => {
            if (res.status === 200) {
              selectSpecialOffer.offer_associated_entries =
                selectSpecialOffer.offer_associated_entries.filter(
                  (item) => item.id !== dataId
                );

              const newData = discountData.map((discount) => {
                if (discount.id === selectSpecialOffer.id) {
                  return selectSpecialOffer;
                }
                return discount;
              });
              setDiscountData(newData);
              setSelectSpecialOffer(selectSpecialOffer);
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };
  return (
    <div
      className={
        sepcialOfferSideBarVisible
          ? " isOpenMenu sidebar-special-offer-menu  scroll"
          : "sidebar-special-offer-menu   scroll"
      }
    >
      <div className="head">
        <h4 className="caption">Special Offer</h4>
        <div
          className="icon"
          onClick={() => {
            setSepcialOfferSideBarVisible(false);
            setSelectSpecialOffer({
              id: "",
              offer_associated_entries: [],
              offer_active: "",
              offer_name: "",
              offer_public_name_az: "",
              offer_public_name_ru: "",
              offer_public_name_en: "",
              offer_start_month: "",
              offer_start_day: "",
              offer_end_month: "",
              offer_end_day: "",
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="special-offer-form"
          onSubmit={handleSubmit(submit)}
        >
          <div className="form-group">
            <label htmlFor="offer_name_special" className="inp-caption">
              Offer Name
            </label>
            <input
              type="text"
              className={errors.offer_name_special ? "inp error" : "inp"}
              name="offer_name_special"
              id="offer_name_special"
              maxLength={254}
              {...register("offer_name_special", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="side-bar-divader"></div>
          <div className="form-group">
            <label htmlFor="offer_public_name_az" className="inp-caption">
              Offer Name (Az)
            </label>
            <input
              type="text"
              className={errors.offer_public_name_az ? "inp error" : "inp"}
              name="offer_public_name_az"
              id="offer_public_name_az"
              maxLength={254}
              {...register("offer_public_name_az", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="offer_public_name_en" className="inp-caption">
              Offer Name (En)
            </label>
            <input
              type="text"
              className={errors.offer_public_name_en ? "inp error" : "inp"}
              name="offer_public_name_en"
              id="offer_public_name_en"
              maxLength={254}
              {...register("offer_public_name_en", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="offer_public_name_ru" className="inp-caption">
              Offer Name (Ru)
            </label>
            <input
              type="text"
              className={errors.offer_public_name_ru ? "inp error" : "inp"}
              name="offer_public_name_ru"
              id="offer_public_name_ru"
              maxLength={254}
              {...register("offer_public_name_ru", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="side-bar-divader"></div>

          <div className="price-group">
            <h3 className="price-caption">Period </h3>
            <div className="inp-group">
              <div className="form-group">
                <label htmlFor="offer_start_month" className="inp-caption">
                  Start Month
                </label>
                <input
                  type="number"
                  name="offer_start_month"
                  id="offer_start_month"
                  className={errors.offer_start_month ? "inp error" : "inp"}
                  {...register("offer_start_month", {
                    onChange: (e) => {
                      errorText !== "" && setErrorText("");
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="offer_start_day" className="inp-caption">
                  Start Day
                </label>
                <input
                  type="number"
                  name="offer_start_day"
                  id="offer_start_day"
                  className={errors.offer_start_day ? "inp error" : "inp"}
                  {...register("offer_start_day", {
                    onChange: (e) => {
                      errorText !== "" && setErrorText("");
                    },
                  })}
                />
              </div>
            </div>
            <div className="inp-group">
              <div className="form-group">
                <label htmlFor="offer_end_month" className="inp-caption">
                  End Month
                </label>
                <input
                  type="number"
                  name="offer_end_month"
                  id="offer_end_month"
                  className={errors.offer_end_month ? "inp error" : "inp"}
                  {...register("offer_end_month", {
                    onChange: (e) => {
                      errorText !== "" && setErrorText("");
                    },
                  })}
                />
              </div>

              <div className="form-group">
                <label htmlFor="offer_end_day" className="inp-caption">
                  End Day
                </label>
                <input
                  type="number"
                  name="offer_end_day"
                  id="offer_end_day"
                  className={errors.offer_end_day ? "inp error" : "inp"}
                  {...register("offer_end_day", {
                    onChange: (e) => {
                      errorText !== "" && setErrorText("");
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className="side-bar-divader"></div>
          <div className="form-group checkbox-group">
            <label htmlFor="offer_active_special" className="inp-caption">
              Activated {""}
            </label>
            <label htmlFor="offer_active_special" className="switch">
              <input
                type="checkbox"
                id="offer_active_special"
                name="offer_active_special"
                className="checkbox"
                {...register("offer_active_special")}
              />
              <span className="slider round"></span>
            </label>
          </div>
          {type === "edit" && selectSpecialOffer && (
            <>
              <div className="side-bar-divader"></div>
              <div className="price-list-tab">
                <div className="tab-head">
                  <div className="tab-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="main-tab">Number of guests</th>
                          <th className="main-tab">Staying period</th>
                          <th className="main-tab">Price</th>

                          <th className="edit">Edit </th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectSpecialOffer.offer_associated_entries.map(
                          (offer) => (
                            <tr key={offer.id}>
                              <td className="main-tab">
                                {offer.entry_price_num_staying_guests}
                              </td>
                              <td className="main-tab">
                                {offer.entry_price_staying_period}
                              </td>
                              <td className="main-tab">{offer.entry_price}</td>

                              <td className="edit">
                                <div className="btn-area">
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      removeData(offer.id);
                                    }}
                                  >
                                    <img src={trash} alt="trash" />
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSpecialPriceVisible(true);
                                      setSpecialPriceSelect({
                                        entry_price_num_staying_guests:
                                          offer.entry_price_num_staying_guests,
                                        entry_price_staying_period:
                                          offer.entry_price_staying_period,
                                        entry_price: offer.entry_price,
                                        id: offer.id,
                                        type: "edit",
                                      });
                                    }}
                                  >
                                    <img src={edit} alt="edit" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="add-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setSpecialPriceVisible(true);
                      setSpecialPriceSelect({
                        entry_price_num_staying_guests: "",
                        entry_price_staying_period: "",
                        entry_price: "",
                        id: "",
                        type: "create",
                      });
                    }}
                  >
                    Add line <img src={add} alt="add" />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="form-footer">
            <button>Save</button>
            <p className="error-text">{errorText}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SpecialOfferSideBar;
