// React
import { useCallback, useEffect, useState } from "react";

// Axios
import axios from "axios";

// Icon
import addFile from "../../assets/images/icon/add-btn.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const Logo = () => {
  // Global State
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Back Data
  const [logoImgData, setLogoImgData] = useState(null);
  const [logoImgError, setLogoImgError] = useState(null);
  const [logoImage, setLogoImage] = useState("");
  const [logoPreview, setLogoPreview] = useState("");

  //Photo Choose Logo
  const handleLogoImg = (e) => {
    // Check if files were selected
    if (!e.target.files || e.target.files.length === 0) {
      console.error("No file selected.");
      return;
    }

    let file = e.target.files[0];
    setLogoImage(file);

    let reader = new FileReader();

    reader.onload = () => {
      setLogoPreview(reader.result);
    };

    reader.onerror = (err) => {
      console.error("FileReader error:", err);
    };

    reader.readAsDataURL(file);
  };

  // Set Logo
  const handleLogoSubmit = async (e) => {
    e.preventDefault();
    const logo = new FormData();
    logo.append("logo", logoImage);

    // for (const [key, value] of logo.entries()) {
    //   console.log(key, value);
    // }
    await axios
      .put(process.env.REACT_APP_SITE_LOGO_UPDATE, logo)
      .then((res) => {
        setLogoImgData(res.data.logo);
        setLogoPreview("");
        Notification("Logo Update!");
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              handleLogoSubmit(e);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setLogoImgError(err);
        }
      });
  };

  // Get Logo Data
  const getLogoData = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_SITE_LOGO_READ)
      .then((res) => {
        setLogoImgData(res.data.logo);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getLogoData();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [navigate, logout]);

  // Rendering Data
  useEffect(() => {
    getLogoData();
  }, [getLogoData]);
  return (
    <form className="image-area boxes-style">
      <h2 className="main-caption">Logo</h2>
      <div className="form-group">
        <label htmlFor="add-logo-photo" className="add">
          <div className="icon">
            <img src={addFile} alt="addfile" />
          </div>
          Choose file
        </label>
        <input
          type="file"
          name="add-logo-photo"
          id="add-logo-photo"
          onChange={handleLogoImg}
        />
      </div>
      <div className="img-cards-area">
        <div className="img-card">
          <div className="top"></div>
          <div className="middle">
            <img
              src={
                logoPreview
                  ? logoPreview
                  : `${process.env.REACT_APP_IMAGE}${logoImgData}`
              }
              alt="logo"
            />
          </div>
          <div className="bottom"></div>
        </div>
      </div>
      <div className="form-footer">
        {logoPreview && (
          <>
            {logoImgError && (
              <p className="error-text">Update failed, please check again</p>
            )}

            <button onClick={(e) => handleLogoSubmit(e)}>Save</button>
          </>
        )}
      </div>
    </form>
  );
};

export default Logo;
