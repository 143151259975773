// React Router Dom
import { NavLink } from "react-router-dom";

// Icon
import arrow from "../assets/images/icon/arrow-left.svg";
import { useContext } from "react";
import { MainContext } from "../utils/MainContext";

const SideBarMainMenu = () => {
  // Global State
  const { mainMenuVisible, setMainMenuVisible } = useContext(MainContext);
  return (
    <div
      className={
        mainMenuVisible
          ? " isOpenMenu sidebar-main-menu scroll"
          : "  sidebar-main-menu scroll"
      }
    >
      <div className="head">
        <h4 className="caption">Menu</h4>
        <div className="icon" onClick={() => setMainMenuVisible(false)}>
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <ul className="menu-list">
          <li className="menuItem">
            <NavLink
              to="/reservation"
              onClick={() => setMainMenuVisible(false)}
            >
              Reservations
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink to="/hotel" onClick={() => setMainMenuVisible(false)}>
              Hotels
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink to="/website-pages-and-content" onClick={() => setMainMenuVisible(false)}>
              Website Pages and content
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink to="/seo" onClick={() => setMainMenuVisible(false)}>
              SEO
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink to="/comment" onClick={() => setMainMenuVisible(false)}>
              Reviews
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink
              to="/exchange-rate"
              onClick={() => setMainMenuVisible(false)}
            >
              Currency exchange rates
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink
              to="/email-templates"
              onClick={() => setMainMenuVisible(false)}
            >
              Email templates
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink
              to="/coupon-code-overview"
              onClick={() => setMainMenuVisible(false)}
            >
              Coupons
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink to="/discount" onClick={() => setMainMenuVisible(false)}>
              Discounts and Special Offers
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink to="/photos" onClick={() => setMainMenuVisible(false)}>
              Media
            </NavLink>
          </li>
          {/* <li className="menuItem">
            <NavLink to="/newsletter" onClick={() => setMainMenuVisible(false)}>
              Newsletter member
            </NavLink>
          </li> */}
          <li className="menuItem">
            <NavLink to="/atributes" onClick={() => setMainMenuVisible(false)}>
              Hotel and Suite attributes
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink to="/contact-us" onClick={() => setMainMenuVisible(false)}>
              Contact from messages
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink to="/settings" onClick={() => setMainMenuVisible(false)}>
              Website settings
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBarMainMenu;
