import React, { useCallback, useContext, useEffect, useState } from "react";

// Icon
import down from "../../assets/images/icon/arrow-down.svg";
import addFile from "../../assets/images/icon/add-btn.svg";

import { MainContext } from "../../utils/MainContext";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import Editor from "../../components/Editor";
import { useAuth } from "../../utils/AuthContext";

const NaftalanHistory = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    homeActiveTab,
    naftalanHistoryEditorValue,
    setNaftalanHistoryEditorValue,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [errorText, setErrorText] = useState("");
  const [accordionVisible, setAccordionVisible] = useState("az");
  const [errorImg, seterrorImg] = useState(false);
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");

  const handleImg = (e) => {
    // Check if files were selected
    if (!e.target.files || e.target.files.length === 0) {
      console.error("No file selected.");
      return;
    }
    if (errorImg) {
      seterrorImg(false);
    }

    let file = e.target.files[0];
    setImage(file);

    let reader = new FileReader();

    reader.onload = () => {
      setPreview(reader.result);
    };

    reader.onerror = (err) => {
      console.error("FileReader error:", err);
    };

    reader.readAsDataURL(file);
  };

  const getNaftalanHistoryData = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_SITE_NAFTALAN_HISTORY_READ)
      .then((res) => {
        setImage(res.data.history_image);
        setNaftalanHistoryEditorValue({
          az: res.data.history_text_az,
          en: res.data.history_text_en,
          ru: res.data.history_text_ru,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && getNaftalanHistoryData())
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [navigate, setNaftalanHistoryEditorValue, logout]);

  useEffect(() => {
    if (homeActiveTab === "Naftalan History Page") {
      setErrorText();
      setPreview("");
      setNaftalanHistoryEditorValue({ az: "", en: "", ru: "" });
      getNaftalanHistoryData();
    }
  }, [getNaftalanHistoryData, homeActiveTab, setNaftalanHistoryEditorValue]);

  const editDescription = async () => {
    const formData = new FormData();
    formData.append("history_image", image);
    formData.append("history_text_az", naftalanHistoryEditorValue.az);
    formData.append("history_text_en", naftalanHistoryEditorValue.en);
    formData.append("history_text_ru", naftalanHistoryEditorValue.ru);

    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    await axios
      .put(process.env.REACT_APP_SITE_NAFTALAN_HISTORY_UPDATE, formData)
      .then((res) => res && notify("Naftalan History Update!"))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              editDescription();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      });
  };

  return (
    <div className="naftalan-history ">
      <div className="container">
        <form action="" className="naftalan-history-form ">
          <div className="image-area">
            <div className="form-group">
              <label htmlFor="add-logo-photo" className="add">
                <div className="icon">
                  <img src={addFile} alt="addfile" />
                </div>
                Choose file
              </label>
              <input
                type="file"
                name="add-logo-photo"
                id="add-logo-photo"
                onChange={handleImg}
              />
            </div>
            <div className="img-cards-area">
              <div className="img-card">
                <div className="top"></div>
                <div className="middle">
                  <img
                    src={
                      preview
                        ? preview
                        : `${process.env.REACT_APP_IMAGE}${image}`
                    }
                    alt="logo"
                  />
                </div>
                <div className="bottom"></div>
              </div>
            </div>
          </div>

          <div className="description-acordion-area">
            <div
              className={
                accordionVisible === "az"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "az" ? "" : "az")
                }
              >
                <h3 className="acordion-caption">Azerbaijan</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{ height: accordionVisible === "az" ? 550 : 0 }}
              >
                <div className="form-group">
                  <label htmlFor="history_text_az">Text</label>
                  <Editor type={"naftalan-history"} locale={"az"} />
                </div>
              </div>
            </div>
            <div
              className={
                accordionVisible === "en"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "en" ? "" : "en")
                }
              >
                <h3 className="acordion-caption">English</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{ height: accordionVisible === "en" ? 550 : 0 }}
              >
                <div className="form-group">
                  <label htmlFor="history_text_en">Text</label>
                  <Editor type={"naftalan-history"} locale={"en"} />
                </div>
              </div>
            </div>
            <div
              className={
                accordionVisible === "ru"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "ru" ? "" : "ru")
                }
              >
                <h3 className="acordion-caption">Russian</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{ height: accordionVisible === "ru" ? 550 : 0 }}
              >
                <div className="form-group">
                  <label htmlFor="history_text_ru">Text</label>
                  <Editor type={"naftalan-history"} locale={"ru"} />
                </div>
              </div>
            </div>
          </div>
          <div className="form-footer">
            <div className="handle-area">
              <p className="error-text">{errorText}</p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    naftalanHistoryEditorValue.az === "" ||
                    naftalanHistoryEditorValue.az === "<p><br></p>"
                  ) {
                    setAccordionVisible("az");
                  } else if (
                    naftalanHistoryEditorValue.en === "" ||
                    naftalanHistoryEditorValue.en === "<p><br></p>"
                  ) {
                    setAccordionVisible("en");
                  } else if (
                    naftalanHistoryEditorValue.ru === "" ||
                    naftalanHistoryEditorValue.ru === "<p><br></p>"
                  ) {
                    setAccordionVisible("ru");
                  } else {
                    editDescription();
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NaftalanHistory;
