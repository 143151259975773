import edit from "../../assets/images/icon/edit.svg";
import { useCallback, useContext, useEffect } from "react";
import { MainContext } from "../../utils/MainContext";
import PriceListScheduleSideBarMenu from "../../components/side-bar-menu/PriceListScheduleSideBarMenu";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const HotelDEtailPriceListSchedule = () => {
  // Params
  const { hotelId } = useParams();

  // Global State
  const {
    mainMenuVisible,
    setMainMenuVisible,
    priceListScheduleVisible,
    setPriceListScheduleVisible,
    hotelDetailActiveTab,
    priceListScheduleData,
    setPriceListScheduleData,
  } = useContext(MainContext);

  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  const getPriceListSchedule = useCallback(async () => {
    await axios
      .get(`${process.env.REACT_APP_SITE_SCHEDULE_READ}${hotelId}`)
      .then((res) => setPriceListScheduleData(res.data))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getPriceListSchedule();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [hotelId, logout, navigate, setPriceListScheduleData]);

  useEffect(() => {
    if (hotelDetailActiveTab === "Price list schedule" && hotelId) {
      getPriceListSchedule();
    }
  }, [getPriceListSchedule, hotelDetailActiveTab, hotelId]);

  return (
    <div className="hotel-detail-priceList-schedule">
      <div className="container">
        <div className="priceList-schedule-head"></div>
        <div className="priceList-schedule-body">
          <table className="table">
            <thead>
              <tr>
                <th className="first">Id</th>
                <th className="first">Period</th>

                <th className="edit">Edit && Delete </th>
              </tr>
            </thead>
            <tbody>
              {priceListScheduleData.length !== 0 &&
                priceListScheduleData.map((price) => (
                  <tr key={price.id}>
                    <td className="first">{price.id}</td>
                    <td className="first">
                      {price.fragment_start_month}-{price.fragment_start_day}/{" "}
                      {price.fragment_end_month}-{price.fragment_end_day}
                    </td>

                    <td className="edit">
                      <div className="btn-area">
                        <button
                          onClick={() => setPriceListScheduleVisible(true)}
                        >
                          <img src={edit} alt="edit" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <PriceListScheduleSideBarMenu hotelId={hotelId} />
      <div
        className={
          priceListScheduleVisible || mainMenuVisible
            ? "overlay-sub-menu active"
            : "overlay-sub-menu"
        }
        onClick={() => {
          mainMenuVisible
            ? setMainMenuVisible(false)
            : setPriceListScheduleVisible(false);
        }}
      ></div>
    </div>
  );
};

export default HotelDEtailPriceListSchedule;
