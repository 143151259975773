// React
import { useContext } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Components
import ImageCard from "../../components/ImageCard";

// Icon
import addFile from "../../assets/images/icon/add-btn.svg";
import AddImg from "../../components/AddImg";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const HotelImg = ({ hotelId }) => {
  // Global State
  const {
    // Data
    hotelData,
    setHotelData,
    hotelMainPhoto,
    setHotelMainPhoto,
    hotelAdditionPhoto,
    setHotelAdditionPhoto,

    setAddPhotoVisible,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  const putData = async () => {
    const formData = new FormData();
    const keys = Object.keys(hotelData);
    keys.forEach((key) => {
      formData.append(key, hotelData[key]);
    });
    formData.set(
      "hotel_associated_attributes",
      hotelData.hotel_associated_attributes
        ? hotelData.hotel_associated_attributes.map((item) => item.id).join(",")
        : ""
    );
    formData.set(
      "hotel_associated_coupons",
      hotelData.hotel_associated_coupons
        ? hotelData.hotel_associated_coupons.map((item) => item.id).join(",")
        : ""
    );

    formData.set(
      "hotel_associated_images",
      hotelAdditionPhoto
        ? hotelAdditionPhoto.map((item) => item.id).join(",")
        : ""
    );
    formData.set(
      "hotel_associated_main_image",
      hotelMainPhoto ? hotelMainPhoto.id : ""
    );
    // Request
    axios
      .put(`${process.env.REACT_APP_SITE_HOTEL_UPTADE}${hotelId}`, formData)
      .then((res) => {
        if (res.status === 200) {
        }
        setHotelData({
          ...hotelData,
          hotel_associated_main_image: hotelMainPhoto,
          hotel_associated_images: hotelAdditionPhoto,
        });
        notify("Hotel Image Update!");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              putData();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  };
  return (
    <div className="boxes-style">
      <div className="image-area ">
        <h2 className="main-caption">Main Photo</h2>
        <div className="form-group">
          <button
            className="add"
            onClick={() => setAddPhotoVisible({ type: "main", visible: true })}
          >
            <div className="icon">
              <img src={addFile} alt="addfile" />
            </div>
            File Manage
          </button>
        </div>
        {hotelMainPhoto ? (
          <div className="img-cards-area">
            <ImageCard
              img={hotelMainPhoto}
              onClickFunction={() => {
                setHotelMainPhoto("");
              }}
            />
          </div>
        ) : (
          <p className="errorImgText">Image not found. Please add an image</p>
        )}

        <h2 className="main-caption">Additional Photos</h2>
        <div className="form-group">
          <button
            className="add"
            onClick={() =>
              setAddPhotoVisible({ type: "addition", visible: true })
            }
          >
            <div className="icon">
              <img src={addFile} alt="addfile" />
            </div>
            File Manage
          </button>
        </div>
        {hotelAdditionPhoto.length !== 0 ? (
          <div className="img-cards-area">
            {hotelAdditionPhoto.map((photo) => (
              <ImageCard
                key={photo.id}
                img={photo}
                onClickFunction={() => {
                  setHotelAdditionPhoto((prevPhotos) =>
                    prevPhotos.filter((item) => item.id !== photo.id)
                  );
                }}
              />
            ))}
          </div>
        ) : (
          <p className="errorImgText">Image not found. Please add an image</p>
        )}
      </div>
      <div className="form-footer">
        <div className="handle-area">
          <p className="error-text"></p>
          <button
            onClick={(e) => {
              e.preventDefault();
              putData();
            }}
          >
            Save
          </button>
        </div>
      </div>
      <AddImg />
    </div>
  );
};

export default HotelImg;
