// Icon
import trash from "../../assets/images/icon/trash.svg";
import add from "../../assets/images/icon/add-plus.svg";
import edit from "../../assets/images/icon/edit.svg";
import BannerSideBAr from "../../components/side-bar-menu/BannerSideBAr";
import { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";

import { createRemoveAlert } from "../../utils/SweetAlert";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const Banner = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    bannerData,
    setBannerData,
    homeActiveTab,
    bannerSideBarVisible,
    setBannerSideBarVisible,
    setSelectBanner,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [type, setType] = useState("");

  const getBannerData = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_SITE_BANNER_READ_ALL)
      .then((res) => setBannerData(res.data))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getBannerData();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [logout, navigate, setBannerData]);

  useEffect(() => {
    if (homeActiveTab === "Promotion Banner") {
      getBannerData();
    }
  }, [getBannerData, homeActiveTab]);

  // Delete Banner
  const removeData = (dataId, banner_name) => {
    createRemoveAlert(
      "Delete Banner!",
      `Are you sure you want to delete the ${banner_name} Banner?`,
      "Yes, Remove",
      async () => {
        await axios
          .delete(`${process.env.REACT_APP_SITE_BANNER_DELETE}${dataId}`)
          .then((res) => {
            if (res.status === 200) {
              const update = bannerData.filter((item) => item.id !== dataId);
              setBannerData(update);
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId, banner_name);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };

  return (
    <div className="banner">
      <div className="container">
        <div className="edit-area">
          <h6 className="title">Add Banner</h6>
          <button
            className="add-btn"
            onClick={() => {
              setBannerSideBarVisible(true);
              setType("create");
              setSelectBanner({
                banner_name: "",
                banner_url: "",
                banner_image: "",
              });
            }}
          >
            Add <img src={add} alt="add" />
          </button>
        </div>
        <div className="banner-body">
          <table className="table">
            <thead>
              <tr>
                <th className="first">ID</th>
                <th className="second">name</th>
                <th className="edit">Edit && Delete </th>
              </tr>
            </thead>
            <tbody>
              {bannerData.length !== 0 &&
                bannerData.map((banner) => (
                  <tr key={banner.id}>
                    <td className="first">{banner.id}</td>
                    <td className="second">{banner.banner_name}</td>

                    <td className="edit">
                      <div className="btn-area">
                        <button
                          onClick={() =>
                            removeData(banner.id, banner.banner_name)
                          }
                        >
                          <img src={trash} alt="delete" />
                        </button>
                        <button
                          onClick={() => {
                            setBannerSideBarVisible(true);
                            setSelectBanner(banner);
                            setType("edit");
                          }}
                        >
                          <img src={edit} alt="edit" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className={
          bannerSideBarVisible ? "overlay-sub-menu active" : "overlay-sub-menu"
        }
        onClick={() => {
          setBannerSideBarVisible(false);
          setSelectBanner({
            banner_name: "",
            banner_url: "",
            banner_image: "",
          });
        }}
      ></div>
      <BannerSideBAr type={type} />
    </div>
  );
};

export default Banner;
