// AuthContext.js

import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Navigate
  const navigate = useNavigate();

  // Location
  const { pathname } = useLocation();

  // const { csrfToken, setCsrfToken } = useContext(MainContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = () => setIsLoggedIn(true);
  const logout = () => setIsLoggedIn(false);

  // useEffect(() => {
  //   if (csrfToken === "") {
  //     axios
  //       .get(process.env.REACT_APP_SITE_CSRF_READ, )
  //       .then((res) => {
  //         setCsrfToken(res.data.csrfToken);
  //       })
  //       .catch((err) => console.log(err));
  //   }

  // }, [csrfToken, setCsrfToken]);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_CHECK_TOKEN)
      .then((res) => {
        if (res) {
          login();
          if (pathname === "/login") {
            navigate("/");
          } else {
            navigate(pathname);
          }
        }
      })
      .catch((err) => {
        if (err) {
          navigate("/login");
        }
      });
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
