import { useCallback, useContext, useEffect } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Axios
import axios from "axios";

// Params
import { useNavigate, useParams } from "react-router-dom";

// Components
import RoomMainInformation from "../Rooms/RoomMainInformation";
import RoomImg from "../Rooms/RoomImg";
import RoomAtribute from "../Rooms/RoomAtribute";
import RoomFreeNightOffer from "../Rooms/RoomFreeNightOffer";
import RoomPersentageOffer from "../Rooms/RoomPersentageOffer";
import HotelDetailRoomDetailAcordion from "../../components/acordion/HotelDetailRoomDetailAcordion";
import RoomSpecialOffer from "../Rooms/RoomSpecialOffer";
import { useAuth } from "../../utils/AuthContext";

const HotelDetailRoomDetail = () => {
  // Params
  const { hotelId } = useParams();
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    selectRoomID,
    hotelDetailActiveTab,
    setRoomData,
    setRoomMainPhoto,
    setRoomAdditionPhoto,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Get Room Data
  const getRoomData = useCallback(async () => {
    await axios
      .get(`${process.env.REACT_APP_SITE_ROOM_READ_BY_ID}${selectRoomID}`)
      .then((res) => {
        setRoomData(res.data);
        setRoomAdditionPhoto(res.data.suite_associated_images);
        setRoomMainPhoto(res.data.suite_associated_main_image);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getRoomData();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [logout, navigate, selectRoomID, setRoomAdditionPhoto, setRoomData, setRoomMainPhoto]);

  // Rendering Data
  useEffect(() => {
    if (hotelDetailActiveTab === "Room Detail") {
      getRoomData();
    }
  }, [getRoomData, hotelDetailActiveTab]);

  return (
    <div className="hotel-detail-room-detail">
      <div className="container">
        {/* Main Room Information */}
        <RoomMainInformation hotelId={hotelId} />

        {/* Offer Free Nights */}
        <RoomFreeNightOffer />

        {/* Offer Percentage */}
        <RoomPersentageOffer />

        {/* Special Offer */}
        <RoomSpecialOffer />

        {/* Suit Attributes */}
        <RoomAtribute />

        {/* Room Main && Addition Img */}
        <RoomImg />

        {/* Suit Description */}
        <div className="discription-area">
          <h2 className="main-caption">Room Description</h2>
          <HotelDetailRoomDetailAcordion />
        </div>
      </div>
    </div>
  );
};

export default HotelDetailRoomDetail;
