// React
import { useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const SpecialPricePriceSideBar = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    selectSpecialOffer,
    discountData,
    setDiscountData,
    setSelectSpecialOffer,
    specialPriceVisible,
    setSpecialPriceVisible,
    specialPriceSelect,
    setSpecialPriceSelect,
  } = useContext(MainContext);
  const { logout } = useAuth();
  //   Local State
  const [errorText, setErrorText] = useState("");

  // Yup schema
  const editSchema = object({
    entry_price_num_staying_guests: string().required().trim(),
    entry_price_staying_period: string().required().trim(),
    entry_price: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(editSchema),
  });

  useEffect(() => {
    setErrorText("");
    reset();
    if (specialPriceVisible) {
      setValue(
        "entry_price_num_staying_guests",
        specialPriceSelect.entry_price_num_staying_guests
      );
      setValue(
        "entry_price_staying_period",
        specialPriceSelect.entry_price_staying_period
      );
      setValue("entry_price", specialPriceSelect.entry_price);
    }
  }, [
    specialPriceVisible,
    reset,
    setErrorText,
    setValue,
    specialPriceSelect.entry_price,
    specialPriceSelect.entry_price_num_staying_guests,
    specialPriceSelect.entry_price_staying_period,
  ]);

  const submitPrice = async (data) => {
    const body = new FormData();
    body.append(
      "entry_price_num_staying_guests",
      data.entry_price_num_staying_guests
    );
    body.append("entry_price_staying_period", data.entry_price_staying_period);
    body.append("entry_price", data.entry_price);
    body.append("entry_associated_offer", selectSpecialOffer.id);

    if (specialPriceSelect.type === "create") {
      await axios
        .post(process.env.REACT_APP_SITE_SPECIAL_OFFER_PRICE_CREATE, body)
        .then((res) => {
          selectSpecialOffer.offer_associated_entries.unshift(res.data);
          const newData = discountData.map((discount) => {
            if (discount.id === selectSpecialOffer.id) {
              return selectSpecialOffer;
            }
            return discount;
          });
          setDiscountData(newData);
          setSelectSpecialOffer(selectSpecialOffer);
          reset();
          setSpecialPriceVisible(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                submitPrice(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        });
    } else {
      await axios
        .put(
          `${process.env.REACT_APP_SITE_SPECIAL_OFFER_PRICE_UPDATE}/${specialPriceSelect.id}`,
          body
        )
        .then((res) => {
          selectSpecialOffer.offer_associated_entries =
            selectSpecialOffer.offer_associated_entries.map((price) => {
              if (price.id === res.data.id) {
                return res.data;
              }
              return price;
            });
          const newData = discountData.map((discount) => {
            if (discount.id === selectSpecialOffer.id) {
              return selectSpecialOffer;
            }
            return discount;
          });
          setDiscountData(newData);
          setSelectSpecialOffer(selectSpecialOffer);

          setSpecialPriceSelect({
            entry_price_num_staying_guests: "",
            entry_price_staying_period: "",
            entry_price: "",
            id: "",
            type: "",
          });
          setSpecialPriceVisible(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                submitPrice(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        });
    }
  };

  return (
    <div
      className={
        specialPriceVisible
          ? " isOpenMenu special-price-sidebar-menu scroll"
          : "special-price-sidebar-menu scroll"
      }
    >
      <div className="head">
        <h4 className="caption">Special Offer Price</h4>
        <div
          className="icon"
          onClick={() => {
            setSpecialPriceVisible(false);
            setSpecialPriceSelect({
              entry_price_num_staying_guests: "",
              entry_price_staying_period: "",
              entry_price: "",
              id: "",
              type: "",
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="special-price-form"
          noValidate
          onSubmit={handleSubmit(submitPrice)}
        >
          <div className="form-group">
            <label
              htmlFor="entry_price_num_staying_guests"
              className="inp-caption"
            >
              Number of guests
            </label>
            <input
              type="text"
              className={
                errors.entry_price_num_staying_guests ? "inp error" : "inp"
              }
              id="entry_price_num_staying_guests"
              name="entry_price_num_staying_guests"
              {...register("entry_price_num_staying_guests", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="entry_price_staying_period" className="inp-caption">
              Staying period
            </label>
            <input
              type="text"
              className={
                errors.entry_price_staying_period ? "inp error" : "inp"
              }
              id="entry_price_staying_period"
              name="entry_price_staying_period"
              {...register("entry_price_staying_period", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="entry_price" className="inp-caption">
              Price
            </label>
            <input
              type="text"
              className={errors.entry_price ? "inp error" : "inp"}
              id="entry_price"
              entry_price
              name="entry_price"
              {...register("entry_price", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>

          <div className="form-footer">
            <button>Save</button>
            <p className="error-text">{errorText}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SpecialPricePriceSideBar;
