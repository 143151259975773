import { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../utils/MainContext";

// Icon
import menuIcon from "../assets/images/icon/burger-menu.svg";
import trash from "../assets/images/icon/trash.svg";
import edit from "../assets/images/icon/edit.svg";
import add from "../assets/images/icon/add-plus.svg";
import axios from "axios";

// Toastie
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createRemoveAlert } from "../utils/SweetAlert";
import DiscountFreeNight from "../components/side-bar-menu/DiscountFreeNight";
import DiscountPersentageNight from "../components/side-bar-menu/DiscountPersentageNight";
import SpecialOfferSideBar from "../components/side-bar-menu/SpecialOfferSideBar";
import SpecialPricePriceSideBar from "../components/side-bar-menu/SpecialPricePriceSideBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";

const Discount = () => {
  // Global State
  const {
    mainMenuVisible,
    setMainMenuVisible,
    discountFreeNightSideBar,
    setDiscountFreeNightSideBar,
    discountPersentageSideBar,
    setDiscountPersentageSideBar,
    sepcialOfferSideBarVisible,
    setSepcialOfferSideBarVisible,
    specialPriceVisible,
    setSpecialPriceVisible,
    // Discount
    discountData,
    setDiscountData,
    setSelectDiscount,
    setSelectSpecialOffer,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  //   Local State
  const [currentTab, setCurrentTab] = useState("night");
  const [prevNumber, setPrevNumber] = useState(0);
  const [activeNumber, setActiveNumber] = useState(1);
  const [nextNumber, setNextNumber] = useState(2);
  const [paginationData, setPaginaTionData] = useState(null);
  const [type, setType] = useState("");

  // Get Free Night Data
  const getFreeNight = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_DISCOUNT_FREE_NIGHT_READ_ALL}?page_length=20&page_number=${page_number}`
        )
        .then((res) => {
          setDiscountData(res.data.page_data);
          setPaginaTionData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                getFreeNight(page_number);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setDiscountData]
  );

  // Get Persentage Night Data
  const getPersentageNight = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_DISCOUNT_PERCENTAGE_READ_ALL}?page_length=20&page_number=${page_number}`
        )
        .then((res) => {
          setDiscountData(res.data.page_data);
          setPaginaTionData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                getPersentageNight(page_number);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setDiscountData]
  );
  // Get Special Offer Data
  const getSpecialOffer = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_SPECIAL_OFFER_READ_ALL}?page_length=20&page_number=${page_number}`
        )
        .then((res) => {
          setDiscountData(res.data.page_data);
          setPaginaTionData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                getSpecialOffer(page_number);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setDiscountData]
  );

  // Search Persentage Night Data
  const searchPersentageNight = useCallback(
    async (value) => {
      await axios
        .get(`${process.env.REACT_APP_SITE_DISCOUNT_PERCENTAGE_SEARCH}${value}`)
        .then((res) => {
          setDiscountData(res.data);
          setPaginaTionData(null);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                searchPersentageNight(value);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setDiscountData]
  );

  // Search Free Night Data
  const searchFreeNight = useCallback(
    async (value) => {
      await axios
        .get(`${process.env.REACT_APP_SITE_DISCOUNT_FREE_NIGHT_SEARCH}${value}`)
        .then((res) => {
          setDiscountData(res.data);
          setPaginaTionData(null);
        })

        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                searchFreeNight(value);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setDiscountData]
  );
  // Search Special Offer Data
  const searchSpecialOffer = useCallback(
    async (value) => {
      await axios
        .get(`${process.env.REACT_APP_SITE_SPECIAL_OFFER_SEARCH}/${value}`)
        .then((res) => {
          setDiscountData(res.data);
          setPaginaTionData(null);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                searchSpecialOffer(value);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setDiscountData]
  );

  // RenderingDAta
  useEffect(() => {
    if (currentTab === "percentage") {
      setPrevNumber(0);
      setActiveNumber(1);
      setNextNumber(2);
      getPersentageNight(1);
    } else if (currentTab === "night") {
      setPrevNumber(0);
      setActiveNumber(1);
      setNextNumber(2);
      getFreeNight(1);
    } else {
      setPrevNumber(0);
      setActiveNumber(1);
      setNextNumber(2);
      getSpecialOffer(1);
      // special
    }
  }, [currentTab, getFreeNight, getPersentageNight, getSpecialOffer]);

  // Controler
  const handleController = async (e) => {
    if (e.target.value.trim().length > 1) {
      if (currentTab === "percentage") {
        searchPersentageNight(e.target.value);
      } else if (currentTab === "night") {
        searchFreeNight(e.target.value);
      } else {
        searchSpecialOffer(e.target.value);
      }
    } else {
      if (currentTab === "percentage") {
        setPrevNumber(paginationData ? prevNumber : 0);
        setActiveNumber(paginationData ? activeNumber : 1);
        setNextNumber(paginationData ? nextNumber : 2);
        getPersentageNight(paginationData ? activeNumber : 1);
      } else if (currentTab === "night") {
        setPrevNumber(paginationData ? prevNumber : 0);
        setActiveNumber(paginationData ? activeNumber : 1);
        setNextNumber(paginationData ? nextNumber : 2);
        getFreeNight(paginationData ? activeNumber : 1);
      } else {
        setPrevNumber(paginationData ? prevNumber : 0);
        setActiveNumber(paginationData ? activeNumber : 1);
        setNextNumber(paginationData ? nextNumber : 2);
        getSpecialOffer(paginationData ? activeNumber : 1);
      }
    }
  };

  /* ------------------------------- Change Page ------------------------------ */
  const handleChange = (step) => {
    let newPrevNumber = prevNumber;
    let newActiveNumber = activeNumber;
    let newNextNumber = nextNumber;

    if (step === "prev" && prevNumber > 0) {
      newPrevNumber = prevNumber - 1;
      newActiveNumber = activeNumber - 1;
      newNextNumber = nextNumber - 1;
    } else {
      newPrevNumber = prevNumber + 1;
      newActiveNumber = activeNumber + 1;
      newNextNumber = nextNumber + 1;
    }

    setPrevNumber(newPrevNumber);
    setActiveNumber(newActiveNumber);
    setNextNumber(newNextNumber);

    if (currentTab === "percentage") {
      getPersentageNight(newActiveNumber);
    } else if (currentTab === "night") {
      getFreeNight(newActiveNumber);
    } else {
      getSpecialOffer(newActiveNumber);

      // special
    }
  };

  // Delete Coupon
  const removeData = (dataId) => {
    createRemoveAlert(
      "Delete Discount!",
      "Are you sure you want to delete the Discount?",
      "Yes, Remove",
      async () => {
        if (currentTab === "night") {
          await axios
            .delete(
              `${process.env.REACT_APP_SITE_DISCOUNT_FREE_NIGHT_DELETE}${dataId}`
            )
            .then((res) => {
              if (res.status === 200) {
                const updateCoupon = discountData.filter(
                  (item) => item.id !== dataId
                );
                setDiscountData(updateCoupon);
              }
            })
            .catch((err) => {
              if (err.response.status === 401) {
                axios
                  .get(process.env.REACT_APP_REFRESH_TOKEN)
                  .then((res) => {
                    removeData(dataId);
                  })
                  .catch((err) => navigate("/login"));
              } else if (err.response.status === 403) {
                logout();
                navigate("/login");
              }
            });
        } else if (currentTab === "percentage") {
          await axios
            .delete(
              `${process.env.REACT_APP_SITE_DISCOUNT_PERCENTAGE_DELETE}${dataId}`
            )
            .then((res) => {
              if (res.status === 200) {
                const updateCoupon = discountData.filter(
                  (item) => item.id !== dataId
                );
                setDiscountData(updateCoupon);
              }
            })
            .catch((err) => {
              if (err.response.status === 401) {
                axios
                  .get(process.env.REACT_APP_REFRESH_TOKEN)
                  .then((res) => {
                    removeData(dataId);
                  })
                  .catch((err) => navigate("/login"));
              } else if (err.response.status === 403) {
                logout();
                navigate("/login");
              }
            });
        } else {
          // special
          await axios
            .delete(
              `${process.env.REACT_APP_SITE_SPECIAL_OFFER_DELETE}/${dataId}`
            )
            .then((res) => {
              if (res.status === 200) {
                const updateCoupon = discountData.filter(
                  (item) => item.id !== dataId
                );
                setDiscountData(updateCoupon);
              }
            })
            .catch((err) => {
              if (err.response.status === 401) {
                axios
                  .get(process.env.REACT_APP_REFRESH_TOKEN)
                  .then((res) => {
                    removeData(dataId);
                  })
                  .catch((err) => navigate("/login"));
              } else if (err.response.status === 403) {
                logout();
                navigate("/login");
              }
            });
        }
      }
    );
  };

  return (
    <main>
      <section className="discount">
        <div className="sub-header">
          <div className="container">
            <div className="row">
              <div className="info">
                <button
                  className=" burger-menu "
                  onClick={() => setMainMenuVisible(true)}
                >
                  <img src={menuIcon} alt="menu" />
                </button>
                <h2 className="caption">Discount</h2>
              </div>
              <div className="search-form">
                <input
                  type="text"
                  placeholder="Search"
                  name="search"
                  id="search"
                  autoComplete="off"
                  onChange={handleController}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="discount-header">
          <div className="container">
            <ul className="tab-list">
              <li
                className={
                  currentTab === "night" ? "tab-item active" : "tab-item"
                }
                onClick={() => setCurrentTab("night")}
              >
                Free night
              </li>
              <li
                className={
                  currentTab === "percentage" ? "tab-item active" : "tab-item"
                }
                onClick={() => setCurrentTab("percentage")}
              >
                Offer percentage
              </li>
              <li
                className={
                  currentTab === "special" ? "tab-item active" : "tab-item"
                }
                onClick={() => setCurrentTab("special")}
              >
                Special offer
              </li>
            </ul>
            <div className="edit-area">
              <h6 className="title">Add discount</h6>
              <button
                className="add-btn"
                onClick={() => {
                  if (currentTab === "percentage") {
                    setDiscountPersentageSideBar(true);
                  } else if (currentTab === "night") {
                    setDiscountFreeNightSideBar(true);
                  } else {
                    setSepcialOfferSideBarVisible(true);
                  }
                  setType("create");
                }}
              >
                Add <img src={add} alt="add" />
              </button>
            </div>
          </div>
        </div>
        <div className="discount-info-area">
          <div className="container">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Offer name</th>
                  <th>Period</th>
                  {currentTab !== "special" && (
                    <>
                      {" "}
                      <th>
                        {currentTab === "night"
                          ? "Free night quantity"
                          : "Value"}
                      </th>
                      <th>Travel dates</th>
                    </>
                  )}
                  <th>Active</th>
                  <th>Edit && Delete </th>
                </tr>
              </thead>
              <tbody>
                {discountData &&
                  discountData.map((item) => (
                    <tr key={item.id}>
                      <td className="carImg">{item.id}</td>
                      <td>{item.offer_name}</td>
                      <td>
                        {currentTab === "special"
                          ? item.offer_start_day +
                            "-" +
                            item.offer_start_month +
                            "/" +
                            item.offer_end_day +
                            "-" +
                            item.offer_end_month
                          : item.offer_start + "/" + item.offer_end}
                      </td>
                      {currentTab !== "special" && (
                        <>
                          <td>
                            {item.offer_free_night_quantity}{" "}
                            {item.offer_percentage_value}
                          </td>

                          <td>
                            {" "}
                            {item.offer_travel_dates_must_be_within_offer_dates
                              ? "Active"
                              : "Dis-active"}{" "}
                          </td>
                        </>
                      )}

                      <td> {item.offer_active ? "Active" : "Dis-active"} </td>

                      <td className="edit">
                        <div className="btn-area">
                          <button onClick={() => removeData(item.id)}>
                            <img src={trash} alt="trash" />
                          </button>
                          <button
                            onClick={() => {
                              setType("edit");
                              if (currentTab === "percentage") {
                                setDiscountPersentageSideBar(true);
                                setSelectDiscount(item);
                              } else if (currentTab === "night") {
                                setDiscountFreeNightSideBar(true);
                                setSelectDiscount(item);
                              } else {
                                setSepcialOfferSideBarVisible(true);
                                setSelectSpecialOffer(item);
                              }
                            }}
                          >
                            <img src={edit} alt="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {paginationData && (
              <div className="pagination">
                <button
                  className="btn prev"
                  onClick={() => handleChange("prev")}
                  disabled={!paginationData.page_has_previous}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM17.685 19.5C18.12 19.935 18.12 20.655 17.685 21.09C17.46 21.315 17.175 21.42 16.89 21.42C16.605 21.42 16.32 21.315 16.095 21.09L10.8 15.795C10.365 15.36 10.365 14.64 10.8 14.205L16.095 8.91C16.53 8.475 17.25 8.475 17.685 8.91C18.12 9.345 18.12 10.065 17.685 10.5L13.185 15L17.685 19.5Z"
                      fill=" #AD471F"
                    />
                  </svg>
                </button>
                {prevNumber !== 0 && (
                  <button
                    className="btn prev-number"
                    onClick={() => handleChange("prev")}
                  >
                    {prevNumber}
                  </button>
                )}
                <button className="btn active">{activeNumber}</button>
                {paginationData && paginationData.page_has_next && (
                  <button
                    className="btn next-number"
                    onClick={() => handleChange("next")}
                  >
                    {nextNumber}
                  </button>
                )}

                {paginationData && (
                  <button
                    className="btn next"
                    disabled={!paginationData.page_has_next}
                    onClick={() => handleChange("next")}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM19.185 15.795L13.89 21.09C13.665 21.315 13.38 21.42 13.095 21.42C12.81 21.42 12.525 21.315 12.3 21.09C11.865 20.655 11.865 19.935 12.3 19.5L16.8 15L12.3 10.5C11.865 10.065 11.865 9.345 12.3 8.91C12.735 8.475 13.455 8.475 13.89 8.91L19.185 14.205C19.635 14.64 19.635 15.36 19.185 15.795Z"
                        fill=" #AD471F"
                      />
                    </svg>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className={
            discountFreeNightSideBar ||
            discountPersentageSideBar ||
            sepcialOfferSideBarVisible ||
            mainMenuVisible
              ? "overlay-sub-menu active"
              : "overlay-sub-menu"
          }
          onClick={() => {
            setMainMenuVisible(false);
            if (currentTab === "percentage") {
              setDiscountPersentageSideBar(false);
              setSelectDiscount({
                offer_active: "",
                offer_name: "",
                offer_public_name_az: "",
                offer_public_name_ru: "",
                offer_public_name_en: "",
                offer_start: "",
                offer_end: "",
                offer_travel_dates_must_be_within_offer_dates: "",
                offer_recurring_period: "",
                offer_free_night_quantity: "",
                offer_percentage_value: "",
              });
            } else if (currentTab === "night") {
              setDiscountFreeNightSideBar(false);
              setSelectDiscount({
                offer_active: "",
                offer_name: "",
                offer_public_name_az: "",
                offer_public_name_ru: "",
                offer_public_name_en: "",
                offer_start: "",
                offer_end: "",
                offer_travel_dates_must_be_within_offer_dates: "",
                offer_recurring_period: "",
                offer_free_night_quantity: "",
                offer_percentage_value: "",
              });
            } else {
              setSepcialOfferSideBarVisible(false);
              setSelectSpecialOffer({
                id: "",
                offer_associated_entries: [],
                offer_active: "",
                offer_name: "",
                offer_public_name_az: "",
                offer_public_name_ru: "",
                offer_public_name_en: "",
                offer_start_month: "",
                offer_start_day: "",
                offer_end_month: "",
                offer_end_day: "",
              });
            }
          }}
        ></div>
        <div
          className={
            specialPriceVisible
              ? "overlay-price-sub-menu active"
              : "overlay-price-sub-menu"
          }
          onClick={() => specialPriceVisible && setSpecialPriceVisible(false)}
        ></div>
        <DiscountFreeNight type={type} />
        <DiscountPersentageNight type={type} />
        <SpecialOfferSideBar type={type} />
        <SpecialPricePriceSideBar />

        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Bounce}
        />
      </section>
    </main>
  );
};

export default Discount;
