// React Hook
import React, { useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Image
import addFile from "../../assets/images/icon/add-btn.svg";
import loadingBtn from "../../assets/images/icon/btn-loading.svg";

import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const HotelVideo = (hotelId) => {
  // Global State
  const {
    // Data
    hotelData,
    setHotelData,
    hotelDetailActiveTab,

    // hotelDetailActiveTab,
  } = useContext(MainContext);
  const { logout } = useAuth();

  //   Local State
  const [eventType, setEventType] = useState({
    youtube: false,
    video: false,
  });
  const [iframeAddComponentsVisible, setIframeAddComponentsVisible] =
    useState(false);
  const [iframeError, setIframeError] = useState("");
  const [sendData, setSendData] = useState(false);
  const [errorVideo, setErrorVideo] = useState(false);
  const [edit, setEdit] = useState(false);
  const [video, setVideo] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const [iframeLink, setIframeLink] = useState("");

  // Yup schema
  const createSchema = object({
    iframe_link: string().required().trim(),
  });

  const navigate = useNavigate();

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(createSchema),
  });

  //   Сheck Youtubr Link
  const checkIframe = (data) => {
    const youtubeIframePattern =
      /<iframe[^>]+src="(https:\/\/www\.youtube\.com\/embed\/[^"]+)"/;
    const match = data.iframe_link.match(youtubeIframePattern);

    if (match) {
      setIframeAddComponentsVisible(false);
      setIframeLink(data.iframe_link);
      setEventType({
        youtube: true,
        video: false,
      });
      setEdit(true);
    } else {
      setIframeError("This is not a YouTube iframe.");
    }
  };

  // Render Data/Reset State
  useEffect(() => {
    if (hotelDetailActiveTab === "Description" && hotelId && hotelData) {
      setEdit(false);
      setIframeAddComponentsVisible(false);
      setIframeError("");
      setEventType({
        youtube: false,
        video: false,
      });

      //   Check Video Type
      if (hotelData.video) {
        console.log(hotelData.video);
        setEventType({
          video: hotelData.video.is_embed === true ? true : false,
          youtube: hotelData.video.is_embed === true ? false : true,
        });
        if (hotelData.video.is_embed) {
          setVideoUrl(
            `${process.env.REACT_APP_IMAGE}${hotelData.video.filepath}`
          );
        } else {
          setIframeLink(hotelData.video.youtube);
        }
      }
    }
  }, [hotelData, hotelDetailActiveTab, hotelId]);

  // Collect Video
  const handleVideo = (e) => {
    // reader.readAsDataURL(file);
    const file = e.target.files[0];
    if (file) {
      setEventType({
        youtube: false,
        video: true,
      });
      setVideo(file);
      const objectUrl = URL.createObjectURL(file);
      setVideoUrl(objectUrl);
      setEdit(true);
    }
  };

  //   Delete Event
  const deleteEvent = async () => {
    const formData = new FormData();
    formData.append("id", hotelData.video.id);
    await axios
      .delete(
        `http://back.naftalan.biz/private/hotel/video/${hotelData.video.id}
`,
        formData
      )
      .then((res) => {
        notify("Video Deleted");
        setHotelData({
          ...hotelData,
          video: null,
        });

        setVideo(null);
        setVideoUrl(null);
        setIframeLink("");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && deleteEvent())
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  };

  const eventUpdate = async () => {
    // update
    setSendData(true);
    if (hotelData.video) {
      const formData = new FormData();
      formData.append("filepath", eventType.video === true ? video : "");
      formData.append("hotel", hotelId.hotelId);
      formData.append("is_embed", eventType.video === true ? true : false);
      formData.append("youtube", eventType.video === true ? "" : iframeLink);
      formData.append("id", hotelData.video.id);
      await axios
        .put(
          `http://back.naftalan.biz/private/hotel/video/${hotelData.video.id}/`,
          formData
        )
        .then((res) => {
          notify("video Update!");
          setSendData(false);
          setHotelData({
            ...hotelData,
            video: res.data,
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => res && eventUpdate())
              .catch((err) => err && navigate("/login"));
          } else if (err.response.status === 403) {
            setSendData(false);
            logout();
            navigate("/login");
          } else {
            setSendData(false);
          }
        });
    }

    // Create
    else {
      const formData = new FormData();
      formData.append("filepath", eventType.video === true ? video : "");
      formData.append("hotel", hotelId.hotelId);
      formData.append("is_embed", eventType.video === true ? true : false);
      formData.append("youtube", eventType.video === true ? "" : iframeLink);

      await axios
        .post(`http://back.naftalan.biz/private/hotel/video/`, formData)
        .then((res) => {
          notify("Video Created");
          setSendData(false);
          setHotelData({
            ...hotelData,
            video: res.data,
          });
        })

        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => res && eventUpdate())
              .catch((err) => err && navigate("/login"));
          } else if (err.response.status === 403) {
            setSendData(false);
            logout();
            navigate("/login");
          } else {
            setSendData(false);
          }
        });
    }
  };

  return (
    <section className="video-area">
      <h2 className="video-caption">Video</h2>
      <button
        className="add-to-video"
        onClick={() => {
          setIframeAddComponentsVisible(true);
          setIframeError("");
        }}
      >
        Add To Youtube Iframe
      </button>
      <div className="image-area">
        <div className="form-group">
          <label
            htmlFor="add-video"
            className={errorVideo ? "add error" : "add "}
          >
            <div className="icon">
              <img src={addFile} alt="addfile" />
            </div>
            Choose video file
          </label>
          <input
            type="file"
            accept="video/*"
            name="add-video"
            id="add-video"
            onChange={handleVideo}
          />
        </div>
      </div>

      {iframeAddComponentsVisible && (
        <div
          className="create-folder"
          onClick={() => {
            setIframeAddComponentsVisible(false);
            reset();
          }}
        >
          <form
            className="content"
            noValidate
            onSubmit={handleSubmit(checkIframe)}
            onClick={(e) => e.stopPropagation()}
          >
            <label htmlFor="iframe_link">Youtube Iframe Link</label>
            <input
              className={errors.iframe_link ? "inp error" : "inp"}
              type="text"
              name="iframe_link"
              id="iframe_link"
              {...register("iframe_link", {
                onChange: (e) => {
                  iframeError !== "" && setIframeError("");
                },
              })}
            />
            <p className="error-text"> {iframeError && iframeError}</p>
            <button className="submit">Add</button>
          </form>
        </div>
      )}
      {eventType.youtube && eventType.video === false && (
        <div
          className="youtube"
          dangerouslySetInnerHTML={{
            __html: iframeLink,
          }}
        />
      )}
      {videoUrl && eventType.youtube === false && eventType.video && (
        <video width="400" controls>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <div className="btn-group">
        {hotelData && hotelData.video && (
          <button className="delete" onClick={() => deleteEvent()}>
            Delete Old Video/Link
          </button>
        )}
        {edit && (
          <button className="save" onClick={() => eventUpdate()}>
            {sendData && <img src={loadingBtn} alt="loader" />}
            Save
          </button>
        )}
      </div>
    </section>
  );
};

export default HotelVideo;
