// Icon
import trash from "../../assets/images/icon/trash.svg";
import add from "../../assets/images/icon/add-plus.svg";
import edit from "../../assets/images/icon/edit.svg";
import { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";

// Toastie
import { createRemoveAlert } from "../../utils/SweetAlert";
import DiseaseSIdeBar from "../../components/side-bar-menu/DiseaseSIdeBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const Disease = () => {
  // Global State
  const {
    diseaseData,
    setDiseaseData,
    homeActiveTab,
    setSelectDisease,
    diseaseVisible,
    setDiseaseVisible,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Local State
  const [type, setType] = useState("");

  // Get Request
  const getDiseaseData = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_SITE_DISEASE_READ)
      .then((res) => setDiseaseData(res.data))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getDiseaseData();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [logout, navigate, setDiseaseData]);

  // Render Data
  useEffect(() => {
    if (homeActiveTab === "Naftalan Diseases Page") {
      getDiseaseData();
    }
  }, [getDiseaseData, homeActiveTab]);

  // Delete Data
  const removeData = (dataId, banner_name) => {
    createRemoveAlert(
      "Delete Diseases!",
      `Are you sure you want to delete the ${banner_name} Diseases?`,
      "Yes, Remove",
      async () => {
        await axios
          .delete(`${process.env.REACT_APP_SITE_DISEASE_DELETE}/${dataId}`)
          .then((res) => {
            if (res.status === 200) {
              const update = diseaseData.filter((item) => item.id !== dataId);
              setDiseaseData(update);
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId, banner_name);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };
  return (
    <>
      <div className="edit-area">
        <h6 className="title">Add Diseases</h6>
        <button
          className="add-btn"
          onClick={() => {
            setDiseaseVisible(true);
            setType("create");
            setSelectDisease({
              disease_icon_path: "",
              disease_hero_az: "",
              disease_text_az: "",
              disease_characteristics_az: "",
              disease_hero_en: "",
              disease_text_en: "",
              disease_characteristics_en: "",
              disease_hero_ru: "",
              disease_text_ru: "",
              disease_characteristics_ru: "",
            });
          }}
        >
          Add <img src={add} alt="add" />
        </button>
      </div>
      <div className="banner-body">
        <table className="table">
          <thead>
            <tr>
              <th className="first">ID</th>
              <th className="second">Title</th>
              <th className="edit">Edit && Delete </th>
            </tr>
          </thead>
          <tbody>
            {diseaseData.length !== 0 &&
              diseaseData.map((disease) => (
                <tr key={disease.id}>
                  <td className="first">{disease.id}</td>
                  <td className="second">{disease.disease_hero_en}</td>

                  <td className="edit">
                    <div className="btn-area">
                      <button
                        onClick={() =>
                          removeData(disease.id, disease.disease_hero_en)
                        }
                      >
                        <img src={trash} alt="delete" />
                      </button>
                      <button
                        onClick={() => {
                          setDiseaseVisible(true);
                          setSelectDisease(disease);
                          setType("edit");
                        }}
                      >
                        <img src={edit} alt="edit" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div
        className={
          diseaseVisible ? "overlay-sub-menu active" : "overlay-sub-menu"
        }
        onClick={() => {
          setDiseaseVisible(false);
          setSelectDisease({
            disease_icon_path: "",
            disease_hero_az: "",
            disease_text_az: "",
            disease_characteristics_az: "",
            disease_hero_en: "",
            disease_text_en: "",
            disease_characteristics_en: "",
            disease_hero_ru: "",
            disease_text_ru: "",
            disease_characteristics_ru: "",
          });
        }}
      ></div>
      <DiseaseSIdeBar type={type} />
    </>
  );
};

export default Disease;
