// React
import { useContext, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Components
import ImageCard from "../../components/ImageCard";

// Icon
import addFile from "../../assets/images/icon/add-btn.svg";
import AddImg from "../../components/AddImg";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const RoomImg = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    // Data
    roomData,
    setRoomData,
    roomMainPhoto,
    setRoomMainPhoto,
    roomAdditionPhoto,
    setRoomAdditionPhoto,
    setAddPhotoVisible,
    selectRoomID,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [errorText, setErrorText] = useState("");

  const putData = async () => {
    const formData = new FormData();
    const keys = Object.keys(roomData);
    keys.forEach((key) => {
      formData.append(key, roomData[key]);
    });

    formData.set(
      "suite_associated_attributes",
      roomData.suite_associated_attributes
        ? roomData.suite_associated_attributes.map((item) => item.id).join(",")
        : ""
    );
    formData.set(
      "suite_associated_special_offer_percentages",
      roomData.suite_associated_special_offer_percentages
        ? roomData.suite_associated_special_offer_percentages
            .map((item) => item.id)
            .join(",")
        : ""
    );
    formData.set(
      "suite_associated_special_offer_free_nights",
      roomData.suite_associated_special_offer_free_nights
        ? roomData.suite_associated_special_offer_free_nights
            .map((item) => item.id)
            .join(",")
        : ""
    );
    formData.set(
      "suite_associated_images",
      roomAdditionPhoto
        ? roomAdditionPhoto.map((item) => item.id).join(",")
        : ""
    );

    formData.set(
      "suite_associated_special_offer_special_price",
      roomData.suite_associated_special_offer_special_price
        ? roomData.suite_associated_special_offer_special_price.id
        : ""
    );

    formData.set(
      "suite_associated_main_image",
      roomMainPhoto ? roomMainPhoto.id : ""
    );
    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    // // Request
    await axios
      .put(`${process.env.REACT_APP_SITE_ROOM_UPTADE}${selectRoomID}`, formData)
      .then((res) => {
        notify("Room Update!");
        setRoomData({
          ...roomData,
          suite_associated_main_image: roomMainPhoto,
          suite_associated_images: roomAdditionPhoto,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              putData();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      });
  };
  return (
    <div className="boxes-style">
      <div className="image-area">
        <h2 className="main-caption">Main Photo</h2>
        <div className="form-group">
          <button
            className="add"
            onClick={() =>
              setAddPhotoVisible({ type: "main-room-photo", visible: true })
            }
          >
            <div className="icon">
              <img src={addFile} alt="addfile" />
            </div>
            File Manage
          </button>
        </div>
        {roomMainPhoto ? (
          <div className="img-cards-area">
            <ImageCard
              img={roomMainPhoto}
              onClickFunction={() => {
                setRoomMainPhoto("");
              }}
            />
          </div>
        ) : (
          <p className="errorImgText">Image not found. Please add an image</p>
        )}

        <h2 className="main-caption">Additional Photos</h2>
        <div className="form-group">
          <button
            className="add"
            onClick={() =>
              setAddPhotoVisible({ type: "addition-room-photo", visible: true })
            }
          >
            <div className="icon">
              <img src={addFile} alt="addfile" />
            </div>
            File Manage
          </button>
        </div>
        {roomAdditionPhoto.length !== 0 ? (
          <div className="img-cards-area">
            {roomAdditionPhoto.map((photo) => (
              <ImageCard
                key={photo.id}
                img={photo}
                onClickFunction={() => {
                  setRoomAdditionPhoto((prevPhotos) =>
                    prevPhotos.filter((item) => item.id !== photo.id)
                  );
                }}
              />
            ))}
          </div>
        ) : (
          <p className="errorImgText">Image not found. Please add an image</p>
        )}
      </div>
      <div className="form-footer">
        <div className="handle-area">
          <p className="error-text">{errorText}</p>
          <button
            onClick={(e) => {
              e.preventDefault();
              putData();
            }}
          >
            Save
          </button>
        </div>
      </div>
      <AddImg />
    </div>
  );
};

export default RoomImg;
