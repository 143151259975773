// Icon
import trash from "../../assets/images/icon/trash.svg";
import add from "../../assets/images/icon/add-plus.svg";
import edit from "../../assets/images/icon/edit.svg";
import { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";

import { createRemoveAlert } from "../../utils/SweetAlert";
import CustomPageCreateSideBar from "../../components/side-bar-menu/CustomPageCreateSideBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const CustomPAgeAllList = () => {
  // Global State
  const {
    customPageAllListData,
    setCustomPageAllListData,
    homeActiveTab,
    customPageAllListSideBarVisible,
    setCustomPageAllListSideBarVisible,
    setSelectCustomPage,
    setHomeActiveTab,
    setCustomPageSelectLocale,
  } = useContext(MainContext);

  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Local State
  const [prevNumber, setPrevNumber] = useState(0);
  const [activeNumber, setActiveNumber] = useState(1);
  const [nextNumber, setNextNumber] = useState(2);
  const [type, setType] = useState("");

  //   Get Data
  const getCustomListPageData = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_CUSTOM_PAGE_READ}?page_length=20&page_number=${page_number}`
        )
        .then((res) => setCustomPageAllListData(res.data))
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                getCustomListPageData(page_number);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setCustomPageAllListData]
  );

  //   Render Data
  useEffect(() => {
    if (homeActiveTab === "Custom Webiste Pages") {
      setCustomPageAllListSideBarVisible(false);
      getCustomListPageData(1);
    }
  }, [
    getCustomListPageData,
    homeActiveTab,
    setCustomPageAllListSideBarVisible,
  ]);

  /* ------------------------------- Change Page ------------------------------ */
  const handleChange = (step) => {
    let newPrevNumber = prevNumber;
    let newActiveNumber = activeNumber;
    let newNextNumber = nextNumber;

    if (step === "prev" && prevNumber > 0) {
      newPrevNumber = prevNumber - 1;
      newActiveNumber = activeNumber - 1;
      newNextNumber = nextNumber - 1;
    } else {
      newPrevNumber = prevNumber + 1;
      newActiveNumber = activeNumber + 1;
      newNextNumber = nextNumber + 1;
    }

    setPrevNumber(newPrevNumber);
    setActiveNumber(newActiveNumber);
    setNextNumber(newNextNumber);

    getCustomListPageData(newActiveNumber);
  };

  // Delete Custom Page
  const removeData = (dataId, name) => {
    createRemoveAlert(
      "Delete Custom Page!",
      `Are you sure you want to delete the ${name} Custom Page?`,
      "Yes, Remove",
      async () => {
        await axios
          .delete(`${process.env.REACT_APP_SITE_CUSTOM_PAGE_DELETE}/${dataId}`)
          .then((res) => {
            if (res.status === 200) {
              let arr = customPageAllListData.page_data;
              const update = arr.filter((item) => item.id !== dataId);
              setCustomPageAllListData({
                ...customPageAllListData,
                page_data: update,
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId, name);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };

  return (
    <div className="custom-page-all-list">
      <div className="container">
        <div className="edit-area">
          <h6 className="title">Create Custom Page</h6>
          <button
            className="add-btn"
            onClick={() => {
              setCustomPageAllListSideBarVisible(true);
              setType("create");
              setSelectCustomPage({
                page_associated_section: "",
                page_name_az: "",
                page_name_en: "",
                page_name_ru: "",
                page_associated_hotels: [],
              });
            }}
          >
            Add <img src={add} alt="add" />
          </button>
        </div>
        <div className="custom-page-all-list-body">
          <table className="table">
            <thead>
              <tr>
                <th className="first">ID</th>
                <th className="second">name</th>
                <th className="edit">Edit && Delete </th>
              </tr>
            </thead>
            <tbody>
              {customPageAllListData &&
                customPageAllListData.page_data.map((page) => (
                  <tr key={page.id}>
                    <td className="first">{page.id}</td>
                    <td className="second">{page.page_name_en}</td>

                    <td className="edit">
                      <div className="btn-area">
                        <button
                          onClick={() => removeData(page.id, page.page_name_en)}
                        >
                          <img src={trash} alt="delete" />
                        </button>
                        <button
                          onClick={() => {
                            setSelectCustomPage(page);
                            setCustomPageSelectLocale("az");
                            setHomeActiveTab("Custom Page Detail");
                          }}
                        >
                          Az
                        </button>
                        <button
                          onClick={() => {
                            setSelectCustomPage(page);
                            setCustomPageSelectLocale("en");
                            setHomeActiveTab("Custom Page Detail");
                          }}
                        >
                          En
                        </button>
                        <button
                          onClick={() => {
                            setSelectCustomPage(page);
                            setCustomPageSelectLocale("ru");
                            setHomeActiveTab("Custom Page Detail");
                          }}
                        >
                          Ru
                        </button>
                        <button
                          onClick={() => {
                            setCustomPageAllListSideBarVisible(true);
                            setSelectCustomPage(page);
                            // setHomeActiveTab("Custom Page Detail")
                            setType("edit");
                          }}
                        >
                          <img src={edit} alt="edit" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {customPageAllListData && (
            <div className="pagination">
              <button
                className="btn prev"
                onClick={() => handleChange("prev")}
                disabled={!customPageAllListData.page_has_previous}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM17.685 19.5C18.12 19.935 18.12 20.655 17.685 21.09C17.46 21.315 17.175 21.42 16.89 21.42C16.605 21.42 16.32 21.315 16.095 21.09L10.8 15.795C10.365 15.36 10.365 14.64 10.8 14.205L16.095 8.91C16.53 8.475 17.25 8.475 17.685 8.91C18.12 9.345 18.12 10.065 17.685 10.5L13.185 15L17.685 19.5Z"
                    fill=" #AD471F"
                  />
                </svg>
              </button>
              {prevNumber !== 0 && (
                <button
                  className="btn prev-number"
                  onClick={() => handleChange("prev")}
                >
                  {prevNumber}
                </button>
              )}
              <button className="btn active">{activeNumber}</button>
              {customPageAllListData && customPageAllListData.page_has_next && (
                <button
                  className="btn next-number"
                  onClick={() => handleChange("next")}
                >
                  {nextNumber}
                </button>
              )}

              {customPageAllListData && (
                <button
                  className="btn next"
                  disabled={!customPageAllListData.page_has_next}
                  onClick={() => handleChange("next")}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM19.185 15.795L13.89 21.09C13.665 21.315 13.38 21.42 13.095 21.42C12.81 21.42 12.525 21.315 12.3 21.09C11.865 20.655 11.865 19.935 12.3 19.5L16.8 15L12.3 10.5C11.865 10.065 11.865 9.345 12.3 8.91C12.735 8.475 13.455 8.475 13.89 8.91L19.185 14.205C19.635 14.64 19.635 15.36 19.185 15.795Z"
                      fill=" #AD471F"
                    />
                  </svg>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={
          customPageAllListSideBarVisible
            ? "overlay-sub-menu active"
            : "overlay-sub-menu"
        }
        onClick={() => {
          setCustomPageAllListSideBarVisible(false);
          setSelectCustomPage({
            page_associated_section: "",
            page_name_az: "",
            page_name_en: "",
            page_name_ru: "",
            page_associated_hotels: [],
          });
          setType("");
        }}
      ></div>
      <CustomPageCreateSideBar type={type} />
    </div>
  );
};

export default CustomPAgeAllList;
