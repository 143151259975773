// React
import { useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { boolean, number, object, string } from "yup";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const CouponSideBarMenu = ({ type, selectCoupon }) => {
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    couponVisible,
    setCouponVisible,
    // selectCoupon,
    setSelectCoupon,
    couponData,
    setCouponData,
  } = useContext(MainContext);

  // Local State
  const [errorText, setErrorText] = useState("");

  // Yup schema
  const couponSchema = object({
    coupon_name: string().required().trim(),
    coupon_code: string().required().trim(),
    coupon_active: boolean().required(),
    coupon_start: string().required().trim(),
    coupon_end: string().required().trim(),
    coupon_percentage_value: number().test(
      "maxDigits",
      "Max Limit 100",
      (number) => number < 101
    ),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(couponSchema),
  });

  useEffect(() => {
    reset();
    setErrorText("");
    if (type === "edit") {
      setValue("coupon_name", selectCoupon.coupon_name);
      setValue("coupon_code", selectCoupon.coupon_code);
      setValue("coupon_active", selectCoupon.coupon_active);
      setValue("coupon_start", selectCoupon.coupon_start);
      setValue("coupon_end", selectCoupon.coupon_end);
      setValue("coupon_percentage_value", selectCoupon.coupon_percentage_value);
    }
  }, [
    couponVisible,
    reset,
    selectCoupon,
    selectCoupon.coupon_active,
    selectCoupon.coupon_code,
    selectCoupon.coupon_end,
    selectCoupon.coupon_name,
    selectCoupon.coupon_percentage_value,
    selectCoupon.coupon_start,
    setValue,
    type,
  ]);

  //Setting Form Submit
  const onSubmit = async (data) => {
    const body = new FormData();
    body.append("coupon_name", data.coupon_name);
    body.append("coupon_code", data.coupon_code);
    body.append("coupon_active", data.coupon_active);
    body.append("coupon_start", data.coupon_start);
    body.append("coupon_end", data.coupon_end);
    body.append("coupon_percentage_value", data.coupon_percentage_value);
    if (selectCoupon.coupon_name === "") {
      try {
        const res = await axios.post(
          process.env.REACT_APP_SITE_COUPON_CREATE,
          body
        );
        setCouponData([res.data, ...couponData]);
        setSelectCoupon({
          coupon_name: "",
          coupon_codeL: "",
          coupon_active: "",
          coupon_start: "",
          coupon_end: "",
          coupon_percentage_value: "",
        });
        notify("Coupon Create!");
        reset();
        setCouponVisible(false);
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              onSubmit(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response.data.errors);
        }
      }
    } else {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SITE_COUPON_UPDATE}${selectCoupon.id}`,
          body
        );

        setCouponData((prevData) => {
          return prevData.map((item) => {
            if (item.id === res.data.id) {
              return res.data;
            }
            return item;
          });
        });

        setSelectCoupon({
          coupon_name: "",
          coupon_codeL: "",
          coupon_active: "",
          coupon_start: "",
          coupon_end: "",
          coupon_percentage_value: "",
        });
        notify("Coupon Update!");
        setCouponVisible(false);
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              onSubmit(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response.data.errors);
        }
      }
    }
  };

  return (
    <div
      className={
        couponVisible
          ? " isOpenMenu coupon-sidebar-menu scroll "
          : "coupon-sidebar-menu scroll  "
      }
    >
      <div className="head ">
        <h4 className="caption">Coupon code overview</h4>
        <div
          className="icon"
          onClick={() => {
            setCouponVisible(false);
            setSelectCoupon({
              coupon_name: "",
              coupon_codeL: "",
              coupon_active: "",
              coupon_start: "",
              coupon_end: "",
              coupon_percentage_value: "",
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body ">
        <form
          action=""
          className="coupon-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-group">
            <label htmlFor="coupon_name" className="inp-caption">
              Name
            </label>
            <input
              type="text"
              className={errors.coupon_name ? "inp error" : "inp"}
              id="coupon_name"
              name="coupon_name"
              autoComplete="off"
              {...register("coupon_name")}
              onChange={() => errorText !== "" && setErrorText("")}
            />
          </div>
          <div className="side-bar-divader"></div>
          <div className="form-group">
            <label htmlFor="coupon_start" className="inp-caption">
              Date from
            </label>
            <input
              type="date"
              className={errors.coupon_start ? "inp error" : "inp"}
              id="coupon_start"
              name="coupon_start"
              {...register("coupon_start")}
              onChange={() => errorText !== "" && setErrorText("")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="coupon_end" className="inp-caption">
              Date to
            </label>
            <input
              type="date"
              className={errors.coupon_end ? "inp error" : "inp"}
              id="coupon_end"
              name="coupon_end"
              {...register("coupon_end")}
              onChange={() => errorText !== "" && setErrorText("")}
            />
          </div>
          <div className="side-bar-divader"></div>
          <div className="form-group">
            <label htmlFor="coupon_code" className="inp-caption">
              Coupon code
            </label>
            <input
              type="text"
              className={errors.coupon_code ? "inp error" : "inp"}
              id="coupon_code"
              name="coupon_code"
              autoComplete="off"
              {...register("coupon_code")}
              onChange={() => errorText !== "" && setErrorText("")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="coupon_percentage_value" className="inp-caption">
              Coupon Percentage Value
            </label>
            <input
              type="number"
              className={errors.coupon_percentage_value ? "inp error" : "inp"}
              id="coupon_percentage_value"
              name="coupon_percentage_value"
              {...register("coupon_percentage_value")}
              onChange={() => errorText !== "" && setErrorText("")}
            />
          </div>
          <div className="side-bar-divader"></div>
          <div className="form-group checkbox-group">
            <label htmlFor="coupon_active" className="inp-caption">
              Activated {""}
            </label>
            <label htmlFor="coupon_active" className="switch">
              <input
                type="checkbox"
                id="coupon_active"
                name="coupon_active"
                className="checkbox"
                {...register("coupon_active")}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <div className="form-footer">
            <p className="error-text">{errorText}</p>
            <div className="btn-area">
              <button>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CouponSideBarMenu;
