// React
import { useContext, useEffect, useRef, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const StandartSideBar = ({ selectData, pricePeriod, suitId }) => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const { standartVisible, setStandartVisible, setPriceList } =
    useContext(MainContext);

  const { logout } = useAuth();

  // Local state
  const [priceData, setPriceData] = useState(null);
  const [errorText, setErrorText] = useState("");

  // Render Data
  useEffect(() => {
    if (standartVisible) {
      setErrorText("");
      setPriceData(selectData);
    }
  }, [selectData, standartVisible]);
  // useEffect(() => {
  //   if (priceListVisible) {
  //     const updatedPriceData = { ...selectData };

  //     // Eğer selectData içindeki prices, pricePeriod kadar öğe içermiyorsa, eksik olanları ekle
  //     pricePeriod.forEach((period) => {
  //       const exists = updatedPriceData.prices.some(
  //         (price) => price.price_associated_schedule_fragment === period.id
  //       );
  //       if (!exists) {
  //         updatedPriceData.prices.push({
  //           price_associated_schedule_fragment: period.id,
  //           price: "",
  //         });
  //       }
  //     });

  //     setPriceData(updatedPriceData);
  //   }
  // }, [priceListVisible, selectData, pricePeriod]);

  const handleChange = (e, key) => {
    // Değişen input değerini state içindeki ilgili anahtara atama
    setPriceData({
      ...priceData,
      [key]: e.target.value,
    });
  };

  // const handleChangePrices = (value, fragmentId) => {
  //   // Fiyat nesnesini bul
  //   let priceObject = priceData.prices.find(
  //     (price) => price.price_associated_schedule_fragment === fragmentId
  //   );

  //   // Eğer fiyat nesnesi bulunamazsa, yeni bir tane oluştur
  //   if (!priceObject) {
  //     priceObject = {
  //       price_associated_schedule_fragment: fragmentId,
  //       price: value,
  //     };

  //     // Yeni fiyat nesnesini priceData'ya ekle
  //     setPriceData((prevData) => ({
  //       ...prevData,
  //       prices: [...prevData.prices, priceObject],
  //     }));
  //   } else {
  //     // Eğer fiyat nesnesi bulunursa, değeri güncelle
  //     setPriceData((prevData) => ({
  //       ...prevData,
  //       prices: prevData.prices.map((price) =>
  //         price.price_associated_schedule_fragment === fragmentId
  //           ? { ...price, price: value }
  //           : price
  //       ),
  //     }));
  //   }
  // };
  const handleChangePrices = (value, fragmentId) => {
    // Fiyat nesnesini bul
    let priceObjectIndex = priceData.prices.findIndex(
      (price) => price.price_associated_schedule_fragment === fragmentId
    );

    if (priceObjectIndex === -1) {
      // Eğer fiyat nesnesi bulunamazsa, yeni bir tane oluştur
      const newPriceObject = {
        price_associated_schedule_fragment: fragmentId,
        price: value,
      };

      // Yeni fiyat nesnesini priceData'ya ekle
      setPriceData((prevData) => ({
        ...prevData,
        prices: [...prevData.prices, newPriceObject],
      }));
    } else {
      // Eğer fiyat nesnesi bulunursa, değeri güncelle
      const updatedPrices = [...priceData.prices]; // Mevcut fiyatları kopyala
      updatedPrices[priceObjectIndex] = {
        ...updatedPrices[priceObjectIndex],
        price: value,
      };
      setPriceData((prevData) => ({
        ...prevData,
        prices: updatedPrices,
      }));
    }
  };
  const sidebar = useRef();
  // Create &&  Update data
  const request = async () => {
    const body = {
      parameters: {
        price_num_staying_guests: Number(priceData.price_num_staying_guests),
        price_staying_period: Number(priceData.price_staying_period),
      },
      prices: priceData.prices.map((price) => ({
        ...price,
        price: parseFloat(price.price),
      })),
    };

    await axios
      .post(
        `${process.env.REACT_APP_SITE_HOTEL_SUIT_PRICE_STANDART_UPTADE_OR_CREATE}${suitId}`,
        body
      )
      .then((res) => {
        sidebar.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setPriceList(res.data);
        notify("Standart Price List Update!");
        setStandartVisible(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              request();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      });
  };

  return (
    <div
      className={
        standartVisible
          ? " isOpenMenu priceList-sidebar-menu scroll"
          : "priceList-sidebar-menu scroll "
      }
      ref={sidebar}
    >
      <div className="head">
        <h4 className="caption">Standart</h4>
        <div className="icon" onClick={() => setStandartVisible(false)}>
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form action="" className="priceList-form ">
          {!selectData?.price_num_staying_guests && (
            <>
              <div className="form-group">
                <label
                  htmlFor="price_num_staying_guests"
                  className="inp-caption"
                >
                  Num. staying guests
                </label>
                <input
                  className="inp"
                  type="number"
                  id="price_num_staying_guests"
                  value={priceData && priceData.price_num_staying_guests}
                  onChange={(e) => handleChange(e, "price_num_staying_guests")}
                />
              </div>
              <div className="form-group">
                <label htmlFor="price_staying_period" className="inp-caption">
                  Staying period
                </label>
                <input
                  className="inp"
                  type="number"
                  id="price_staying_period"
                  value={priceData && priceData.price_staying_period}
                  onChange={(e) => handleChange(e, "price_staying_period")}
                />
              </div>
            </>
          )}
          {/* {priceData &&
            pricePeriod?.map((period) =>
              priceData.prices.map((price) => (
                <div className="form-group">
                  <label htmlFor="" className="inp-caption">
                    {period.fragment_start_month}-{period.fragment_start_day}/{" "}
                    {period.fragment_end_month}-{period.fragment_end_day}
                  </label>
                  <input
                    className="inp"
                    type="number"
                    id="price_staying_period"
                    value={
                    
                      price.price_associated_schedule_fragment === period.id &&
                      price.price
                    }
                    onChange={(e) =>
                      handleChangePrices(e.target.value, period.id)
                    }
                  />
                </div>
              ))
            )} */}
          {priceData &&
            pricePeriod?.map((period) => {
              // Fi̇yat nesnesi̇ni̇ bül
              const matchingPrice = priceData.prices.find(
                (price) =>
                  price.price_associated_schedule_fragment === period.id
              );

              return (
                <div className="form-group" key={period.id}>
                  <label htmlFor="" className="inp-caption">
                    {period.fragment_start_month}-{period.fragment_start_day}/{" "}
                    {period.fragment_end_month}-{period.fragment_end_day}
                  </label>
                  <input
                    className="inp"
                    type="number"
                    id={`price_staying_period_${period.id}`}
                    value={matchingPrice ? matchingPrice.price : ""}
                    onChange={(e) =>
                      handleChangePrices(e.target.value, period.id)
                    }
                  />
                </div>
              );
            })}

          <div className="form-footer">
            <p className="error-text">{errorText}</p>
            <div className="btn-area">
              <button
                onClick={(e) => {
                  e.preventDefault();

                  request();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default StandartSideBar;
