// React
import { useCallback, useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Axios
import axios from "axios";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Toastie
import { notify } from "../../utils/ToastIfyNonfoction";

// Params
import { useNavigate, useParams } from "react-router-dom";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import arrowDown from "../../assets/images/icon/arrow-down.svg";
import { useAuth } from "../../utils/AuthContext";
const HotelCouponSideBar = () => {
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Params
  const { hotelId } = useParams();

  // Global State
  const {
    // Visible State
    hotelCouponSideBarVisible,
    setHotelCouponSideBarVisible,

    // Data
    hotelData,
    setHotelData,
  } = useContext(MainContext);

  // Local State
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [listData, setListData] = useState([]);
  const [newCoupon, setNewCoupon] = useState(null);

  // All Coupon Get Request
  const getAllHotelCoupon = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SITE_COUPON_READ_ALL}?locale=en&page_length=10&page_number=1`
      )
      .then((res) => setListData(res.data.page_data))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getAllHotelCoupon();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [logout, navigate]);

  // Search Coupon Get Request
  const searchHotelCoupon = useCallback(
    async (inputValue) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_COUPON_SEARCH}${inputValue}?locale=en`
        )
        .then((res) => setListData(res.data))
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                searchHotelCoupon(inputValue);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate]
  );

  const dataController = (inp) => {
    if (inp.trim().length > 1) {
      searchHotelCoupon(inp);
    } else {
      getAllHotelCoupon();
    }
  };

  // Yup schema
  const couponSchema = object({
    coupon: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(couponSchema),
  });

  //   Rendering Data
  useEffect(() => {
    reset();
    if (hotelCouponSideBarVisible) {
      getAllHotelCoupon();
    }
  }, [getAllHotelCoupon, hotelCouponSideBarVisible, reset]);

  // Add Coupon
  const addCoupon = async (data) => {
    const existCoupon = hotelData.hotel_associated_coupons.find(
      (item) => item.id === newCoupon.id
    );

    if (!existCoupon) {
      const formData = new FormData();
      const keys = Object.keys(hotelData);
      keys.forEach((key) => {
        formData.append(key, hotelData[key]);
      });
      formData.set(
        "hotel_associated_images",
        hotelData.hotel_associated_images
          ? hotelData.hotel_associated_images.map((item) => item.id).join(",")
          : ""
      );
      formData.set(
        "hotel_associated_main_image",
        hotelData.hotel_associated_main_image
          ? hotelData.hotel_associated_main_image.id
          : ""
      );
      formData.set(
        "hotel_associated_attributes",
        hotelData.hotel_associated_attributes
          ? hotelData.hotel_associated_attributes
              .map((item) => item.id)
              .join(",")
          : ""
      );
      formData.set(
        "hotel_associated_coupons",
        hotelData.hotel_associated_coupons.length !== 0
          ? hotelData.hotel_associated_coupons
              .map((item) => item.id)
              .join(",") +
              "," +
              String(newCoupon.id)
          : String(newCoupon.id)
      );

      await axios
        .put(`${process.env.REACT_APP_SITE_HOTEL_UPTADE}${hotelId}`, formData)
        .then((res) => {
          if (res.status === 200) {
            setHotelData((prevState) => ({
              ...prevState,
              hotel_associated_coupons: [
                newCoupon,
                ...prevState.hotel_associated_coupons,
              ],
            }));
            notify("New Coupon Add!");
            setHotelCouponSideBarVisible(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                addCoupon();
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    }
  };

  return (
    <div
      className={
        hotelCouponSideBarVisible
          ? " isOpenMenu attribute-sidebar-menu scroll"
          : "attribute-sidebar-menu  scroll"
      }
    >
      <div className="head">
        <h4 className="caption">Coupon</h4>
        <div
          className="icon"
          onClick={() => setHotelCouponSideBarVisible(false)}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="atribute-form"
          onSubmit={handleSubmit(addCoupon)}
        >
          <div
            className={`form-group ${
              dropDownVisible ? "select open" : "select"
            } `}
          >
            <label htmlFor="coupon" className="inp-caption">
              Rooms
            </label>
            <input
              type="text"
              //   value={inpValue}
              name="coupon"
              id="coupon"
              className={
                errors.coupon ? "inp select-inp error" : "inp select-inp"
              }
              placeholder="Search and Select Character"
              {...register("coupon")}
              onChange={(e) => dataController(e.target.value)}
              onClick={() => setDropDownVisible(true)}
              autoComplete="off"
            />
            <div
              className="icon"
              onClick={(e) => {
                e.stopPropagation();
                setDropDownVisible(!dropDownVisible);
              }}
            >
              <img src={arrowDown} alt="open-close-dropdown" />
            </div>
            <div
              className="select-area"
              style={{
                height: dropDownVisible
                  ? listData.length === 0
                    ? 60
                    : listData.length > 3
                    ? 111.6
                    : listData.length * 45
                  : 0,
              }}
            >
              <ul className="select-list scroll">
                {listData.length === 0 ? (
                  <li className="select-item">Not Found</li>
                ) : (
                  listData.map((item) => (
                    <li
                      className="select-item"
                      onClick={(e) => {
                        e.target.parentElement.parentElement.parentElement.children[1].classList.remove(
                          "error"
                        );

                        setDropDownVisible(false);
                        setValue("coupon", item.coupon_name);
                        setNewCoupon(item);
                        //   getComment(activeNumber, item.id);
                      }}
                      key={item.id}
                    >
                      {item.coupon_name}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
          <div className="form-footer">
            <p className="error-text"></p>
            <div className="btn-area">
              <button>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HotelCouponSideBar;
