// React
import { useCallback, useContext, useEffect, useState } from "react";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
// Toastie
import { notify } from "../../utils/ToastIfyNonfoction";

// Icon
import down from "../../assets/images/icon/arrow-down.svg";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { MainContext } from "../../utils/MainContext";
import { useAuth } from "../../utils/AuthContext";
const HotelDetailSeoAccordion = () => {
  const { logout } = useAuth();
  // Navigate
  const navigate = useNavigate();

  // Global State
  const { hotelDetailActiveTab } = useContext(MainContext);

  // Local State
  const [accordionVisible, setAccordionVisible] = useState("az");
  const [descriptionData, setDescriptionData] = useState([]);
  const [renderData, setRenderData] = useState(false);

  const { hotelId } = useParams();

  useEffect(() => {
    if (hotelDetailActiveTab === "SEO") {
      setRenderData(true);
    }
  }, [hotelDetailActiveTab]);

  // Yup schema
  const descriptionAzSchema = object({
    seo_title_az: string().required().trim(),
    seo_description_az: string().required().trim(),
  });
  const descriptionEnSchema = object({
    seo_title_en: string().required().trim(),
    seo_description_en: string().required().trim(),
  });
  const descriptionRuSchema = object({
    seo_title_ru: string().required().trim(),
    seo_description_ru: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(
      accordionVisible === "az"
        ? descriptionAzSchema
        : accordionVisible === "en"
        ? descriptionEnSchema
        : descriptionRuSchema
    ),
  });

  const getSeoData = useCallback(async () => {
    await axios
      .get(`${process.env.REACT_APP_SITE_SEO_READ}${hotelId}`)
      .then((res) => {
        setDescriptionData(res.data);
        res.data.map((item) => {
          setValue(`seo_title_${item.seo_locale}`, item.seo_title);
          setValue(`seo_description_${item.seo_locale}`, item.seo_description);
          return true;
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && getSeoData())
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [hotelId, logout, navigate, setValue]);

  useEffect(() => {
    if (renderData) {
      getSeoData();
      setRenderData(false);
    }
  }, [getSeoData, renderData]);

  const editDescription = async (data) => {
    const body = new FormData();

    const checkVisibility = () => {
      if (descriptionData.length === 0) {
        return true;
      } else {
        for (const item of descriptionData) {
          if (item.seo_locale === accordionVisible) {
            return false;
          }
        }
        return true;
      }
    };
    if (checkVisibility()) {
      body.append("seo_title", data[`seo_title_${accordionVisible}`]);
      body.append(
        "seo_description",
        data[`seo_description_${accordionVisible}`]
      );
      body.append("seo_associated_hotel", hotelId);

      body.append("seo_locale", accordionVisible);

      try {
        const res = await axios.post(
          process.env.REACT_APP_SITE_SEO_CREATE,
          body
        );
        if (res) {
          setDescriptionData([...descriptionData, res.data]);
          notify("SEO Create!");
        }
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && editDescription(data))
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      }
    } else {
      let descriptionId = descriptionData.find(
        (item) => item.seo_locale === accordionVisible && item.id
      );
      body.append("seo_title", data[`seo_title_${accordionVisible}`]);
      body.append(
        "seo_description",
        data[`seo_description_${accordionVisible}`]
      );
      body.append("seo_associated_hotel", hotelId);

      body.append("seo_locale", accordionVisible);
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SITE_SEO_UPTADE}${descriptionId.id}`,
          body
        );
        setDescriptionData((prevBannerData) =>
          prevBannerData.map((description) =>
            description.id === res.data.id ? res.data : description
          )
        );

        notify("SEO Update!");
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && editDescription(data))
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      }
    }
  };

  return (
    <div className="description-acordion-area">
      <div
        className={
          accordionVisible === "az"
            ? "accordion-group  active"
            : "accordion-group "
        }
      >
        <div
          className="axordion-head "
          onClick={() =>
            setAccordionVisible(accordionVisible === "az" ? "" : "az")
          }
        >
          <h3 className="acordion-caption">Azerbaijan</h3>
          <div className="icon">
            <img src={down} alt="down" />
          </div>
        </div>
        <form
          className="acrodion-body"
          style={{ height: accordionVisible === "az" ? 350 : 0 }}
          onSubmit={handleSubmit(editDescription)}
        >
          <div className="form-group">
            <label htmlFor="seo_title_az">Title</label>
            <input
              name="seo_title_az"
              id="seo_title_az"
              maxLength={254}
              className={errors.seo_title_az ? "scroll error" : "scroll"}
              {...register("seo_title_az")}
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="seo_description_az">Description</label>
            <textarea
              name="seo_description_az"
              id="seo_description_az"
              cols="30"
              rows="10"
              className={errors.seo_description_az ? "scroll error" : "scroll"}
              {...register("seo_description_az")}
            ></textarea>
          </div>
          <div className="form-footer">
            <div className="handle-area">
              <p className="error-text"></p>

              <button>Save</button>
            </div>
          </div>
        </form>
      </div>
      <div
        className={
          accordionVisible === "en"
            ? "accordion-group  active"
            : "accordion-group "
        }
      >
        <div
          className="axordion-head "
          onClick={() =>
            setAccordionVisible(accordionVisible === "en" ? "" : "en")
          }
        >
          <h3 className="acordion-caption">English</h3>
          <div className="icon">
            <img src={down} alt="down" />
          </div>
        </div>
        <form
          className="acrodion-body"
          style={{ height: accordionVisible === "en" ? 350 : 0 }}
          onSubmit={handleSubmit(editDescription)}
        >
          <div className="form-group">
            <label htmlFor="seo_title_en">Title</label>
            <input
              name="seo_title_en"
              id="seo_title_en"
              maxLength={254}
              className={errors.seo_title_en ? "scroll error" : "scroll"}
              {...register("seo_title_en")}
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="seo_description_en">Description</label>
            <textarea
              name="seo_description_en"
              id="seo_description_en"
              cols="30"
              rows="10"
              className={errors.seo_description_en ? "scroll error" : "scroll"}
              {...register("seo_description_en")}
            ></textarea>
          </div>
          <div className="form-footer">
            <div className="handle-area">
              <p className="error-text"></p>

              <button>Save</button>
            </div>
          </div>
        </form>
      </div>
      <div
        className={
          accordionVisible === "ru"
            ? "accordion-group  active"
            : "accordion-group "
        }
      >
        <div
          className="axordion-head "
          onClick={() =>
            setAccordionVisible(accordionVisible === "ru" ? "" : "ru")
          }
        >
          <h3 className="acordion-caption">Russian</h3>
          <div className="icon">
            <img src={down} alt="down" />
          </div>
        </div>
        <form
          className="acrodion-body"
          style={{ height: accordionVisible === "ru" ? 350 : 0 }}
          onSubmit={handleSubmit(editDescription)}
        >
          <div className="form-group">
            <label htmlFor="seo_title_ru">Title</label>
            <input
              name="seo_title_ru"
              id="seo_title_ru"
              maxLength={254}
              className={errors.seo_title_az ? "scroll error" : "scroll"}
              {...register("seo_title_ru")}
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="seo_description_ru">Description</label>
            <textarea
              name="seo_description_ru"
              id="seo_description_ru"
              cols="30"
              rows="10"
              className={errors.seo_description_ru ? "scroll error" : "scroll"}
              {...register("seo_description_ru")}
            ></textarea>
          </div>
          <div className="form-footer">
            <div className="handle-area">
              <p className="error-text"></p>
              <button>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HotelDetailSeoAccordion;
