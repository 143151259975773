// React
import { useCallback, useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Axios
import axios from "axios";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Toastie
import { notify } from "../../utils/ToastIfyNonfoction";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import arrowDown from "../../assets/images/icon/arrow-down.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const OfferPersentageSideBar = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    // Visible State
    offerPersentageVisible,
    setOfferPersentageVisible,

    // Data
    roomData,
    setRoomData,
    selectRoomID,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [listData, setListData] = useState([]);
  const [newOffer, setNewOffer] = useState(null);
  const [errorText, setErrorText] = useState("");

  // All Coupon Get Request
  const getAllOfferPersentage = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SITE_DISCOUNT_PERCENTAGE_READ_ALL}?page_length=10&page_number=1`
      )
      .then((res) => setListData(res.data.page_data))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getAllOfferPersentage();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [logout, navigate]);

  // Search Coupon Get Request
  const searchOfferPersentage = useCallback(
    async (inputValue) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_DISCOUNT_PERCENTAGE_SEARCH}${inputValue}`
        )
        .then((res) => setListData(res.data))
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                searchOfferPersentage(inputValue);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate]
  );

  const dataController = (inp) => {
    if (inp.trim().length > 1) {
      searchOfferPersentage(inp);
    } else {
      getAllOfferPersentage();
    }
  };

  // Yup schema
  const couponSchema = object({
    coupon: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(couponSchema),
  });

  //   Rendering Data
  useEffect(() => {
    reset();
    if (offerPersentageVisible) {
      setErrorText("");
      getAllOfferPersentage();
    }
  }, [getAllOfferPersentage, offerPersentageVisible, reset]);

  // Add Coupon
  const addOfferFreeNight = async (data) => {
    const existOffer = roomData.suite_associated_special_offer_free_nights.find(
      (item) => item.id === newOffer.id
    );
    console.log(existOffer);

    // if (!existCoupon) {
    const formData = new FormData();
    const keys = Object.keys(roomData);
    // Anahtarları FormData'ya ekleyin
    keys.forEach((key) => {
      formData.append(key, roomData[key]);
    });
    formData.set(
      "suite_associated_main_image",
      roomData.suite_associated_main_image
        ? roomData.suite_associated_main_image.id
        : ""
    );
    formData.set(
      "suite_associated_images",
      roomData.suite_associated_images
        ? roomData.suite_associated_images.map((item) => item.id).join(",")
        : ""
    );

    formData.set(
      "suite_associated_attributes",
      roomData.suite_associated_attributes
        ? roomData.suite_associated_attributes.map((item) => item.id).join(",")
        : ""
    );
    formData.set(
      "suite_associated_special_offer_free_nights",
      roomData.suite_associated_special_offer_free_nights
        ? roomData.suite_associated_special_offer_free_nights
            .map((item) => item.id)
            .join(",")
        : ""
    );

    formData.set(
      "suite_associated_special_offer_special_price",
      roomData.suite_associated_special_offer_special_price
        ? roomData.suite_associated_special_offer_special_price.id
        : ""
    );

    formData.set(
      "suite_associated_special_offer_percentages",
      roomData.suite_associated_special_offer_percentages.length !== 0
        ? roomData.suite_associated_special_offer_percentages
            .map((item) => item.id)
            .join(",") +
            "," +
            String(newOffer.id)
        : String(newOffer.id)
    );
    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }

    await axios
      .put(`${process.env.REACT_APP_SITE_ROOM_UPTADE}${selectRoomID}`, formData)
      .then((res) => {
        setRoomData({
          ...roomData,
          suite_associated_special_offer_percentages:
            res.data.suite_associated_special_offer_percentages,
        });
        notify("Room Offer Free Night Update!");
        setOfferPersentageVisible(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              addOfferFreeNight();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      });
  };

  return (
    <div
      className={
        offerPersentageVisible
          ? " isOpenMenu attribute-sidebar-menu scroll"
          : "attribute-sidebar-menu  scroll"
      }
    >
      <div className="head">
        <h4 className="caption">Offer Persentage</h4>
        <div className="icon" onClick={() => setOfferPersentageVisible(false)}>
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="atribute-form"
          onSubmit={handleSubmit(addOfferFreeNight)}
        >
          <div
            className={`form-group ${
              dropDownVisible ? "select open" : "select"
            } `}
          >
            <label htmlFor="coupon" className="inp-caption">
              Persentage
            </label>
            <input
              type="text"
              //   value={inpValue}
              name="coupon"
              id="coupon"
              className={
                errors.coupon ? "inp select-inp error" : "inp select-inp"
              }
              placeholder="Search and Select Character"
              {...register("coupon")}
              onChange={(e) => dataController(e.target.value)}
              onClick={() => setDropDownVisible(true)}
              autoComplete="off"
            />
            <div
              className="icon"
              onClick={(e) => {
                e.stopPropagation();
                setDropDownVisible(!dropDownVisible);
              }}
            >
              <img src={arrowDown} alt="open-close-dropdown" />
            </div>
            <div
              className="select-area"
              style={{
                height: dropDownVisible
                  ? listData.length === 0
                    ? 60
                    : listData.length > 3
                    ? 111.6
                    : listData.length * 45
                  : 0,
              }}
            >
              <ul className="select-list scroll">
                {listData.length === 0 ? (
                  <li className="select-item">Not Found</li>
                ) : (
                  listData.map((item) => (
                    <li
                      className="select-item"
                      onClick={(e) => {
                        e.target.parentElement.parentElement.parentElement.children[1].classList.remove(
                          "error"
                        );

                        setDropDownVisible(false);
                        setValue("coupon", item.offer_name);
                        setNewOffer(item);
                        //   getComment(activeNumber, item.id);
                      }}
                      key={item.id}
                    >
                      {item.offer_name}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
          <div className="form-footer">
            <p className="error-text">{errorText}</p>
            <div className="btn-area">
              <button>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default OfferPersentageSideBar;
