// React
import { useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";
import { useAuth } from "../../utils/AuthContext";

// Toastie
import { notify } from "../../utils/ToastIfyNonfoction";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import add from "../../assets/images/icon/add-plus.svg";
import trash from "../../assets/images/icon/trash.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PriceListScheduleSideBarMenu = ({ hotelId }) => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    priceListScheduleVisible,
    setPriceListScheduleVisible,
    priceListScheduleData,
    setPriceListScheduleData,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [data, setData] = useState([]);
  const [errText, setErrText] = useState("");

  useEffect(() => {
    if (priceListScheduleVisible) {
      setErrText("");
      const newArray = priceListScheduleData.map((obj) => {
        return { ...obj, action: "update" };
      });

      setData(newArray);
    }
  }, [priceListScheduleData, priceListScheduleVisible, setData]);

  const addPeriod = () => {
    const newObject = {
      action: "create",
      fragment_associated_hotel: "",
      fragment_end_day: "",
      fragment_end_month: "",
      fragment_start_day: "",
      fragment_start_month: "",
      id: "test" + Math.random().toString() + Math.random().toString(),
    };

    setData((prevState) => [...prevState, newObject]);
  };

  const deletePeriod = (dataId) => {
    let updateState;
    if (String(dataId).startsWith("test")) {
      updateState = data.filter((item) => item.id !== dataId);
    } else {
      updateState = data.map((item) => {
        if (item.id === dataId) {
          delete item.fragment_start_month;
          delete item.fragment_start_day;
          delete item.fragment_end_month;
          delete item.fragment_end_day;
          item.action = "delete";
        }
        return item;
      });
    }

    setData(updateState);
  };

  const handleChange = (e, index, key) => {
    if (data[index].action !== "delete") {
      const newData = [...data];
      newData[index][key] = e.target.value;
      setData(newData);
    }
  };

  // Update Period
  const updatePeriod = async () => {
    const updatedData = data.map((item) => {
      if (String(item.id).startsWith("test")) {
        delete item.id;
      }
      item.fragment_associated_hotel = hotelId;
      return item;
    });

    await axios
      .put(
        `${process.env.REACT_APP_SITE_SCHEDULE_UPDATE}/${hotelId}`,
        updatedData
      )
      .then((res) => {
        setPriceListScheduleData(res.data);
        notify("Price Schedules Update!");
        setPriceListScheduleVisible(false);
      })

      .catch((err) => {
        // Jwt Error Check

        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && updatePeriod())
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrText(err.response?.data.errors);
        }
      });
  };

  return (
    <div
      className={
        priceListScheduleVisible
          ? " isOpenMenu priceListSchedule-sidebar-menu scroll"
          : "priceListSchedule-sidebar-menu scroll "
      }
    >
      <div className="head">
        <h4 className="caption">Price list schedule</h4>
        <div
          className="icon"
          onClick={() => setPriceListScheduleVisible(false)}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form action="" className="priceListSchedule-form">
          {data.map(
            (price, index) =>
              price.action !== "delete" && (
                <div className="price-group" key={index + "t"}>
                  <div className="price-top">
                    <h3 className="price-caption">Period {index + 1}</h3>
                    <div
                      className="delete"
                      onClick={() => deletePeriod(price.id)}
                    >
                      <img src={trash} alt="delete" />
                    </div>
                  </div>
                  <div className="inp-group">
                    <div className="form-group">
                      <label htmlFor="" className="inp-caption">
                        Start Month
                      </label>
                      <input
                        type="number"
                        className="inp"
                        value={price.fragment_start_month}
                        onChange={(e) =>
                          handleChange(e, index, "fragment_start_month")
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="" className="inp-caption">
                        Start Day
                      </label>
                      <input
                        type="number"
                        className="inp"
                        value={price.fragment_start_day}
                        onChange={(e) =>
                          handleChange(e, index, "fragment_start_day")
                        }
                      />
                    </div>
                  </div>
                  <div className="inp-group">
                    <div className="form-group">
                      <label htmlFor="" className="inp-caption">
                        End Month
                      </label>
                      <input
                        type="number"
                        className="inp"
                        value={price.fragment_end_month}
                        onChange={(e) =>
                          handleChange(e, index, "fragment_end_month")
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="" className="inp-caption">
                        End Day
                      </label>
                      <input
                        type="number"
                        className="inp"
                        value={price.fragment_end_day}
                        onChange={(e) =>
                          handleChange(e, index, "fragment_end_day")
                        }
                      />
                    </div>
                  </div>
                </div>
              )
          )}
          <div className="price-period-add">
            <h6 className="title">Add Period</h6>
            <button
              className="add-btn"
              onClick={(e) => {
                // e.stopPropagation()
                e.preventDefault();
                addPeriod();
              }}
            >
              Add <img src={add} alt="add" />
            </button>
          </div>

          <div className="form-footer">
            <p className="error-text">{errText}</p>
            <div className="btn-area">
              <button
                onClick={(e) => {
                  e.preventDefault();

                  updatePeriod();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PriceListScheduleSideBarMenu;
