// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

import arrow from "../../assets/images/icon/arrow-left.svg";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const HotelAtributeGroupSideBar = () => {
  // Global State
  const {
    attributeData,
    // setAttributeData,
    atributeGroupSideBarVisible,
    setAtributeGroupSideBarVisible,
    selectAttributeGroup,
    setSelectAttributeGroup,
    atributeActiveTab,
    attributeProccesType,

    setAttributeGroupData,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [errorText, setErrorText] = useState("");

  // Navigate
  const navigate = useNavigate();

  // Yup schema
  const attributeSchema = object({
    attribute_group: string().required().trim(),
    attribute_group_name_az: string().required().trim(),
    attribute_group_name_en: string().required().trim(),
    attribute_group_name_ru: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(attributeSchema),
  });

  useEffect(() => {
    if (atributeGroupSideBarVisible) {
      reset();
      if (
        selectAttributeGroup.attribute_group &&
        attributeProccesType === "edit"
      ) {
        setValue("attribute_group", selectAttributeGroup.attribute_group);
        setValue(
          "attribute_group_name_az",
          selectAttributeGroup.attribute_group_name_az
        );
        setValue(
          "attribute_group_name_en",
          selectAttributeGroup.attribute_group_name_en
        );
        setValue(
          "attribute_group_name_ru",
          selectAttributeGroup.attribute_group_name_ru
        );
        setValue("attribute_name_az", selectAttributeGroup.attribute_name_az);
        setValue("attribute_name_en", selectAttributeGroup.attribute_name_en);
        setValue("attribute_name_ru", selectAttributeGroup.attribute_name_ru);
      }
    }
  }, [
    atributeGroupSideBarVisible,
    attributeData,
    atributeActiveTab,
    reset,
    selectAttributeGroup,
    setValue,
    attributeProccesType,
  ]);

  // Create && Edit
  const submit = async (data) => {
    const body = new FormData();

    body.append("attribute_group", data.attribute_group);
    body.append("attribute_group_name_az", data.attribute_group_name_az);
    body.append("attribute_group_name_en", data.attribute_group_name_en);
    body.append("attribute_group_name_ru", data.attribute_group_name_ru);

    /* ------------------------------ Check Process ----------------------------- */
    // Edit
    if (attributeProccesType === "edit") {
      if (atributeActiveTab === "Hotel Attribute Groups") {
        try {
          const res = await axios.put(
            `${process.env.REACT_APP_SITE_HOTEL_ATTRIBUTE_GROUP_UPDATE}/${selectAttributeGroup.id}            `,
            body
          );
          setAttributeGroupData((prevData) => {
            return prevData.map((item) => {
              if (item.id === res.data.id) {
                return res.data;
              }
              return item;
            });
          });

          setAtributeGroupSideBarVisible(false);
          setSelectAttributeGroup({
            attribute_group: "",
            attribute_group_name_az: "",
            attribute_group_name_en: "",
            attribute_group_name_ru: "",
            id: "",
          });
          notify("Hotel Attribute Groups Update!");
        } catch (err) {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                submit(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        }
      }
      // Suit Attribute Edit Put Method
      else {
        try {
          const res = await axios.put(
            `${process.env.REACT_APP_SITE_SUIT_ATTRIBUTE_GROUP_UPDATE}/${selectAttributeGroup.id}`,
            body
          );

          setAttributeGroupData((prevData) => {
            return prevData.map((item) => {
              if (item.id === res.data.id) {
                return res.data;
              }
              return item;
            });
          });
          setAtributeGroupSideBarVisible(false);
          setSelectAttributeGroup({
            attribute_group: "",
            attribute_group_name_az: "",
            attribute_group_name_en: "",
            attribute_group_name_ru: "",
            id: "",
          });
          notify("Suit Attribute Groups Update!");
        } catch (err) {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                submit(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        }
      }
    } else {
      /* --------------------------------- Create --------------------------------- */
      /* ----------------------- Hotel Attribute Create ----------------------- */
      if (atributeActiveTab === "Hotel Attributes") {
        await axios
          .post(process.env.REACT_APP_SITE_HOTEL_ATTRIBUTE_GROUP_CREATE, body)
          .then((res) => {
            // setAttributeData([res.data, ...attributeData]);
            reset();
            setAtributeGroupSideBarVisible(false);
            setSelectAttributeGroup({
              attribute_group: "",
              attribute_group_name_az: "",
              attribute_group_name_en: "",
              attribute_group_name_ru: "",
              id: "",
            });
            notify("Hotel Attribute Groups Create!");
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  submit(data);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            } else {
              setErrorText(err.response?.data.errors);
            }
          });
      } else {
        /* ----------------------- Suit Attribute Create ----------------------- */
        await axios
          .post(process.env.REACT_APP_SITE_SUIT_ATTRIBUTE_GROUP_CREATE, body)
          .then((res) => {
            // setAttributeData([res.data, ...attributeData]);
            reset();
            setAtributeGroupSideBarVisible(false);
            setSelectAttributeGroup({
              attribute_group: "",
              attribute_group_name_az: "",
              attribute_group_name_en: "",
              attribute_group_name_ru: "",
              id: "",
            });
            notify("Suit Attribute Groups Create!");
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  submit(data);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            } else {
              setErrorText(err.response?.data.errors);
            }
          });
      }
    }
  };
  return (
    <div
      className={
        atributeGroupSideBarVisible
          ? " isOpenMenu sidebar-discount-menu scroll "
          : "sidebar-discount-menu  scroll  "
      }
    >
      <div className="head">
        <h4 className="caption">
          {atributeActiveTab === "Hotel Attributes"
            ? "Hotel Attributes"
            : "Suit Attributes"}
        </h4>
        <div
          className="icon"
          onClick={() => {
            setAtributeGroupSideBarVisible(false);
            setSelectAttributeGroup({
              attribute_group: "",
              attribute_group_name_az: "",
              attribute_group_name_en: "",
              attribute_group_name_ru: "",
              id: "",
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="discount-form"
          onSubmit={handleSubmit(submit)}
        >
          <div className="form-group">
            <label htmlFor="attribute_group" className="inp-caption">
              Category name
            </label>
            <input
              type="text"
              className={errors.attribute_group ? "inp error" : "inp"}
              name="attribute_group"
              id="attribute_group"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("attribute_group")}
            />
          </div>
          <div className="side-bar-divader"></div>
          <div className="form-group">
            <label htmlFor="attribute_group_name_az" className="inp-caption">
              Group Name Az
            </label>
            <input
              type="text"
              className={errors.attribute_group_name_az ? "inp error" : "inp"}
              name="attribute_group_name_az"
              id="attribute_group_name_az"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("attribute_group_name_az")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="attribute_group_name_en" className="inp-caption">
              Group Name En
            </label>
            <input
              type="text"
              className={errors.attribute_group_name_en ? "inp error" : "inp"}
              name="attribute_group_name_en"
              id="attribute_group_name_en"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("attribute_group_name_en")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="attribute_group_name_ru" className="inp-caption">
              Group Name Ru
            </label>
            <input
              type="text"
              className={errors.attribute_group_name_ru ? "inp error" : "inp"}
              name="attribute_group_name_ru"
              id="attribute_group_name_ru"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("attribute_group_name_ru")}
            />
          </div>

          <div className="form-footer">
            <button>Save</button>
            <p className="error-text">{errorText}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HotelAtributeGroupSideBar;
