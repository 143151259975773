// React
import { useContext, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { boolean, object, string } from "yup";

// Axios
import axios from "axios";

// Params
import { useNavigate, useParams } from "react-router-dom";

// Icon
import arrowDown from "../../assets/images/icon/arrow-down.svg";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useAuth } from "../../utils/AuthContext";
const HotelDetailRoomAdd = () => {
// Global State
const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Params
  const { hotelId } = useParams();

  // Global State
  const { setHotelDetailActiveTab } = useContext(MainContext);

  // Local State
  const [dropDownVisible, setDropDownVisible] = useState(null);
  const [errorText, setErrorText] = useState("");
  const suite_types = [
    { id: 1, character: "single-suite" },
    { id: 2, character: "double-suite" },
    { id: 3, character: "family-suite" },
    { id: 4, character: "executive-suite" },
    { id: 5, character: "presidential-suite" },
  ];
  const suite_pricing_types = [
    { id: 1, character: "per-night" },
    { id: 2, character: "per-person per-night" },
  ];

  // Yup schema
  const createSchema = object({
    suite_active: boolean().nullable().optional(),
    suite_additional_guest_after: string().required().trim(),
    suite_min_capacity: string().required().trim(),
    suite_max_capacity: string().required().trim(),
    suite_type: string().required().trim(),
    suite_pricing_type: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(createSchema),
  });

  //   Create Room
  const createRoom = async (data) => {
    const body = new FormData();

    body.append("suite_active", data.suite_active);
    body.append(
      "suite_additional_guest_after",
      data.suite_additional_guest_after
    );
    body.append("suite_min_capacity", data.suite_min_capacity);
    body.append("suite_max_capacity", data.suite_max_capacity);
    body.append("suite_type", data.suite_type);
    body.append("suite_pricing_type", data.suite_pricing_type);
    body.append("suite_associated_hotel", hotelId);
    body.append("suite_associated_main_image", "");
    body.append("suite_associated_images", "");
    body.append("suite_associated_attributes", "");
    body.append("suite_associated_special_offer_percentages", "");
    body.append("suite_associated_special_offer_percentages", "");
    body.append("suite_associated_special_offer_special_price", "");

    await axios
      .post(process.env.REACT_APP_SITE_ROOM_CREATE, body)
      .then((res) => {
        if (res) {
          notify("Room Create!");
          setHotelDetailActiveTab("Rooms");
          reset();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              createRoom(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      });
  };

  return (
    <div className="hotel-detai-room-add">
      <div className="container">
        <form
          action=""
          className="room-main-form"
          onSubmit={handleSubmit(createRoom)}
        >
          <div className="inp-area">
            <div
              className={`form-group ${
                dropDownVisible === "suite_type" ? "select open" : "select"
              } `}
            >
              <label htmlFor="suit-suite_type" className="inp-caption">
                Suit Type
              </label>
              <input
                type="text"
                id="suit-suite_type"
                name="suit-suite_type"
                // value={inpValue}
                readOnly
                className={
                  errors.suite_type ? "inp select-inp error" : "inp select-inp"
                }
                placeholder="Search and Select Character"
                onClick={() =>
                  setDropDownVisible(
                    dropDownVisible === "suite_type" ? null : "suite_type"
                  )
                }
                {...register("suite_type", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
              <div className="icon">
                <img src={arrowDown} alt="open-close-dropdown" />
              </div>
              <div
                className="select-area"
                style={{ height: dropDownVisible ? 120 : 0 }}
              >
                <ul className="select-list scroll">
                  {suite_types.map((item) => (
                    <li
                      className="select-item"
                      onClick={(e) => {
                        e.target.parentElement.parentElement.parentElement.children[1].classList.remove(
                          "error"
                        );
                        setValue("suite_type", item.character);
                        setDropDownVisible(false);
                      }}
                      key={item.id}
                    >
                      {item.character}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              className={`form-group ${
                dropDownVisible === "suite_pricing_type"
                  ? "select open"
                  : "select"
              } `}
            >
              <label htmlFor="suite_pricing_type" className="inp-caption">
                Suite Pricing Types
              </label>
              <input
                type="text"
                id="suite_pricing_type"
                name="suite_pricing_type"
                readOnly
                className={
                  errors.suite_pricing_type
                    ? "inp select-inp error"
                    : "inp select-inp"
                }
                placeholder="Select"
                onClick={() =>
                  setDropDownVisible(
                    dropDownVisible === "suite_pricing_type"
                      ? null
                      : "suite_pricing_type"
                  )
                }
                {...register("suite_pricing_type", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
              <div
                className="icon"
                onClick={() =>
                  setDropDownVisible(
                    dropDownVisible === "suite_pricing_type"
                      ? null
                      : "pricing_types"
                  )
                }
              >
                <img src={arrowDown} alt="open-close-dropdown" />
              </div>
              <div
                className="select-area"
                style={{
                  height: dropDownVisible === "suite_pricing_type" ? 85 : 0,
                }}
              >
                <ul className="select-list scroll">
                  {suite_pricing_types.map((item) => (
                    <li
                      className="select-item"
                      onClick={(e) => {
                        e.target.parentElement.parentElement.parentElement.children[1].classList.remove(
                          "error"
                        );
                        setValue("suite_pricing_type", item.character);
                        setDropDownVisible(false);
                      }}
                      key={item.id}
                    >
                      {item.character}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="suite_max_capacity">Max Capacity</label>
              <input
                type="number"
                className={errors.suite_max_capacity ? "inp error" : "inp"}
                id="suite_max_capacity"
                name="suite_max_capacity"
                {...register("suite_max_capacity", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="suite_min_capacity">Min Capacity</label>
              <input
                type="number"
                className={errors.suite_min_capacity ? "inp error" : "inp"}
                id="suite_min_capacity"
                name="suite_min_capacity"
                {...register("suite_min_capacity", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="suite_additional_guest_after">
                Addition Guest After
              </label>
              <input
                type="number"
                className={
                  errors.suite_additional_guest_after ? "inp error" : "inp"
                }
                name="suite_additional_guest_after"
                id="suite_additional_guest_after"
                {...register("suite_additional_guest_after", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>
            <div className="form-group checkbox-group">
              <label htmlFor="suite_active" className="inp-caption">
                Activated {""}
              </label>
              <label htmlFor="suite_active" className="switch">
                <input
                  type="checkbox"
                  name="suite_active"
                  id="suite_active"
                  className="checkbox"
                  {...register("suite_active")}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="form-footer">
            <p className="error-text">{errorText}</p>
            <button>Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HotelDetailRoomAdd;
