// React
import { useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
// Toastie
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import down from "../../assets/images/icon/arrow-down.svg";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const EmailSideBarMenu = ({ selectEmail }) => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    emailVisible,
    setEmailVisible,
    emailData,
    setEmailData,
    // selectEmail,
    setSelectEmail,
    emailErrorText,
    setEmailErrorText,
  } = useContext(MainContext);
  const { logout } = useAuth();

  const [accordionVisible, setAccordionVisible] = useState("az");
  const [editDescription, setEditDescription] = useState(0);

  // Yup schema
  const emailAzSchema = object({
    description_subject_az: string().required().trim(),
    description_text_az: string().required().trim(),
    description_mail_az: string().email().required().trim(),
    description_big_caption_az: string().required().trim(),
    description_phone_az: string().required().trim(),
    description_small_caption_az: string().required().trim(),
  });
  const emailEnSchema = object({
    description_subject_en: string().required().trim(),
    description_text_en: string().required().trim(),
    description_mail_en: string().email().required().trim(),
    description_big_caption_en: string().required().trim(),
    description_phone_en: string().required().trim(),
    description_small_caption_en: string().required().trim(),
  });
  const emailRuSchema = object({
    description_subject_ru: string().required().trim(),
    description_text_ru: string().required().trim(),
    description_mail_ru: string().email().required().trim(),
    description_big_caption_ru: string().required().trim(),
    description_phone_ru: string().required().trim(),
    description_small_caption_ru: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(
      accordionVisible === "az"
        ? emailAzSchema
        : accordionVisible === "en"
        ? emailEnSchema
        : emailRuSchema
    ),
  });
  useEffect(() => {
    reset();
    if (emailVisible) {
      selectEmail.template_associated_descriptions.map((email) => {
        setValue(
          `description_text_${email.description_locale}`,
          email.description_subject
        );
        setValue(
          `description_subject_${email.description_locale}`,
          email.description_text
        );

        setValue(
          `description_mail_${email.description_locale}`,
          email.description_mail
        );
        setValue(
          `description_big_caption_${email.description_locale}`,
          email.description_big_caption
        );
        setValue(
          `description_phone_${email.description_locale}`,
          email.description_phone
        );
        setValue(
          `description_small_caption_${email.description_locale}`,
          email.description_small_caption
        );
      });
    }
  }, [
    accordionVisible,
    emailVisible,
    reset,
    selectEmail.template_associated_descriptions,
    setValue,
  ]);

  // useEffect(()=>{
  //   if(accordionVisible){
  //     let descriptionId = selectEmail.template_associated_descriptions.find(
  //       (item) => item.description_locale === accordionVisible && item.id
  //     );
  //     setEditDescription(descriptionId.id)
  //   }
  // },[])
console.log(selectEmail)
  const editEmail = async (data) => {
    const body = new FormData();

    const checkVisibility = () => {
      if (selectEmail.template_associated_descriptions.length === 0) {
        return true;
      } else {
        for (const item of selectEmail.template_associated_descriptions) {
          if (item.description_locale === accordionVisible) {
            return false;
          }
        }
        return true;
      }
    };
    if (checkVisibility()) {
      body.append(
        "description_subject",
        data[`description_text_${accordionVisible}`]
      );
      body.append(
        "description_text",
        data[`description_subject_${accordionVisible}`]
      );

      body.append(
        "description_mail",
        data[`description_mail_${accordionVisible}`]
      );
      body.append(
        "description_big_caption",
        data[`description_big_caption_${accordionVisible}`]
      );
      body.append(
        "description_phone",
        data[`description_phone_${accordionVisible}`]
      );
      body.append(
        "description_small_caption",
        data[`description_small_caption_${accordionVisible}`]
      );

      body.append("description_associated_template", selectEmail.id);
      body.delete("descriptiotionId.in_locale");
      body.append("description_locale", accordionVisible);
      try {
        const res = await axios.post(
          process.env.REACT_APP_SITE_EMAIL_TEMPLATES_CREATE,
          body
        );
        notify("Email Template Data Create!");
        reset();
        window.location.reload();
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              editEmail(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setEmailErrorText(err.response?.data.errors);
        }
      }
    } else {
      let descriptionId = selectEmail.template_associated_descriptions.find(
        (item) => item.description_locale === accordionVisible && item.id
      );

      body.append("description_locale", accordionVisible);
      body.append(
        "description_subject",
        data[`description_text_${accordionVisible}`]
      );

      body.append(
        "description_text",
        data[`description_subject_${accordionVisible}`]
      );




      body.append(
        "description_mail",
        data[`description_mail_${accordionVisible}`]
      );
      body.append(
        "description_big_caption",
        data[`description_big_caption_${accordionVisible}`]
      );
      body.append(
        "description_phone",
        data[`description_phone_${accordionVisible}`]
      );
      body.append(
        "description_small_caption",
        data[`description_small_caption_${accordionVisible}`]
      );
      body.append("description_associated_template", selectEmail.id);
      










      // body.append("description_mail", "mail@naftalan.com");
      // body.append(
      //   "description_big_caption",
      //   "Мы сейчас проверяем наличие свободного номера в соответствии с вашим запросом. Мы свяжемся с вами по электронной почте или телефону в течение следующих двух рабочих часов и предоставим информацию о статусе вашего бронирования."
      // );
      // body.append("description_phone", "+994505722000");
      // body.append(
      //   "description_small_caption",
      //   "Спасибо за использование нашей системы бронирования!"
      // );
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SITE_EMAIL_TEMPLATES_UPDATE}${descriptionId.id}`,
          body
        );
        // setCouponData(prevData => {
        //   return prevData.map(item => {
        //     if (item.id === res.data.id) {
        //       return res.data;
        //     }
        //     return item;
        //   });
        // });
        notify("Email Template Update!");
        window.location.reload();
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              editEmail(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setEmailErrorText(err.response?.data.errors);
        }
      }
    }
  };

  return (
    <div
      className={
        emailVisible
          ? " isOpenMenu email-sidebar-menu scroll "
          : "email-sidebar-menu  scroll "
      }
    >
      <div className="head">
        <h4 className="caption">Email templates</h4>
        <div className="icon" onClick={() => setEmailVisible(false)}>
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <div className="email-acordion-area">
          {/*  Azerbaijan  */}
          <div
            className={
              accordionVisible === "az"
                ? "accordion-group  active"
                : "accordion-group "
            }
          >
            <div
              className="axordion-head "
              onClick={() =>
                setAccordionVisible(accordionVisible === "az" ? "" : "az")
              }
            >
              <h3 className="acordion-caption">Azerbaijan</h3>
              <div className="icon">
                <img src={down} alt="down" />
              </div>
            </div>
            <form
              className="acrodion-body"
              style={{ height: accordionVisible === "az" ? 825 : 0 }}
              onSubmit={handleSubmit(editEmail)}
            >
              {/* Title */}
              <div className="form-group">
                <label htmlFor="description_text_az">Title</label>
                <input
                  className={errors.description_text_az ? "inp error" : "inp"}
                  type="text"
                  name="description_text_az"
                  id="description_text_az"
                  {...register("description_text_az")}
                />
              </div>

              {/* Email */}
              <div className="form-group">
                <label htmlFor="description_mail_az">Email</label>
                <input
                  className={errors.description_mail_az ? "inp error" : "inp"}
                  type="email"
                  name="description_mail_az"
                  id="description_mail_az"
                  {...register("description_mail_az")}
                />
              </div>
              {/* Phone */}
              <div className="form-group">
                <label htmlFor="description_phone_az">Phone</label>
                <input
                  className={errors.description_phone_az ? "inp error" : "inp"}
                  type="tel"
                  name="description_phone_az"
                  id="description_phone_az"
                  {...register("description_phone_az")}
                />
              </div>
              {/* Short Description */}
              <div className="form-group">
                <label htmlFor="description_small_caption_az">
                  Short Description
                </label>
                <textarea
                  className={
                    errors.description_small_caption_az ? "inp error" : "inp"
                  }
                  type="text"
                  name="description_small_caption_az"
                  id="description_small_caption_az"
                  {...register("description_small_caption_az")}
                />
              </div>
              {/* Long Description */}
              <div className="form-group">
                <label htmlFor="description_big_caption_az">
                  Long Description
                </label>
                <textarea
                  className={
                    errors.description_big_caption_az ? "inp error" : "inp"
                  }
                  type="text"
                  name="description_big_caption_az"
                  id="description_big_caption_az"
                  {...register("description_big_caption_az")}
                />
              </div>

              {/* Text */}
              <div className="form-group">
                <label htmlFor="description_subject_az">Text</label>
                <textarea
                  className={
                    errors.description_subject_az
                      ? "scroll large  error"
                      : "scroll large"
                  }
                  name="description_subject_az"
                  id="description_subject_az"
                  cols="30"
                  rows="10"
                  {...register("description_subject_az")}
                ></textarea>
              </div>
              <div className="form-footer">
                <div className="handle-area">
                  <p className="error-text"></p>
                  <button>Save</button>
                </div>
              </div>
            </form>
          </div>

          {/* English */}
          <div
            className={
              accordionVisible === "en"
                ? "accordion-group  active"
                : "accordion-group "
            }
          >
            <div
              className="axordion-head "
              onClick={() =>
                setAccordionVisible(accordionVisible === "en" ? "" : "en")
              }
            >
              <h3 className="acordion-caption">English</h3>
              <div className="icon">
                <img src={down} alt="down" />
              </div>
            </div>
            <form
              className="acrodion-body"
              style={{ height: accordionVisible === "en" ? 825 : 0 }}
              onSubmit={handleSubmit(editEmail)}
            >
              {/* Title */}
              <div className="form-group">
                <label htmlFor="description_text_en">Title</label>
                <input
                  className={errors.description_text_en ? "inp error" : "inp"}
                  type="text"
                  name="description_text_en"
                  id="description_text_en"
                  {...register("description_text_en")}
                />
              </div>

              {/* Email */}
              <div className="form-group">
                <label htmlFor="description_mail_en">Email</label>
                <input
                  className={errors.description_mail_en ? "inp error" : "inp"}
                  type="email"
                  name="description_mail_en"
                  id="description_mail_en"
                  {...register("description_mail_en")}
                />
              </div>
              {/* Phone */}
              <div className="form-group">
                <label htmlFor="description_phone_en">Phone</label>
                <input
                  className={errors.description_phone_en ? "inp error" : "inp"}
                  type="tel"
                  name="description_phone_en"
                  id="description_phone_en"
                  {...register("description_phone_en")}
                />
              </div>
              {/* Short Description */}
              <div className="form-group">
                <label htmlFor="description_small_caption_en">
                  Short Description
                </label>
                <textarea
                  className={
                    errors.description_small_caption_en ? "inp error" : "inp"
                  }
                  type="text"
                  name="description_small_caption_en"
                  id="description_small_caption_en"
                  {...register("description_small_caption_en")}
                />
              </div>
              {/* Long Description */}
              <div className="form-group">
                <label htmlFor="description_big_caption_en">
                  Long Description
                </label>
                <textarea
                  className={
                    errors.description_big_caption_en ? "inp error" : "inp"
                  }
                  type="text"
                  name="description_big_caption_en"
                  id="description_big_caption_en"
                  {...register("description_big_caption_en")}
                />
              </div>

              {/* Text */}
              <div className="form-group">
                <label htmlFor="description_subject_en">Text</label>
                <textarea
                  className={
                    errors.description_subject_en
                      ? "scroll large  error"
                      : "scroll large"
                  }
                  name="description_subject_en"
                  id="description_subject_en"
                  cols="30"
                  rows="10"
                  {...register("description_subject_en")}
                ></textarea>
              </div>
              <div className="form-footer">
                <div className="handle-area">
                  <p className="error-text"></p>
                  <button>Save</button>
                </div>
              </div>
            </form>
          </div>

          {/* Russian */}
          <div
            className={
              accordionVisible === "ru"
                ? "accordion-group  active"
                : "accordion-group "
            }
          >
            <div
              className="axordion-head "
              onClick={() =>
                setAccordionVisible(accordionVisible === "ru" ? "" : "ru")
              }
            >
              <h3 className="acordion-caption">Russian</h3>
              <div className="icon">
                <img src={down} alt="down" />
              </div>
            </div>
            <form
              className="acrodion-body"
              style={{ height: accordionVisible === "ru" ? 825 : 0 }}
              onSubmit={handleSubmit(editEmail)}
            >
              {/* Title */}
              <div className="form-group">
                <label htmlFor="description_text_ru">Title</label>
                <input
                  className={errors.description_text_ru ? "inp error" : "inp"}
                  type="text"
                  name="description_text_ru"
                  id="description_text_ru"
                  {...register("description_text_ru")}
                />
              </div>

              {/* Email */}
              <div className="form-group">
                <label htmlFor="description_mail_ru">Email</label>
                <input
                  className={errors.description_mail_ru ? "inp error" : "inp"}
                  type="email"
                  name="description_mail_en"
                  id="description_mail_ru"
                  {...register("description_mail_ru")}
                />
              </div>
              {/* Phone */}
              <div className="form-group">
                <label htmlFor="description_phone_ru">Phone</label>
                <input
                  className={errors.description_phone_ru ? "inp error" : "inp"}
                  type="tel"
                  name="description_phone_ru"
                  id="description_phone_ru"
                  {...register("description_phone_ru")}
                />
              </div>
              {/* Short Description */}
              <div className="form-group">
                <label htmlFor="description_small_caption_ru">
                  Short Description
                </label>
                <textarea
                  className={
                    errors.description_small_caption_ru ? "inp error" : "inp"
                  }
                  type="text"
                  name="description_small_caption_ru"
                  id="description_small_caption_ru"
                  {...register("description_small_caption_ru")}
                />
              </div>
              {/* Long Description */}
              <div className="form-group">
                <label htmlFor="description_big_caption_ru">
                  Long Description
                </label>
                <textarea
                  className={
                    errors.description_big_caption_ru ? "inp error" : "inp"
                  }
                  type="text"
                  name="description_big_caption_ru"
                  id="description_big_caption_ru"
                  {...register("description_big_caption_ru")}
                />
              </div>

              {/* Text */}
              <div className="form-group">
                <label htmlFor="description_subject_ru">Text</label>
                <textarea
                  className={
                    errors.description_subject_ru
                      ? "scroll large  error"
                      : "scroll large"
                  }
                  name="description_subject_ru"
                  id="description_subject_ru"
                  cols="30"
                  rows="10"
                  {...register("description_subject_ru")}
                ></textarea>
              </div>
              <div className="form-footer">
                <div className="handle-area">
                  <p className="error-text"></p>

                  <button>Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </div>
  );
};

export default EmailSideBarMenu;
