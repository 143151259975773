// React
import { useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import down from "../../assets/images/icon/arrow-down.svg";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const FaqSideBar = ({ type }) => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const { selectFaq, setFaqData, setSelectFaq, faqVisible, setFaqVisible } =
    useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [accordionVisible, setAccordionVisible] = useState("az");

  const [errorText, setErrorText] = useState("");

  // React hook form
  const diseaseSchema = object({
    question_title_az: string().required().trim(),
    question_answer_az: string().required().trim(),
    question_title_ru: string().required().trim(),
    question_answer_ru: string().required().trim(),

    question_title_en: string().required().trim(),
    question_answer_en: string().required().trim(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(diseaseSchema),
  });

  useEffect(() => {
    setAccordionVisible("az");

    reset();
    setErrorText("");
    if (faqVisible && type === "edit") {
      setValue("question_title_az", selectFaq.question_title_az);
      setValue("question_answer_az", selectFaq.question_answer_az);
      setValue("question_title_ru", selectFaq.question_title_ru);
      setValue("question_answer_ru", selectFaq.question_answer_ru);
      setValue("question_title_en", selectFaq.question_title_en);
      setValue("question_answer_en", selectFaq.question_answer_en);
    }
  }, [
    faqVisible,
    reset,
    selectFaq.disease_characteristics_az,
    selectFaq.disease_characteristics_en,
    selectFaq.disease_characteristics_ru,
    selectFaq.question_title_az,
    selectFaq.question_title_ru,
    selectFaq.question_title_en,
    selectFaq.question_answer_az,
    selectFaq.question_answer_ru,
    selectFaq.question_answer_en,
    setValue,
    type,
  ]);
  const updateDisease = async (data) => {
    const body = new FormData();
    body.append("question_title_az", data.question_title_az);
    body.append("question_answer_az", data.question_answer_az);
    body.append("question_title_ru", data.question_title_ru);
    body.append("question_answer_ru", data.question_answer_ru);
    body.append("question_title_en", data.question_title_en);
    body.append("question_answer_en", data.question_answer_en);

    if (type === "create") {
      await axios
        .post(process.env.REACT_APP_SITE_FAQ_CREATE, body)
        .then((res) => {
          setFaqData((prevState) => ({
            ...prevState,
            page_data: [res.data, ...prevState.page_data],
          }));
          notify("Faq Create!");
          setFaqVisible(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                updateDisease(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        });
    } else {
      await axios
        .put(`${process.env.REACT_APP_SITE_FAQ_UPDATE}/${selectFaq.id}`, body)
        .then((res) => {
          setFaqData((prevState) => ({
            ...prevState,
            page_data: prevState.page_data.map((item) =>
              item.id === res.data.id ? res.data : item
            ),
          }));

          notify("Faq Update!");
          setSelectFaq({
            question_title_az: "",
            question_answer_az: "",
            question_title_ru: "",
            question_answer_ru: "",
            question_title_en: "",
            question_answer_en: "",
            id: "",
          });
          setFaqVisible(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                updateDisease(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        });
    }
  };

  return (
    <div
      className={
        faqVisible
          ? " isOpenMenu disease-sidebar-menu scroll "
          : "disease-sidebar-menu  scroll "
      }
    >
      <div className="head">
        <h4 className="caption">Disease</h4>
        <div
          className="icon"
          onClick={(e) => {
            e.stopPropagation();
            setFaqVisible(false);

            setSelectFaq({
              question_title_az: "",
              question_answer_az: "",
              question_title_ru: "",
              question_answer_ru: "",
              question_title_en: "",
              question_answer_en: "",
              id: "",
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="disease-form"
          onSubmit={handleSubmit(updateDisease)}
        >
          <div className="email-acordion-area">
            <div
              className={
                accordionVisible === "az"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "az" ? "" : "az")
                }
              >
                <h3 className="acordion-caption">Azerbaijan</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{
                  height: accordionVisible === "az" ? 404 : 0,
                }}
              >
                <div className="form-group">
                  <label htmlFor="question_title_az">Question</label>
                  <textarea
                    className={
                      errors.question_title_az
                        ? "inp error scroll"
                        : "inp scroll"
                    }
                    type="text"
                    name="question_title_az"
                    id="question_title_az"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("question_title_az")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="question_answer_az">Answer</label>
                  <textarea
                    className={
                      errors.question_answer_az ? "scroll error" : "scroll"
                    }
                    name="question_answer_az"
                    id="question_answer_az"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("question_answer_az")}
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              className={
                accordionVisible === "en"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "en" ? "" : "en")
                }
              >
                <h3 className="acordion-caption">English</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{
                  height: accordionVisible === "en" ? 404 : 0,
                }}
              >
                <div className="form-group">
                  <label htmlFor="question_title_en">Question</label>
                  <textarea
                    className={
                      errors.question_title_en
                        ? "inp error scroll"
                        : "inp scroll"
                    }
                    type="text"
                    name="question_title_en"
                    id="question_title_en"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("question_title_en")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="question_answer_en">Answer</label>
                  <textarea
                    className={
                      errors.question_answer_en ? "scroll error" : "scroll"
                    }
                    name="question_answer_en"
                    id="question_answer_en"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("question_answer_en")}
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              className={
                accordionVisible === "ru"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "ru" ? "" : "ru")
                }
              >
                <h3 className="acordion-caption">Russian</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{
                  height: accordionVisible === "ru" ? 404 : 0,
                }}
              >
                <div className="form-group">
                  <label htmlFor="question_title_ru">Question</label>
                  <textarea
                    className={
                      errors.question_title_ru
                        ? "inp error scroll"
                        : "inp scroll"
                    }
                    type="text"
                    name="question_title_ru"
                    id="question_title_ru"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("question_title_ru")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="question_answer_ru">Answer</label>
                  <textarea
                    className={
                      errors.question_answer_ru ? "scroll error" : "scroll"
                    }
                    name="question_answer_ru"
                    id="question_answer_ru"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("question_answer_ru")}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="form-footer">
            <button
              onClick={(e) => {
                if (errors.question_title_az || errors.question_answer_az) {
                  setAccordionVisible("az");
                } else if (
                  errors.question_title_ru ||
                  errors.question_answer_ru
                ) {
                  setAccordionVisible("en");
                } else if (
                  errors.question_title_en ||
                  errors.question_answer_en
                ) {
                  setAccordionVisible("ru");
                } else {
                  // handleSubmit(updateDisease);\
                  return null;
                }
              }}
            >
              Save
            </button>
            <p className="error-text"></p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FaqSideBar;
