import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { notify } from "../../utils/ToastIfyNonfoction";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { MainContext } from "../../utils/MainContext";
// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import arrowDown from "../../assets/images/icon/arrow-down.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const SocialMediaSideBar = ({ type }) => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    socialMediaData,
    setSocialMediaData,
    socialMediaSideBarVisible,
    setSocialMediaSideBarVisible,
    selectSocialMedia,
    setSelectSocialMedia,
  } = useContext(MainContext);

  const { logout } = useAuth();

  const socialIcons = [
    {
      id: 1,
      socialPlatform: "apple",
    },
    {
      id: 2,
      socialPlatform: "behance",
    },
    {
      id: 3,
      socialPlatform: "discord",
    },
    {
      id: 4,
      socialPlatform: "dripple",
    },
    {
      id: 5,
      socialPlatform: "fiqma",
    },
    {
      id: 6,
      socialPlatform: "github",
    },
    {
      id: 7,
      socialPlatform: "google",
    },
    {
      id: 8,
      socialPlatform: "facebook",
    },
    {
      id: 9,
      socialPlatform: "linkedin",
    },
    {
      id: 10,
      socialPlatform: "medium",
    },
    {
      id: 11,
      socialPlatform: "pinterest",
    },
    {
      id: 12,
      socialPlatform: "rabbit",
    },
    {
      id: 13,
      socialPlatform: "skype",
    },
    {
      id: 14,
      socialPlatform: "slack",
    },
    {
      id: 15,
      socialPlatform: "snapchat",
    },
    {
      id: 16,
      socialPlatform: "spotify",
    },
    {
      id: 17,
      socialPlatform: "telegram",
    },
    {
      id: 18,
      socialPlatform: "tiktok",
    },
    {
      id: 19,
      socialPlatform: "twitch",
    },
    {
      id: 20,
      socialPlatform: "twitter",
    },
    {
      id: 21,
      socialPlatform: "whatsapp",
    },
    {
      id: 22,
      socialPlatform: "youtube",
    },
    {
      id: 23,
      socialPlatform: "vkontakte",
    },
    {
      id: 24,
      socialPlatform: "ondoklassniki",
    },
    {
      id: 25,
      socialPlatform: "instagram",
    },
  ];

  //   Local State
  const [errorText, setErrorText] = useState("");
  const [dropDownVisible, setDropDownVisible] = useState(null);

  // Yup schema
  const attributeSchema = object({
    social_media_path: string().required().trim(),
    social_url: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(attributeSchema),
  });

  useEffect(() => {
    if (socialMediaSideBarVisible) {
      reset();
      if (selectSocialMedia && type === "edit") {
        setValue("social_media_path", selectSocialMedia.social_media_path);
        setValue("social_url", selectSocialMedia.social_url);
      }
    }
  }, [reset, selectSocialMedia, setValue, socialMediaSideBarVisible, type]);

  // Create && Edit
  const submit = async (data) => {
    const body = new FormData();

    body.append("social_url", data.social_url);
    body.append("social_media_path", data.social_media_path);

    if (type === "create") {
      try {
        const res = await axios.post(
          process.env.REACT_APP_SITE_SOCIAL_MEDIA_CREATE,
          body
        );
        setSocialMediaData([res.data, ...socialMediaData]);
        setSelectSocialMedia({
          social_media_path: "",
          social_url: "",
          id: "",
        });
        reset();
        notify("Social Media Create!");
        setSocialMediaSideBarVisible(false);
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              submit(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response.data.errors);
        }
      }
    } else {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SITE_SOCIAL_MEDIA_UPDATE}/${selectSocialMedia.id}`,
          body
        );
        setSocialMediaData(
          socialMediaData.map((item) =>
            item.id === res.data.id ? res.data : item
          )
        );

        setSelectSocialMedia({
          social_media_path: "",
          social_url: "",
          id: "",
        });
        notify("Social Media Update!");
        setSocialMediaSideBarVisible(false);
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              submit(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response.data.errors);
        }
      }
    }
  };

  return (
    <div
      className={
        socialMediaSideBarVisible
          ? " isOpenMenu social-media-sidebar-menu scroll"
          : "social-media-sidebar-menu   scroll"
      }
      onClick={() => dropDownVisible && setDropDownVisible(false)}
    >
      <div className="head">
        <h4 className="caption">Social Media</h4>
        <div
          className="icon"
          onClick={() => {
            setSocialMediaSideBarVisible(false);
            setSelectSocialMedia({
              social_media_path: "",
              social_url: "",
              id: "",
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="discount-form"
          onSubmit={handleSubmit(submit)}
        >
          <div
            className={`form-group ${
              dropDownVisible === "offer" ? "select open" : "select"
            } `}
          >
            <label htmlFor="social_media_path" className="inp-caption">
              Social Media Type
            </label>
            <input
              type="text"
              id="social_media_path"
              name="social_media_path"
              readOnly
              className={
                errors.social_media_path
                  ? "inp select-inp error"
                  : "inp select-inp"
              }
              placeholder=" Select Social Media Type"
              onClick={() =>
                setDropDownVisible(dropDownVisible === "offer" ? null : "offer")
              }
              {...register("social_media_path")}
            />
            <div className="icon">
              <img src={arrowDown} alt="open-close-dropdown" />
            </div>
            <div
              className="select-area"
              style={{ height: dropDownVisible === "offer" ? 120 : 0 }}
              onClick={(e) => e.stopPropagation()}
            >
              <ul className="select-list scroll">
                {socialIcons.map((item) => (
                  <li
                    className="select-item"
                    onClick={(e) => {
                      e.target.parentElement.parentElement.parentElement.children[1].classList.remove(
                        "error"
                      );
                      setValue("social_media_path", item.socialPlatform);
                      setDropDownVisible(false);
                    }}
                    key={item.id}
                  >
                    {item.socialPlatform}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="social_url" className="inp-caption">
              Url
            </label>
            <input
              type="text"
              className={errors.social_url ? "inp error" : "inp"}
              name="social_url"
              id="social_url"
              {...register("social_url", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>

          <div className="form-footer">
            <button>Save</button>
            <p className="error-text">{errorText}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SocialMediaSideBar;
