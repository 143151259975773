import { useAuth } from "../utils/AuthContext";
import { useNavigate } from "react-router-dom";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

import axios from "axios";
import { useState } from "react";

const Login = () => {
  // test
  const { login } = useAuth();
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");

  // Yup schema
  const createSchema = object({
    username: string().required().trim(),
    password: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createSchema),
  });

  const loginRequest = async (data) => {
    const body = new FormData();
    body.append("username", data.username);
    body.append("password", data.password);

    await axios
      .post(process.env.REACT_APP_SITE_LOGIN, body)
      .then((res) => {
        if (res) {
          login();
          navigate("/");
        }
      })
      .catch((err) => {
        if (err) {
          setErrorText("usename or password is incorrect");
        }
      });
  };
  return (
    <main>
      <section className="login">
        <div className="container">
          <div className="row">
            <form
              className="login-form"
              noValidate
              onSubmit={handleSubmit(loginRequest)}
            >
              <div className="form-group">
                <label htmlFor="username"> Username</label>
                <input
                  className={errors.username ? "inp error" : "inp"}
                  type="text"
                  name="username"
                  id="username"
                  autoComplete="off"
                  {...register("username", {
                    onChange: (e) => {
                      errorText !== "" && setErrorText("");
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password"> Password</label>
                <input
                  className={errors.password ? "inp error" : "inp"}
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="off"
                  {...register("password", {
                    onChange: (e) => {
                      errorText !== "" && setErrorText("");
                    },
                  })}
                />
                <div className="error-text">{errorText}</div>
              </div>
              <div className="login-footer">
                <button className="btn">Login</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Login;
