import React, { useContext } from "react";
import { MainContext } from "../utils/MainContext";
// Alert
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icon
import menuIcon from "../assets/images/icon/burger-menu.svg";
import MainPageComments from "./comment-section/MainPageComments";
import HotelComments from "./comment-section/HotelComments";
const Comment = () => {
  // Global State
  const {
    mainMenuVisible,
    setMainMenuVisible,
    commentSideBarVisible,
    setCommentSideBarVisible,
    setSelectComment,
    // commentData,
    // setCommentData,
    commentActiveTab,
    setCommentActiveTab,
  } = useContext(MainContext);

  // Local State && DataList
  const tabList = [
    { id: 1, tab: "Hotel reviews" },
    { id: 2, tab: "Hotel user reviews" },
  ];
  return (
    <main>
      <section className="hotel-detail">
        <div className="sub-header">
          <div className="container">
            <div className="row">
              <div className="info">
                <button    className=" burger-menu " onClick={() => setMainMenuVisible(true)}>
                  <img src={menuIcon} alt="menu" />
                </button>
                <h2 className="caption">Reviews</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="hotel-detail-header">
          <div className="container">
            <ul className="tab-list">
              {tabList.map((menuTab) => (
                <li
                  className={
                    commentActiveTab === menuTab.tab
                      ? "tab-item active"
                      : "tab-item"
                  }
                  onClick={() => setCommentActiveTab(menuTab.tab)}
                  key={menuTab.id}
                >
                  {menuTab.tab}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={
            mainMenuVisible || commentSideBarVisible
              ? "overlay-sub-menu active"
              : "overlay-sub-menu"
          }
          onClick={() => {
            if (mainMenuVisible) {
              setMainMenuVisible(false);
            } else if (commentSideBarVisible) {
              setCommentSideBarVisible(false);
              setSelectComment({
                id:"",
                review_reviewer_image:"",
                review_reviewer_name: "",
                review_star_rating: "",
                review_reviewer_origin: "",
                review_text: "",
                review_locale: "",
              });
            } else {
              return null;
            }
          }}
        ></div>
        <div className="hotel-detail-body">
          {commentActiveTab === "Hotel reviews" ? (
            <MainPageComments />
          ) : commentActiveTab === "Hotel user reviews" ? (
            <HotelComments />
          ) : null}
        </div>
        <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
      </section>
    </main>
  );
};

export default Comment;
