// React
import { useCallback, useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Axios
import axios from "axios";
import { useForm } from "react-hook-form";
import { createRemoveAlert } from "../../utils/SweetAlert";

// Icon
import arrowDown from "../../assets/images/icon/arrow-down.svg";
import add from "../../assets/images/icon/add-plus.svg";
import trash from "../../assets/images/icon/trash.svg";
import edit from "../../assets/images/icon/edit.svg";
import MinorSideBar from "../../components/side-bar-menu/MinorSideBar";
import AdditionSideBar from "../../components/side-bar-menu/AdditionSideBar";
import StandartSideBar from "../../components/side-bar-menu/StandartSideBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const HotelDEtailPriceList = ({ hotelId }) => {
  // Global State
  const {
    minorVisible,
    setMinorVisible,
    standartVisible,
    setStandartVisible,
    additionVisible,
    setAdditionVisible,
    hotelDetailActiveTab,
    mainMenuVisible,
    setMainMenuVisible,
    priceList,
    setPriceList,
  } = useContext(MainContext);

  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Local State
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [listData, setListData] = useState([]);
  const [roomId, setRoomId] = useState(null);
  const [roomType, setRoomType] = useState(true);
  const [pricePeriod, setPricePeriod] = useState(null);
  const [selectData, setSelectData] = useState(null);

  // Get Request
  const getAllRoom = useCallback(async () => {
    await axios
      .get(`${process.env.REACT_APP_SITE_ROOM_READ_ALL_BY_HOTEL_ID}${hotelId}`)
      .then((res) => setListData(res.data))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getAllRoom();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [hotelId, logout, navigate]);

  // React hook form
  const { register, setValue } = useForm();
  const getPriceListSchedule = useCallback(async () => {
    axios
      .get(`${process.env.REACT_APP_SITE_SCHEDULE_READ}${hotelId}`)
      .then((res) => setPricePeriod(res.data))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getPriceListSchedule();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [hotelId, logout, navigate]);

  useEffect(() => {
    if (hotelDetailActiveTab === "Price list") {
      setPriceList(null);
      getPriceListSchedule();
      getAllRoom();
    }
  }, [getAllRoom, getPriceListSchedule, hotelDetailActiveTab, setPriceList]);
  const getPrice = useCallback(
    async (id) => {
      setRoomType(true);
      await axios
        .get(`${process.env.REACT_APP_SITE_ROOM_PRICE}${id}`)
        .then((res) => {
          setPriceList(res.data);
          if (res.data.suite_pricing_type === "per-night") {
            setRoomType(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                getPrice(id);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setRoomType(true);
          }
        });
    },
    [logout, navigate, setPriceList]
  );

  // Delete Hotel Price List
  const removeData = (data, deleteType) => {
    // delete updateDataDeleteTemplate.prices;

    let body = {
      parameters: data,
    };
    createRemoveAlert(
      "Delete Hotel Price List!",
      `Are you sure you want to delete the ${deleteType} Price List?`,
      "Yes, Remove",

      async () => {
        if (deleteType === "standart") {
          await axios
            .delete(
              `${process.env.REACT_APP_SITE_HOTEL_SUIT_PRICE_STANDART_DELETE}/${roomId}`,
              {
                data: body,
              }
            )
            .then((res) => {
              setPriceList(res.data);
            })
            .catch((err) => {
              if (err.response.status === 401) {
                axios
                  .get(process.env.REACT_APP_REFRESH_TOKEN)
                  .then((res) => {
                    removeData(data, deleteType);
                  })
                  .catch((err) => navigate("/login"));
              } else if (err.response.status === 403) {
                logout();
                navigate("/login");
              }
            });
        } else if (deleteType === "minor") {
          await axios
            .delete(
              `${process.env.REACT_APP_SITE_HOTEL_SUIT_PRICE_MINOR_DELETE}/${roomId}`,
              {
                data: body,
              }
            )
            .then((res) => {
              setPriceList(res.data);
            })
            .catch((err) => {
              if (err.response.status === 401) {
                axios
                  .get(process.env.REACT_APP_REFRESH_TOKEN)
                  .then((res) => {
                    removeData(data, deleteType);
                  })
                  .catch((err) => navigate("/login"));
              } else if (err.response.status === 403) {
                logout();
                navigate("/login");
              }
            });
        } else {
          await axios
            .delete(
              `${process.env.REACT_APP_SITE_HOTEL_SUIT_PRICE_ADDITIONAL_DELETE}/${roomId}`,

              {
                data: body,
              }
            )
            .then((res) => {
              setPriceList(res.data);
            })
            .catch((err) => {
              if (err.response.status === 401) {
                axios
                  .get(process.env.REACT_APP_REFRESH_TOKEN)
                  .then((res) => {
                    removeData(data, deleteType);
                  })
                  .catch((err) => navigate("/login"));
              } else if (err.response.status === 403) {
                logout();
                navigate("/login");
              }
            });
        }
      }
    );
  };
  return (
    <div className="hotel-detail-price-list">
      <div className="container">
        <div className="select-room">
          <div
            className={`form-group ${
              dropDownVisible ? "select open" : "select"
            } `}
          >
            <label htmlFor="inp_value" className="inp-caption">
              Rooms
            </label>
            <input
              type="text"
              //   value={inpValue}
              name="inp_value"
              id="inp_value"
              className="inp select-inp"
              placeholder="Search or Select "
              readOnly
              // onChange={(e) => dataController(e.target.value)}
              onClick={() => setDropDownVisible(true)}
              autoComplete="off"
              {...register("inp_value")}
            />
            <div
              className="icon"
              onClick={(e) => {
                e.stopPropagation();
                setDropDownVisible(!dropDownVisible);
              }}
            >
              <img src={arrowDown} alt="open-close-dropdown" />
            </div>
            <div
              className="select-area"
              style={{
                height: dropDownVisible
                  ? listData.length > 3
                    ? 111.6
                    : listData.length * 50
                  : 0,
              }}
            >
              <ul className="select-list scroll">
                {listData.map((item) => (
                  <li
                    className="select-item"
                    onClick={() => {
                      setDropDownVisible(false);
                      setValue("inp_value", [
                        `${item.suite_associated_descriptions
                          .filter((name) => name.description_locale === "en")
                          .map((name) => name.description_suite_name)
                          .filter(Boolean)
                          .join(" || ")}${
                          item.suite_associated_descriptions.length !== 0
                            ? " "
                            : "Suite : " + item.id + " (Please Add Room Name)"
                        } `,
                      ]);
                      setRoomId(item.id);
                      getPrice(item.id);
                    }}
                    key={item.id}
                  >
                    {item.suite_associated_descriptions?.map(
                      (name) =>
                        name.description_locale === "en" &&
                        name.description_suite_name
                    )}
                    {item.suite_associated_descriptions.length === 0 &&
                      "Suite : " + item.id + " (Please Add Room Name)"}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {priceList && (
          <>
            <div className="price-list-tab">
              <div className="tab-head">
                <div className="tab-caption">Standart</div>
                <div className="tab-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="first-line"></th>
                        <th className="main-tab">Num. staying guests</th>
                        <th className="main-tab">Staying period</th>
                        {pricePeriod?.map((period) => (
                          <th className=" changable-tab" key={period.id}>
                            {period.fragment_start_month}-
                            {period.fragment_start_day}/{" "}
                            {period.fragment_end_month}-
                            {period.fragment_end_day}
                          </th>
                        ))}
                        {/* <th className=" changable-tab">01 02 2023 - 01 03 2023</th>
                    <th className=" changable-tab">01 02 2023 - 01 03 2023</th> */}

                        <th className="edit">Edit </th>
                      </tr>
                    </thead>
                    <tbody>
                      {priceList?.suite_associated_standard_prices.map(
                        (list) => (
                          <tr key={list.id}>
                            <td className="first-line"></td>
                            <td className="main-tab">
                              {list.price_num_staying_guests}
                            </td>
                            <td className="main-tab">
                              {list.price_staying_period}
                            </td>

                            {/* {list.prices.map((price) =>
                            pricePeriod.map((period) => (
                              <td className="changable-tab" key={period.id}>
                                {price.price_associated_schedule_fragment ===
                                  period.id ? price.price : "Add Price"}
                              </td>
                            ))
                          )} */}
                            {pricePeriod?.map((period) => {
                              const matchingPrice = list?.prices?.find(
                                (price) =>
                                  price.price_associated_schedule_fragment ===
                                  period.id
                              );
                              return (
                                <td
                                  className="changable-tab"
                                  key={period.id}
                                  id={period.id}
                                >
                                  {matchingPrice
                                    ? matchingPrice.price
                                    : "No Price"}
                                </td>
                              );
                            })}

                            <td className="edit">
                              <div className="btn-area">
                                <button
                                  onClick={() => {
                                    removeData(
                                      {
                                        price_num_staying_guests:
                                          list.price_num_staying_guests,
                                        price_staying_period:
                                          list.price_staying_period,
                                      },
                                      "standart"
                                    );
                                  }}
                                >
                                  <img src={trash} alt="trash" />
                                </button>
                                <button
                                  onClick={() => {
                                    setStandartVisible(true);

                                    setSelectData(list);
                                  }}
                                >
                                  <img src={edit} alt="edit" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <button
                  className="add-btn"
                  onClick={() => {
                    setStandartVisible(true);
                    setSelectData({
                      price_num_staying_guests: "",
                      price_staying_period: "",
                      prices: [],
                    });
                  }}
                >
                  Add line <img src={add} alt="add" />
                </button>
              </div>
            </div>
            {roomType && (
              <div className="price-list-tab">
                <div className="tab-head">
                  <div className="tab-caption">Minor</div>
                  <div className="tab-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="first-line"></th>
                          <th className="main-tab">Min. age</th>
                          <th className="main-tab">Max. age</th>
                          <th className="main-tab">Num. staying guests</th>
                          <th className="main-tab">Staying period</th>
                          {pricePeriod?.map((period) => (
                            <th className=" changable-tab" key={period.id}>
                              {period.fragment_start_month}-
                              {period.fragment_start_day}/{" "}
                              {period.fragment_end_month}-
                              {period.fragment_end_day}
                            </th>
                          ))}
                          {/* <th className=" changable-tab">
                          01 02 2023 - 01 03 2023
                        </th>
                        <th className=" changable-tab">
                          01 02 2023 - 01 03 2023
                        </th> */}

                          <th className="edit">Edit </th>
                        </tr>
                      </thead>
                      <tbody>
                        {priceList?.suite_associated_minor_prices.map(
                          (list) => (
                            <tr key={list.id}>
                              <td className="first-line"></td>
                              <td className="main-tab">
                                {list.price_age_start}
                              </td>
                              <td className="main-tab">{list.price_age_end}</td>
                              <td className="main-tab">
                                {list.price_num_staying_guests}
                              </td>
                              <td className="main-tab">
                                {list.price_staying_period}
                              </td>
                              {/* {list.prices.map((price) =>
                            pricePeriod.map((period) => (
                              <td className="changable-tab" key={period.id}>
                                {price.price_associated_schedule_fragment ===
                                  period.id ? price.price : "Add Price"}
                              </td>
                            ))
                          )} */}
                              {pricePeriod?.map((period) => {
                                const matchingPrice = list?.prices?.find(
                                  (price) =>
                                    price.price_associated_schedule_fragment ===
                                    period.id
                                );
                                return (
                                  <td
                                    className="changable-tab"
                                    key={period.id}
                                    id={period.id}
                                  >
                                    {matchingPrice
                                      ? matchingPrice.price
                                      : "No Price"}
                                  </td>
                                );
                              })}

                              <td className="edit">
                                <div className="btn-area">
                                  <button
                                    onClick={(e) =>
                                      removeData(
                                        {
                                          price_age_start: list.price_age_start,
                                          price_age_end: list.price_age_end,
                                          price_num_staying_guests:
                                            list.price_num_staying_guests,
                                          price_staying_period:
                                            list.price_staying_period,
                                        },
                                        "minor"
                                      )
                                    }
                                  >
                                    <img src={trash} alt="trash" />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setMinorVisible(true);

                                      setSelectData(list);
                                    }}
                                  >
                                    <img src={edit} alt="edit" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <button
                    className="add-btn"
                    onClick={() => {
                      setMinorVisible(true);

                      setSelectData({
                        price_age_start: "",
                        price_age_end: "",
                        price_num_staying_guests: "",
                        price_staying_period: "",
                        prices: [],
                      });
                    }}
                  >
                    Add line <img src={add} alt="add" />
                  </button>
                </div>
              </div>
            )}

            <div className="price-list-tab">
              <div className="tab-head">
                <div className="tab-caption">Additional</div>
                <div className="tab-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="first-line"></th>
                        <th className="main-tab">Min. age</th>
                        <th className="main-tab">Max. age</th>
                        <th className="main-tab">Num. staying guests</th>
                        <th className="main-tab">Staying period</th>
                        {pricePeriod?.map((period) => (
                          <th className=" changable-tab" key={period.id}>
                            {period.fragment_start_month}-
                            {period.fragment_start_day}/{" "}
                            {period.fragment_end_month}-
                            {period.fragment_end_day}
                          </th>
                        ))}
                        {/* <th className=" changable-tab">
                          01 02 2023 - 01 03 2023
                        </th>
                        <th className=" changable-tab">
                          01 02 2023 - 01 03 2023
                        </th> */}

                        <th className="edit">Edit </th>
                      </tr>
                    </thead>
                    <tbody>
                      {priceList?.suite_associated_additional_guest_prices.map(
                        (list) => (
                          <tr key={list.id}>
                            <td className="first-line"></td>
                            <td className="main-tab">{list.price_age_start}</td>
                            <td className="main-tab">{list.price_age_end}</td>
                            <td className="main-tab">
                              {list.price_num_staying_guests}
                            </td>
                            <td className="main-tab">
                              {list.price_staying_period}
                            </td>
                            {/* {list.prices.map((price) =>
                            pricePeriod.map((period) => (
                              <td className="changable-tab" key={period.id}>
                                {price.price_associated_schedule_fragment ===
                                  period.id ? price.price : "Add Price"}
                              </td>
                            ))
                          )} */}
                            {pricePeriod?.map((period) => {
                              const matchingPrice = list?.prices?.find(
                                (price) =>
                                  price.price_associated_schedule_fragment ===
                                  period.id
                              );
                              return (
                                <td
                                  className="changable-tab"
                                  key={period.id}
                                  id={period.id}
                                >
                                  {matchingPrice
                                    ? matchingPrice.price
                                    : "No Price"}
                                </td>
                              );
                            })}

                            <td className="edit">
                              <div className="btn-area">
                                <button
                                  onClick={(e) =>
                                    removeData(
                                      {
                                        price_age_start: list.price_age_start,
                                        price_age_end: list.price_age_end,
                                        price_num_staying_guests:
                                          list.price_num_staying_guests,
                                        price_staying_period:
                                          list.price_staying_period,
                                      },
                                      "addition"
                                    )
                                  }
                                >
                                  <img src={trash} alt="trash" />
                                </button>
                                <button
                                  onClick={() => {
                                    setAdditionVisible(true);

                                    setSelectData(list);
                                  }}
                                >
                                  <img src={edit} alt="edit" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <button
                  className="add-btn"
                  onClick={() => {
                    setAdditionVisible(true);

                    setSelectData({
                      price_age_start: "",
                      price_age_end: "",
                      price_num_staying_guests: "",
                      price_staying_period: "",
                      prices: [],
                    });
                  }}
                >
                  Add line <img src={add} alt="add" />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <StandartSideBar
        pricePeriod={pricePeriod}
        selectData={selectData}
        suitId={roomId}
      />
      <MinorSideBar
        pricePeriod={pricePeriod}
        selectData={selectData}
        suitId={roomId}
      />
      <AdditionSideBar
        pricePeriod={pricePeriod}
        selectData={selectData}
        suitId={roomId}
      />
      <div
        className={
          standartVisible || mainMenuVisible || minorVisible || additionVisible
            ? "overlay-sub-menu active"
            : "overlay-sub-menu"
        }
        onClick={() => {
          if (mainMenuVisible) {
            setMainMenuVisible(false);
          } else if (standartVisible) {
            setStandartVisible(false);
          } else if (minorVisible) {
            setMinorVisible(false);
          } else if (additionVisible) {
            setAdditionVisible(false);
          }
        }}
      ></div>
    </div>
  );
};

export default HotelDEtailPriceList;
