// React
import { useCallback, useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Components

// Icon
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useAuth } from "../../utils/AuthContext";
const RoomAtribute = () => {
  // Global State
  const {
    // Visible State
    mainMenuVisible,
    setMainMenuVisible,

    // hotelDetailActiveTab,
    selectRoomID,
    roomData,
    setRoomData,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [groupList, setGroupList] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  // Navigate
  const navigate = useNavigate();

  // Get Request For Hotel Attribute
  const gelAllAttributeGroup = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SITE_SUIT_ATTRIBUTE_GROUP_READ_BY_ID}/${selectRoomID}`
      )
      .then((res) => {
        setGroupList(res.data);
        if (roomData && roomData.suite_associated_attributes) {
          setSelectedAttributes(roomData.suite_associated_attributes);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && gelAllAttributeGroup())
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [logout, navigate, roomData, selectRoomID]);
  useEffect(() => {
    if (selectRoomID) {
      gelAllAttributeGroup();
    }
  }, [gelAllAttributeGroup, selectRoomID]);

  // Add Attribute
  const handleCheckboxChange = (attribute) => {
    setSelectedAttributes((prevSelected) => {
      if (prevSelected.some((attr) => attr.id === attribute.id)) {
        // Attribute is already selected, remove it
        return prevSelected.filter((attr) => attr.id !== attribute.id);
      } else {
        // Attribute is not selected, add it
        return [...prevSelected, attribute];
      }
    });
  };

  const uptadeAttribute = async (data) => {
    // const existCoupon = roomData.suite_associated_attributes.find(
    //   (item) => item.id === newCoupon.id
    // );
    // console.log(existCoupon);

    // if (!existCoupon) {
    const formData = new FormData();
    const keys = Object.keys(roomData);
    keys.forEach((key) => {
      formData.append(key, roomData[key]);
    });
    formData.set(
      "suite_associated_main_image",
      roomData.suite_associated_main_image
        ? roomData.suite_associated_main_image.id
        : ""
    );
    formData.set(
      "suite_associated_images",
      roomData.suite_associated_images
        ? roomData.suite_associated_images.map((item) => item.id).join(",")
        : ""
    );

    formData.set(
      "suite_associated_special_offer_percentages",
      roomData.suite_associated_special_offer_percentages
        ? roomData.suite_associated_special_offer_percentages
            .map((item) => item.id)
            .join(",")
        : ""
    );
    formData.set(
      "suite_associated_special_offer_free_nights",
      roomData.suite_associated_special_offer_free_nights
        ? roomData.suite_associated_special_offer_free_nights
            .map((item) => item.id)
            .join(",")
        : ""
    );

    formData.set(
      "suite_associated_special_offer_special_price",
      roomData.suite_associated_special_offer_special_price
        ? roomData.suite_associated_special_offer_special_price.id
        : ""
    );

    formData.set(
      "suite_associated_attributes",
      selectedAttributes.length !== 0
        ? selectedAttributes.map((item) => item.id).join(",")
        : ""
    );
    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }

    await axios
      .put(`${process.env.REACT_APP_SITE_ROOM_UPTADE}${selectRoomID}`, formData)
      .then((res) => {
        setRoomData({
          ...roomData,
          suite_associated_attributes: res.data.suite_associated_attributes,
        });
        notify("Room Attribute Update!");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              uptadeAttribute();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  };

  return (
    roomData.suite_associated_attributes && (
      <div className="artibute-area boxes-style">
        <div className="atribute-body-selected">
          <h2 className="attribute-cation">Attributes</h2>
          <div className="attribute-select-area">
            {groupList.length !== 0 &&
              groupList.map((group) => (
                <div className="group" key={group.id}>
                  <div className="group-caption">
                    {group.attribute_group_name_en}
                  </div>
                  <div className="select-area">
                    {group.group_associated_attributes.map((attribute) => (
                      <div className="select-group" key={attribute.id}>
                        <input
                          type="checkbox"
                          name={attribute.id}
                          id={attribute.id}
                          checked={selectedAttributes.some(
                            (attr) => attr.id === attribute.id
                          )}
                          onChange={() => handleCheckboxChange(attribute)}
                        />
                        <label htmlFor={attribute.id}>
                          {" "}
                          {attribute.attribute_name_en}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          <div className="form-footer-attribute">
            <div className="handle-area">
              <p className="error-text"></p>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  uptadeAttribute();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div
          className={
            mainMenuVisible ? "overlay-sub-menu active" : "overlay-sub-menu"
          }
          onClick={() => {
            mainMenuVisible && setMainMenuVisible(false);
          }}
        ></div>
      </div>
    )
  );
};
export default RoomAtribute;
