// React
import { useCallback, useEffect } from "react";

// Axios
import axios from "axios";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Toastie
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const SettingSection = () => {
  // Global state
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Yup schema
  const settingSchema = object({
    site_address: string().required().trim(),
    site_city: string().required().trim(),
    site_zip: string().required().trim(),
    site_country: string().required().trim(),
    site_email: string().required().trim(),
    site_phone: string().required().trim(),
    site_phone_international: string().required().trim(),
    site_fax: string().required().trim(),
    site_phone_additional: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(settingSchema),
  });

  //Setting Form Submit
  const onSubmit = async (data) => {
    const body = new FormData();
    body.append("site_email", data.site_email);
    body.append("site_phone", data.site_phone);
    body.append("site_phone_international", data.site_phone_international);
    body.append("site_fax", data.site_fax);
    body.append("site_address", data.site_address);
    body.append("site_city", data.site_city);
    body.append("site_zip", data.site_zip);
    body.append("site_country", data.site_country);
    body.append("site_phone_additional", data.site_phone_additional);

    await axios
      .put(process.env.REACT_APP_SETTING_UPDATE, body)
      .then((res) => {
        if (res.status === 200) {
          notify("Data Update!");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              onSubmit(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  };

  // Get Setting Data
  const getSettingData = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_SETTING_READ)
      .then((res) => {
        setValue("site_email", res.data.site_email);
        setValue("site_phone", res.data.site_phone);
        setValue("site_phone_international", res.data.site_phone_international);
        setValue("site_fax", res.data.site_fax);
        setValue("site_address", res.data.site_address);
        setValue("site_city", res.data.site_city);
        setValue("site_zip", res.data.site_zip);
        setValue("site_country", res.data.site_country);
        setValue("site_phone_additional", res.data.site_phone_additional);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getSettingData();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [logout, navigate, setValue]);

  // Rendering Data
  useEffect(() => {
    getSettingData();
  }, [getSettingData]);

  return (
    <form
      action=""
      className="main-input-area boxes-style"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="main-caption">Agency</h2>
      <div className="inp-area">
        <div className="form-group">
          <label htmlFor="site_address">Address</label>
          <input
            type="text"
            className={errors.site_address ? "inp error" : "inp"}
            id="site_address"
            name="site_address"
            {...register("site_address")}
          />
        </div>

        <div className="form-group">
          <label htmlFor="site_city">City</label>
          <input
            type="text"
            className={errors.site_city ? "inp error" : "inp"}
            id="site_city"
            name="site_city"
            {...register("site_city")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="site_zip">ZIP code</label>
          <input
            type="text"
            className={errors.site_zip ? "inp error" : "inp"}
            id="site_zip"
            name="site_zip"
            {...register("site_zip")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="site_country">Country</label>
          <input
            type="text"
            className={errors.site_country ? "inp error" : "inp"}
            id="site_country"
            name="site_country"
            {...register("site_country")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="site_email">Email</label>
          <input
            type="email"
            className={errors.site_email ? "inp error" : "inp"}
            id="site_email"
            name="site_email"
            {...register("site_email")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="site_phone">Telephone</label>
          <input
            type="tel"
            className={errors.site_phone ? "inp error" : "inp"}
            id="site_phone"
            name="site_phone"
            placeholder="+994555555555"
            {...register("site_phone")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="site_phone_international">
            Telephone International
          </label>
          <input
            type="tel"
            className={errors.site_phone_international ? "inp error" : "inp"}
            id="site_phone_international"
            name="site_phone_international"
            placeholder="+994555555555"
            {...register("site_phone_international")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="site_phone_additional">Telephone (Additional)</label>
          <input
            type="tel"
            className={errors.site_phone_additional ? "inp error" : "inp"}
            id="site_phone_additional"
            name="site_phone_additional"
            placeholder="+994555555555"
            {...register("site_phone_additional")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="site_fax">Fax</label>
          <input
            type="text"
            className={errors.site_fax ? "inp error" : "inp"}
            id="site_fax"
            name="site_fax"
            {...register("site_fax")}
          />
        </div>
      </div>
      <div className="form-footer">
        <p className="error-text"></p>
        <button>Save</button>
      </div>
    </form>
  );
};

export default SettingSection;
