// React
import { useContext } from "react";

// Params
import { useNavigate, useParams } from "react-router-dom";

// Context
import { MainContext } from "../../utils/MainContext";

// Components
import HotelCouponSideBar from "../../components/side-bar-menu/HotelCouponSideBar";

// Axios
import axios from "axios";

// Sweet Alert
import { createRemoveAlert } from "../../utils/SweetAlert";

// Icon
import trash from "../../assets/images/icon/trash.svg";
import add from "../../assets/images/icon/add-plus.svg";
import { useAuth } from "../../utils/AuthContext";

const HotelCoupon = () => {
  // Params
  const { hotelId } = useParams();

  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    // Visible States
    mainMenuVisible,
    setMainMenuVisible,
    hotelCouponSideBarVisible,
    setHotelCouponSideBarVisible,

    // Data
    hotelData,
    setHotelData,

    // hotelDetailActiveTab,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Delete Hotel
  const removeData = (dataId, coupon_name) => {
    createRemoveAlert(
      "Delete Coupon!",
      `Are you sure you want to delete the ${coupon_name} Coupon?`,
      "Yes, Remove",
      async () => {
        const updateCouponData = hotelData.hotel_associated_coupons.filter(
          (item) => item.id !== dataId
        );

        const formData = new FormData();
        let UpdatedData = hotelData;

        const keys = Object.keys(UpdatedData);

        keys.forEach((key) => {
          formData.append(key, UpdatedData[key]);
        });

        formData.set(
          "hotel_associated_images",
          hotelData.hotel_associated_images
            ? hotelData.hotel_associated_images.map((item) => item.id).join(",")
            : ""
        );
        formData.set(
          "hotel_associated_main_image",
          hotelData.hotel_associated_main_image
            ? hotelData.hotel_associated_main_image.id
            : ""
        );
        formData.set(
          "hotel_associated_attributes",
          hotelData.hotel_associated_attributes
            ? hotelData.hotel_associated_attributes
                .map((item) => item.id)
                .join(",")
            : ""
        );

        formData.set(
          "hotel_associated_coupons",
          updateCouponData.length !== 0
            ? updateCouponData.map((item) => item.id).join(",")
            : ""
        );
        // for (const [key, value] of formData.entries()) {
        //   console.log(key, value);
        // }

        await axios
          .put(`${process.env.REACT_APP_SITE_HOTEL_UPTADE}${hotelId}`, formData)
          .then((res) => {
            setHotelData({
              ...hotelData,
              hotel_associated_coupons: updateCouponData,
            });
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId, coupon_name);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };

  return (
    <div className="coupon-area boxes-style">
      <div className="coupon-head">
        <div className="add-area">
          <h4 className="add-caption">Add Coupon</h4>{" "}
          <button
            className="add-btn"
            onClick={() => setHotelCouponSideBarVisible(true)}
          >
            Add <img src={add} alt="add" />
          </button>
        </div>
      </div>
      <div className="coupon-body">
        <table className="table">
          <thead>
            <tr>
              <th className="first">Coupon Name</th>
              <th className="first">Coupon Code</th>
              <th className="edit">Delete </th>
            </tr>
          </thead>
          <tbody>
            {hotelData.hotel_associated_coupons.map((coupon) => (
              <tr key={coupon.id}>
                <td className="first">{coupon.coupon_name}</td>
                <td className="first">{coupon.coupon_code}</td>
                <td className="edit">
                  <div className="btn-area">
                    <button
                      onClick={() => removeData(coupon.id, coupon.coupon_name)}
                    >
                      <img src={trash} alt="delete" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <HotelCouponSideBar />
      <div
        className={
          hotelCouponSideBarVisible || mainMenuVisible
            ? "overlay-sub-menu active"
            : "overlay-sub-menu"
        }
        onClick={() => {
          mainMenuVisible
            ? setMainMenuVisible(false)
            : setHotelCouponSideBarVisible(false);
        }}
      ></div>
    </div>
  );
};

export default HotelCoupon;
