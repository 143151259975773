// React
import { useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const ExchangeRateSideBarMenu = ({ selectData }) => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const { exchangeRateVisible, setExchangeRateVisible, setExchangeRateData } =
    useContext(MainContext);
  const { logout } = useAuth();
  // Local State
  const [errorText, setErrorText] = useState("");

  // Yup schema
  const schema = object({
    currency_price: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setErrorText("");
    if (selectData) {
      setValue("currency_price", selectData?.currency_price);
    }
  }, [exchangeRateVisible, selectData, selectData.currency_price, setValue]);

  const submit = async (data) => {
    let newData = data.currency_price;
    const body = new FormData();
    body.append("currency_name", selectData.currency_name);
    body.append("currency_code", selectData.currency_code);
    body.append("currency_price", newData);

    axios
      .put(
        `${process.env.REACT_APP_SITE_EXCHANGE_RATE_UPDATE}${selectData.id}`,
        body
      )
      .then((res) => {
        setExchangeRateData((prevData) => {
          return prevData.map((item) => {
            if (item.id === selectData.id) {
              return { ...item, currency_price: data.currency_price };
            }
            return item;
          });
        });
        notify("Exchange Rate Update!");

        setExchangeRateVisible(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              submit(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      });
  };

  return (
    <div
      className={
        exchangeRateVisible
          ? " isOpenMenu exchange-rate-sidebar-menu scroll"
          : "exchange-rate-sidebar-menu  scroll"
      }
    >
      <div className="head">
        <h4 className="caption">Exchange rate</h4>
        <div className="icon" onClick={() => setExchangeRateVisible(false)}>
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <h4 className="form-caption">
          {selectData && selectData.currency_code}
        </h4>
        <form
          action=""
          className="exchange-rate-form"
          onSubmit={handleSubmit(submit)}
        >
          <div className="form-group">
            <label htmlFor="currency_price" className="inp-caption">
              Price
            </label>
            <input
              type="number"
              name="currency_price"
              id="currency_price"
              className={errors.currency_price ? "inp error" : "inp"}
              {...register("currency_price", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>

          <div className="form-footer">
            <p className="error-text">{errorText}</p>
            <div className="btn-area">
              <button>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExchangeRateSideBarMenu;
