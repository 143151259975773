// React
import { useCallback, useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import arrowDown from "../../assets/images/icon/arrow-down.svg";
import deleteIcon from "../../assets/images/icon/delete.svg";
// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Axois
import axios from "axios";

// Alert
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const CustomPageCreateSideBar = ({ type }) => {
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    setCustomPageAllListData,
    customPageAllListSideBarVisible,
    setCustomPageAllListSideBarVisible,
    selectCustomPage,
    setSelectCustomPage,
  } = useContext(MainContext);

  // Local State
  const [errorText, setErrorText] = useState("");
  const [dropDownVisible, setDropDownVisible] = useState("");
  const location = [
    { id: 1, character: "home" },
    { id: 2, character: "naftalan-history" },
    { id: 3, character: "naftalan-disease" },
    { id: 4, character: "faq" },
    { id: 5, character: "contact" },
    { id: 6, character: "footer" },
  ];
  const [hotelListData, setHotelListData] = useState([]);
  const [newData, setNewData] = useState([]);

  const deleteCharacter = (id) => {
    const updatedData = newData.filter((item) => item.id !== id);
    setNewData(updatedData);
  };

  const handleCharacterAddData = (data) => {
    const keyExists = newData.some((item) => {
      return item.id === data.id;
    });

    if (!keyExists) {
      setNewData([...newData, data]);
    }
  };

  const gelAllHotel = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SITE_HOTEL_READ_ALL}?page_length=8&page_number=1`
      )
      .then((res) => {
        setHotelListData(res.data.page_data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && gelAllHotel())
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [logout, navigate]);

  const controller = async (value) => {
    if (value.length > 1) {
      await axios
        .get(`${process.env.REACT_APP_SITE_HOTEL_SEARCH}/${value}`)
        .then((res) => {
          setHotelListData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => res && controller(value))
              .catch((err) => err && navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    } else {
      gelAllHotel();
    }
  };

  // Yup schema
  const createSchema = object({
    location: string().nullable().optional(),
    page_name_ru: string().required().trim(),
    page_name_en: string().required().trim(),
    page_name_az: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(createSchema),
  });

  useEffect(() => {
    setErrorText("");
    gelAllHotel();
    if (customPageAllListSideBarVisible && type === "edit") {
      setValue("location", selectCustomPage.page_associated_section);
      setValue("page_name_ru", selectCustomPage.page_name_ru);
      setValue("page_name_en", selectCustomPage.page_name_en);
      setValue("page_name_az", selectCustomPage.page_name_az);
      setNewData(selectCustomPage.page_associated_hotels);
    } else {
      reset();
      setNewData([]);
    }
  }, [
    customPageAllListSideBarVisible,
    gelAllHotel,
    reset,
    selectCustomPage,
    selectCustomPage.page_associated_hotels,
    selectCustomPage.page_associated_section,
    selectCustomPage.page_associated_seos,
    selectCustomPage.page_name_az,
    selectCustomPage.page_name_en,
    selectCustomPage.page_name_ru,
    setValue,
    type,
  ]);

  //   Create Folder
  const createCustomPage = async (data) => {
    const body = new FormData();
    body.append("page_associated_section", data.location);
    body.append("page_name_ru", data.page_name_ru);
    body.append("page_name_en", data.page_name_en);
    body.append("page_name_az", data.page_name_az);
    body.append(
      "page_associated_hotels",
      newData.length !== 0 ? newData.map((item) => item.id).join(",") : ""
    );
    if (type === "create") {
      await axios
        .post(process.env.REACT_APP_SITE_CUSTOM_PAGE_CREATE, body)
        .then((res) => {
          setCustomPageAllListData((prevState) => {
            const updatedData = {
              ...res.data,
              page_associated_hotels: newData,
            };

            return {
              ...prevState,
              page_data: [updatedData, ...prevState.page_data],
            };
          });
          notify("Custom Page Create!");
          setSelectCustomPage({
            page_associated_section: "",
            page_name_az: "",
            page_name_en: "",
            page_name_ru: "",
            page_associated_hotels: [],
          });
          setCustomPageAllListSideBarVisible(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                createCustomPage(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    } else {
      await axios
        .put(
          `${process.env.REACT_APP_SITE_CUSTOM_PAGE_UPDATE}/${selectCustomPage.id}`,
          body
        )
        .then((res) => {
          setCustomPageAllListData((prevState) => ({
            ...prevState,
            page_data: prevState.page_data.map((item) =>
              item.id === res.data.id
                ? { ...res.data, page_associated_hotels: newData }
                : item
            ),
          }));
          setSelectCustomPage({
            page_associated_section: "",
            page_name_az: "",
            page_name_en: "",
            page_name_ru: "",
            page_associated_hotels: [],
          });
          notify("Custom Page Data Update!");
          setCustomPageAllListSideBarVisible(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                createCustomPage(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    }
  };
  return (
    <div
      className={
        customPageAllListSideBarVisible
          ? " isOpenMenu file-manager-folder-sidebar-menu scroll"
          : "file-manager-folder-sidebar-menu scroll"
      }
      onClick={() => dropDownVisible && setDropDownVisible("")}
    >
      <div className="head">
        <h4 className="caption">Custom Page Create</h4>
        <div
          className="icon"
          onClick={() => {
            setCustomPageAllListSideBarVisible(false);
            setSelectCustomPage({
              page_associated_section: "",
              page_name_az: "",
              page_name_en: "",
              page_name_ru: "",
              page_associated_hotels: [],
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="folder-form edit-img custom-page-create"
          noValidate
          onSubmit={handleSubmit(createCustomPage)}
        >
          <div
            className={`form-group ${
              dropDownVisible === "type" ? "select open" : "select"
            } `}
          >
            <label htmlFor="location" className="inp-caption">
              Location
            </label>
            <input
              type="text"
              id="location"
              name="location"
              // value={inpValue}
              readOnly
              className={
                errors.location ? "inp select-inp error" : "inp select-inp"
              }
              placeholder="Select  Location"
              onClick={() =>
                setDropDownVisible(dropDownVisible === "type" ? "" : "type")
              }
              {...register("location", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
            <div className="icon">
              <img src={arrowDown} alt="open-close-dropdown" />
            </div>
            <div
              className="select-area"
              style={{ height: dropDownVisible === "type" ? 120 : 0 }}
              onClick={(e) => e.stopPropagation()}
            >
              <ul className="select-list scroll">
                {location.map((item) => (
                  <li
                    className="select-item"
                    onClick={(e) => {
                      e.target.parentElement.parentElement.parentElement.children[1].classList.remove(
                        "error"
                      );
                      setValue("location", item.character);
                      setDropDownVisible("");
                    }}
                    key={item.id}
                  >
                    {item.character}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="page_name_az" className="inp-caption">
              Page Name Az
            </label>
            <input
              type="text"
              className={errors.page_name_az ? "inp error" : "inp"}
              name="page_name_az"
              id="page_name_az"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("page_name_az")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="page_name_en" className="inp-caption">
              Page Name En
            </label>
            <input
              type="text"
              className={errors.page_name_en ? "inp error" : "inp"}
              name="page_name_en"
              id="page_name_en"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("page_name_en")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="page_name_ru" className="inp-caption">
              Page Name Ru
            </label>
            <input
              type="text"
              className={errors.page_name_ru ? "inp error" : "inp"}
              name="page_name_ru"
              id="page_name_ru"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("page_name_ru")}
            />
          </div>
          <div className="side-bar-divader"></div>
          <div
            className={`form-group ${
              dropDownVisible === "hotel" ? "select open" : "select"
            } `}
          >
            <label htmlFor="hotel_atribute" className="inp-caption">
              Add Hotel (optional)
            </label>
            <input
              type="text"
              className="inp select-inp"
              name="hotel_atribute"
              id="hotel_atribute"
              placeholder="Search and Select Hotel"
              onClick={() => setDropDownVisible("hotel")}
              onChange={(e) => controller(e.target.value)}
            />
            <div
              className="icon"
              onClick={(e) => {
                e.stopPropagation();
                setDropDownVisible(dropDownVisible === "hotel" && "");
              }}
            >
              <img src={arrowDown} alt="open-close-dropdown" />
            </div>
            <div
              className="select-area"
              style={{
                height: dropDownVisible
                  ? hotelListData.length > 3
                    ? 111.6
                    : hotelListData.length <= 3 && hotelListData.length > 0
                    ? hotelListData.length * 45
                    : 50
                  : 0,
              }}
            >
              <ul className="select-list scroll">
                {hotelListData.length !== 0 ? (
                  hotelListData.map((item) => (
                    <li
                      className="select-item"
                      onClick={() => {
                        setDropDownVisible("");
                        handleCharacterAddData(item);
                      }}
                      key={item.id}
                    >
                      {item.hotel_name}
                    </li>
                  ))
                ) : (
                  <li className="select-item"> Not Found Attribute</li>
                )}
              </ul>
            </div>
          </div>
          <div className="clarifyingArea scroll">
            {newData.map((item) => (
              <div className="clarifying-btn" key={item.id}>
                <span className="text">{item.hotel_name}</span>
                <div
                  className="delete"
                  onClick={() => deleteCharacter(item.id)}
                >
                  <img src={deleteIcon} alt="delete" />
                </div>
              </div>
            ))}
          </div>
          <div className="form-footer">
            <button>{type === "create" ? "Create Custom Page" : "Save"}</button>
            <p className="error-text"></p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomPageCreateSideBar;
