// React
import { useContext } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Components

// Icon
import trash from "../../assets/images/icon/trash.svg";
import add from "../../assets/images/icon/add-plus.svg";
import { createRemoveAlert } from "../../utils/SweetAlert";
import axios from "axios";
import OfferPersentageSideBar from "../../components/side-bar-menu/OfferPersentageSideBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const RoomPersentageOffer = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    // Visible State
    mainMenuVisible,
    setMainMenuVisible,
    offerPersentageVisible,
    setOfferPersentageVisible,

    // hotelDetailActiveTab,
    selectRoomID,
    roomData,
    setRoomData,
  } = useContext(MainContext);
  const { logout } = useAuth();


  // Delete Hotel
  const removeData = (dataId, offer) => {
    createRemoveAlert(
      "Delete Percentage Offer!",
      `Are you sure you want to delete the ${offer} Percentage Offer?`,
      "Yes, Remove",
      async () => {
        const updateOffer =
          roomData.suite_associated_special_offer_percentages.filter(
            (item) => item.id !== dataId
          );

        const formData = new FormData();
        let UpdatedData = roomData;

        const keys = Object.keys(UpdatedData);

        keys.forEach((key) => {
          formData.append(key, UpdatedData[key]);
        });

        formData.set(
          "suite_associated_main_image",
          roomData.suite_associated_main_image
            ? roomData.suite_associated_main_image.id
            : ""
        );
        formData.set(
          "suite_associated_images",
          roomData.suite_associated_images
            ? roomData.suite_associated_images.map((item) => item.id).join(",")
            : ""
        );

        formData.set(
          "suite_associated_special_offer_free_nights",
          roomData.suite_associated_special_offer_free_nights
            ? roomData.suite_associated_special_offer_free_nights
                .map((item) => item.id)
                .join(",")
            : ""
        );

        formData.set(
          "suite_associated_special_offer_special_price",
          roomData.suite_associated_special_offer_special_price
            ? roomData.suite_associated_special_offer_special_price.id
            : ""
        );

        formData.set(
          "suite_associated_attributes",
          roomData.suite_associated_attributes
            ? roomData.suite_associated_attributes
                .map((item) => item.id)
                .join(",")
            : ""
        );
        formData.set(
          "suite_associated_special_offer_percentages",
          updateOffer.length !== 0
            ? updateOffer.map((item) => item.id).join(",")
            : ""
        );
        // for (const [key, value] of formData.entries()) {
        //   console.log(key, value);
        // }

        await axios
          .put(
            `${process.env.REACT_APP_SITE_ROOM_UPTADE}${selectRoomID}`,
            formData
          )
          .then((res) => {
            setRoomData({
              ...roomData,
              suite_associated_special_offer_percentages: updateOffer,
            });
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId, offer);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };
  return (
    roomData.suite_associated_special_offer_percentages && (
      <div className="artibute-area boxes-style">
        <div className="atribute-head">
          <div className="add-area">
            <h4 className="add-caption">Add Percentage Offer</h4>{" "}
            <button
              className="add-btn"
              onClick={() => setOfferPersentageVisible(true)}
            >
              Add <img src={add} alt="add" />
            </button>
          </div>
        </div>
        <div className="atribute-body">
          <table className="table">
            <thead>
              <tr>
                <th className="first">id</th>
                <th className="first">Offer Name</th>
                <th className="first">Period </th>
                <th className="edit"> Delete </th>
              </tr>
            </thead>
            <tbody>
              {roomData.suite_associated_special_offer_percentages.map(
                (offer) => (
                  <tr key={offer.id}>
                    <td className="first">{offer.id}</td>
                    <td className="first">{offer.offer_name}</td>
                    <td className="first">
                      {offer.offer_start}/{offer.offer_end}
                    </td>
                    <td className="edit">
                      <div className="btn-area">
                        <button
                          onClick={() => removeData(offer.id, offer.offer_name)}
                        >
                          <img src={trash} alt="delete" />
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <OfferPersentageSideBar />
        <div
          className={
            offerPersentageVisible || mainMenuVisible
              ? "overlay-sub-menu active"
              : "overlay-sub-menu"
          }
          onClick={() => {
            mainMenuVisible
              ? setMainMenuVisible(false)
              : setOfferPersentageVisible(false);
          }}
        ></div>
      </div>
    )
  );
};

export default RoomPersentageOffer;
