// React
import { useCallback, useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Icon
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useAuth } from "../../utils/AuthContext";
const HotelAtribute = ({ hotelId }) => {
  // Global State
  const {
    // Visible State
    mainMenuVisible,
    setMainMenuVisible,

    // Data
    hotelData,
    setHotelData,

    hotelDetailActiveTab,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Local State
  const [groupList, setGroupList] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  // Get Request For Hotel Attribute
  const gelAllAttributeGroup = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SITE_HOTEL_ATTRIBUTE_GROUP_READ_BY_ID}/${hotelId}`
      )
      .then((res) => {
        setGroupList(res.data);
        setSelectedAttributes(hotelData?.hotel_associated_attributes);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && gelAllAttributeGroup())
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [hotelData?.hotel_associated_attributes, hotelId, logout, navigate]);

  // Render Data
  useEffect(() => {
    if (hotelDetailActiveTab === "Description" && hotelId) {
      gelAllAttributeGroup();
    }
  }, [gelAllAttributeGroup, hotelDetailActiveTab, hotelId]);

  // Add Attribute
  const handleCheckboxChange = (attribute) => {
    setSelectedAttributes((prevSelected) => {
      if (prevSelected.some((attr) => attr.id === attribute.id)) {
        // Attribute is already selected, remove it
        return prevSelected.filter((attr) => attr.id !== attribute.id);
      } else {
        // Attribute is not selected, add it
        return [...prevSelected, attribute];
      }
    });
  };

  const updateAttribute = async () => {
    const formData = new FormData();
    let UpdatedData = hotelData;

    const keys = Object.keys(UpdatedData);
    keys.forEach((key) => {
      formData.append(key, UpdatedData[key]);
    });
    formData.set(
      "hotel_associated_images",
      hotelData.hotel_associated_images
        ? hotelData.hotel_associated_images.map((item) => item.id).join(",")
        : ""
    );
    formData.set(
      "hotel_associated_main_image",
      hotelData.hotel_associated_main_image
        ? hotelData.hotel_associated_main_image.id
        : ""
    );
    formData.set(
      "hotel_associated_attributes",

      selectedAttributes.length !== 0
        ? selectedAttributes.map((item) => item.id).join(",")
        : ""
    );
    formData.set(
      "hotel_associated_coupons",
      hotelData.hotel_associated_coupons
        ? hotelData.hotel_associated_coupons.map((item) => item.id).join(",")
        : ""
    );

    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }

    await axios
      .put(`${process.env.REACT_APP_SITE_HOTEL_UPTADE}${hotelId}`, formData)
      .then((res) => {
        notify("Hotel Attributes Update!");
        setHotelData({
          ...hotelData,
          hotel_associated_attributes: selectedAttributes,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && updateAttribute())
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  };

  return (
    <div className="artibute-area boxes-style">
      <div className="atribute-body">
        <h2 className="attribute-cation">Attributes</h2>
        <div className="attribute-select-area">
          {groupList.length !== 0 &&
            groupList.map((group) => (
              <div className="group" key={group.id}>
                <div className="group-caption">
                  {group.attribute_group_name_en}
                </div>
                <div className="select-area">
                  {group.group_associated_attributes.map((attribute) => (
                    <div className="select-group" key={attribute.id}>
                      <input
                        type="checkbox"
                        name={attribute.id}
                        id={attribute.id}
                        checked={selectedAttributes.some(
                          (attr) => attr.id === attribute.id
                        )}
                        onChange={() => handleCheckboxChange(attribute)}
                      />
                      <label htmlFor={attribute.id}>
                        {" "}
                        {attribute.attribute_name_en}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
        <div className="form-footer-attribute">
          <div className="handle-area">
            <p className="error-text"></p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                updateAttribute();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          mainMenuVisible ? "overlay-sub-menu active" : "overlay-sub-menu"
        }
        onClick={() => {
          mainMenuVisible && setMainMenuVisible(false);
        }}
      ></div>
    </div>
  );
};

export default HotelAtribute;
