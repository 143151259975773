import HotelDetailSeoAccordion from "../../components/acordion/HotelDetailSeoAccordion";

const HotelDetailSeo = () => {
  return (
    <div className="hotel-detail-seo">
      <div className="container">
        <HotelDetailSeoAccordion />
      </div>
    </div>
  );
};

export default HotelDetailSeo;
