import { useContext, useEffect, useState } from "react";

// Icon
import { useNavigate, useParams } from "react-router-dom";

// Axios
import axios from "axios";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { boolean, number, object, string } from "yup";

// Toastie
import { notify } from "../../utils/ToastIfyNonfoction";

// Context
import { MainContext } from "../../utils/MainContext";
import { useAuth } from "../../utils/AuthContext";

const HotelDetailGeneral = () => {
  // Global State
  const { hotelData, setHotelData } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [errorText, setErrorText] = useState("");

  // Params
  const { hotelId } = useParams();

  // Navigate
  const navigate = useNavigate();

  // Yup schema
  const hotelGeneralInfoSchema = object({
    hotel_name: string().required().trim(),
    hotel_active: boolean().nullable().optional(),
    hotel_phone: string().required().trim(),
    hotel_email: string().required().trim().email(),
    hotel_address: string().required().trim(),
    hotel_priority_rating: number().test(
      "maxDigits",
      "number field must have exactly 3 digits",
      (number) => number < 11
    ),
    hotel_infant_age: number().test(
      "maxDigits",
      "number field must have exactly 3 digits",
      (number) => number < 18
    ),
    hotel_star_rating: number().test(
      "maxDigits",
      "number field must have exactly 3 digits",
      (number) => number < 6
    ),
    hotel_fax: string().nullable().optional(),
    hotel_map_url: string().nullable().optional(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(hotelGeneralInfoSchema),
  });

  // getData
  useEffect(() => {
    setErrorText("");
    if (hotelId) {
      axios
        .get(`${process.env.REACT_APP_SITE_HOTEL_READ_BY_ID}${hotelId}`)
        .then((res) => {
          setValue("hotel_name", res.data.hotel_name);
          setValue("hotel_active", res.data.hotel_active);
          setValue("hotel_phone", res.data.hotel_phone);
          setValue("hotel_email", res.data.hotel_email);
          setValue("hotel_address", res.data.hotel_address);
          setValue("hotel_priority_rating", res.data.hotel_priority_rating);
          setValue("hotel_infant_age", res.data.hotel_infant_age);
          setValue("hotel_star_rating", res.data.hotel_star_rating);
          setValue("hotel_fax", res.data.hotel_fax);
          setValue("hotel_map_url", res.data.hotel_map_url);

          setHotelData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => res && navigate("/hotel"))
              .catch((err) => err && navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    } else {
      setHotelData({
        hotel_name: "",
        hotel_active: "",
        hotel_associated_main_image: "",
        hotel_associated_images: "",
        hotel_associated_coupons: "",
        hotel_associated_attributes: "",
        hotel_phone: "",
        hotel_email: "",
        hotel_address: "",
        hotel_priority_rating: "",
        hotel_infant_age: "",
        hotel_star_rating: "",
        hotel_fax: "",
        hotel_map_url: "",
      });
    }
  }, [setHotelData, hotelId, navigate, setValue, logout]);

  //Setting Form Submit

  const onSubmit = (data) => {
    const formData = new FormData();
    const datakeys = Object.keys(data);

    // Nesne anahtarlarını diziye dönüştür

    // setTestData(prevTestData => {
    //   const updatedTestData = { ...prevTestData };

    //   // datakeys dizisindeki her anahtar için işlem yapın
    //   datakeys.forEach(key => {
    //     updatedTestData[key] = data[key]; // Yeni değeri güncelle
    //   });

    //   return updatedTestData; // Güncellenmiş state'i döndür
    // });

    let UpdatedData = hotelData;
    datakeys.forEach((key) => {
      UpdatedData[key] = data[key]; // Yeni değeri güncelle
    });

    const keys = Object.keys(UpdatedData);
    // Anahtarları FormData'ya ekleyin
    keys.forEach((key) => {
      formData.append(key, UpdatedData[key]);
    });

    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }

    if (hotelId) {
      putData(UpdatedData, formData);
    } else {
      createData(formData);
    }
    setHotelData(UpdatedData);
  };

  const createData = (body) => {
    //     for (const [key, value] of body.entries()) {
    //   console.log(key, value);
    // }
    axios
      .post(process.env.REACT_APP_SITE_HOTEL_CREATE, body)
      .then((res) => {
        if (res.status === 200) {
          notify("Hotel Create!");

          const timer = setTimeout(() => {
            navigate("/hotel");
          }, 1500);
          return () => clearTimeout(timer);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              createData(body);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response.data.errors);
        }
      });
  };

  const putData = (hotelData, formData) => {
    // // /* ---------------------------- Set specific Data --------------------------- */
    formData.set(
      "hotel_associated_images",
      hotelData.hotel_associated_images
        ? hotelData.hotel_associated_images.map((item) => item.id).join(",")
        : ""
    );
    formData.set(
      "hotel_associated_main_image",
      hotelData.hotel_associated_main_image
        ? hotelData.hotel_associated_main_image.id
        : ""
    );
    formData.set(
      "hotel_associated_attributes",
      hotelData.hotel_associated_attributes
        ? hotelData.hotel_associated_attributes.map((item) => item.id).join(",")
        : ""
    );
    formData.set(
      "hotel_associated_coupons",
      hotelData.hotel_associated_coupons
        ? hotelData.hotel_associated_coupons.map((item) => item.id).join(",")
        : ""
    );

    // Request
    axios
      .put(`${process.env.REACT_APP_SITE_HOTEL_UPTADE}${hotelId}`, formData)
      .then((res) => {
        if (res.status === 200) {
          notify("Hotel DAta Update!");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              putData(hotelData, formData);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response.data.errors);
        }
      });
  };

  return (
    <div className="hotel-detail-general">
      <div className="container">
        <form
          action=""
          className="main-input-area"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="inp-area">
            <div className="form-group">
              <label htmlFor="hotel_name">Hotel Name*</label>
              <input
                type="text"
                className={errors.hotel_name ? "inp error" : "inp"}
                name="hotel_name"
                id="hotel_name"
                {...register("hotel_name", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="hotel_phone">Telephone</label>
              <input
                type="tel"
                className={errors.hotel_phone ? "inp error" : "inp"}
                id="hotel_phone"
                name="hotel_phone"
                {...register("hotel_phone", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="hotel_email">Mail</label>
              <input
                type="email"
                className={errors.hotel_email ? "inp error" : "inp"}
                id="hotel_email"
                name="hotel_email"
                {...register("hotel_email", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="hotel_address">Adress</label>
              <input
                type="text"
                className={errors.hotel_address ? "inp error" : "inp"}
                id="hotel_address"
                name="hotel_address"
                {...register("hotel_address", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="hotel_infant_age">İnfant Age</label>
              <input
                type="number"
                className={errors.hotel_infant_age ? "inp error" : "inp"}
                id="hotel_infant_age"
                name="hotel_infant_age"
                {...register("hotel_infant_age", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="hotel_priority_rating">Priority Rating</label>
              <input
                type="number"
                className={errors.hotel_priority_rating ? "inp error" : "inp"}
                id="hotel_priority_rating"
                name="hotel_priority_rating"
                {...register("hotel_priority_rating", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="hotel_star_rating">Rating</label>
              <input
                type="number"
                className={errors.hotel_star_rating ? "inp error" : "inp"}
                id="hotel_star_rating"
                name="hotel_star_rating"
                {...register("hotel_star_rating", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="hotel_fax">Fax Code</label>
              <input
                type="text"
                className={errors.hotel_fax ? "inp error" : "inp"}
                id="hotel_fax"
                name="hotel_fax"
                {...register("hotel_fax", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>
            <div className="form-group map">
              <label htmlFor="hotel_map_url">Map Url</label>
              <input
                type="text"
                className={errors.hotel_map_url ? "inp error" : "inp"}
                id="hotel_map_url"
                name="hotel_map_url"
                {...register("hotel_map_url", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
            </div>
            <div className="form-group checkbox-group">
              <label htmlFor="hotel_active" className="inp-caption">
                Activated {""}
              </label>
              <label htmlFor="hotel_active" className="switch">
                <input
                  type="checkbox"
                  id="hotel_active"
                  name="hotel_active"
                  className="checkbox"
                  {...register("hotel_active")}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="form-footer">
            <p className="error-text">{errorText}</p>
            <button>Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HotelDetailGeneral;
