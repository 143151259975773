// Icon
import close from "../assets/images/icon/close.svg";

const ImageCard = ({ img, onClickFunction }) => {
  return (
    <div className="img-card">
      <div className="top">
        <div className="close-icon" onClick={onClickFunction}>
          <img src={close} alt="close" />
        </div>
      </div>
      <div className="middle">
        <img
          src={`${process.env.REACT_APP_IMAGE}${img?.image}`}
          alt="logo"
        />
      </div>
      <div className="bottom">
        {/* <h6 className="img-title">Filename.jpg</h6> */}
      </div>
    </div>
  );
};

export default ImageCard;
