// React
import { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../utils/MainContext";

// React Router Dom
import { useLocation, useNavigate } from "react-router-dom";

// Axios
import axios from "axios";

// Sweet Alert
import { createRemoveAlert } from "../utils/SweetAlert";

// Icon
import menuIcon from "../assets/images/icon/burger-menu.svg";
import trash from "../assets/images/icon/trash.svg";
import edit from "../assets/images/icon/edit.svg";
import add from "../assets/images/icon/add-plus.svg";
import { useAuth } from "../utils/AuthContext";

const AllHotelList = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const { mainMenuVisible, setMainMenuVisible, setHotelDetailActiveTab } =
    useContext(MainContext);
  const { logout } = useAuth();

  // Router
  const { pathname } = useLocation();
  // Scroll Set Reset
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Local State
  const [allHotel, setAllHotel] = useState([]);
  const [prevNumber, setPrevNumber] = useState(0);
  const [activeNumber, setActiveNumber] = useState(1);
  const [nextNumber, setNextNumber] = useState(2);
  const [paginationData, setPaginationData] = useState(null);

  // Get Setting Data
  const getAllHotelData = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_HOTEL_READ_ALL}?locale="en"&page_length=20&page_number=${page_number}`
        )
        .then((res) => {
          setPaginationData(res.data);

          setAllHotel(res.data.page_data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                getAllHotelData(page_number);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate]
  );
  const searchHotel = useCallback(
    async (inputValue) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_HOTEL_SEARCH}/${inputValue}?locale=en`
        )
        .then((res) => setAllHotel(res.data))
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                searchHotel(inputValue);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate]
  );

  const dataController = (inp) => {
    if (inp.trim().length > 1) {
      searchHotel(inp);
    } else {
      getAllHotelData(activeNumber);
    }
  };

  // Rendering Data
  useEffect(() => {
    if (pathname === "/hotel") {
      setPrevNumber(0);
      setActiveNumber(1);
      setNextNumber(2);
      getAllHotelData(1);
    }
  }, [getAllHotelData, pathname]);

  /* ------------------------------- Change Page ------------------------------ */
  const handleChange = (step) => {
    let newPrevNumber = prevNumber;
    let newActiveNumber = activeNumber;
    let newNextNumber = nextNumber;

    if (step === "prev" && prevNumber > 0) {
      newPrevNumber = prevNumber - 1;
      newActiveNumber = activeNumber - 1;
      newNextNumber = nextNumber - 1;
    } else {
      newPrevNumber = prevNumber + 1;
      newActiveNumber = activeNumber + 1;
      newNextNumber = nextNumber + 1;
    }

    setPrevNumber(newPrevNumber);
    setActiveNumber(newActiveNumber);
    setNextNumber(newNextNumber);

    getAllHotelData(newActiveNumber);
  };

  // Delete Hotel
  const removeData = (dataId) => {
    createRemoveAlert(
      "Delete Hotel!",
      "Are you sure you want to delete the hotel?",
      "Yes, Remove",
      async () => {
        await axios
          .delete(`${process.env.REACT_APP_SITE_HOTEL_DELETE}${dataId}`)
          .then((res) => {
            if (res.status === 200) {
              const updateHotel = allHotel.filter(
                (hotel) => hotel.id !== dataId
              );
              setAllHotel(updateHotel);
            }
            // setAllHotel(res.data.page_data);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };

  return (
    <main>
      <section className="all-hotel-list">
        <div className="sub-header">
          <div className="container">
            <div className="row">
              <div className="info">
                <button
                  className=" burger-menu "
                  onClick={() => setMainMenuVisible(true)}
                >
                  <img src={menuIcon} alt="menu" />
                </button>
                <h2 className="caption">Hotel</h2>
              </div>
              <div className="search-form">
                <input
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => dataController(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="edit-area">
            <h6 className="title">Add Hotel</h6>
            <button
              className="add-btn"
              onClick={() => {
                navigate("/hotel-detail");
                setHotelDetailActiveTab("Hotel Add");
              }}
            >
              Add <img src={add} alt="add" />
            </button>
          </div>
        </div>
        <div className="hotel-info-area">
          <div className="container">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Hotel name</th>
                  <th>Address</th>
                  <th>Active</th>
                  <th>Edit && Delete </th>
                </tr>
              </thead>
              <tbody>
                {allHotel.length !== 0 &&
                  allHotel.map((hotel) => (
                    <tr key={hotel.id}>
                      <td>{hotel.id}</td>
                      <td>{hotel.hotel_name}</td>
                      <td>{hotel.hotel_address}</td>
                      <td>{hotel.hotel_active ? "Active" : "Dis-active"}</td>

                      <td className="edit">
                        <div className="btn-area">
                          <button onClick={() => removeData(hotel.id)}>
                            <img src={trash} alt="trash" />
                          </button>
                          <button
                            onClick={() => {
                              navigate(`/hotel-detail/${hotel.id}`);
                              setHotelDetailActiveTab("General");
                            }}
                          >
                            <img src={edit} alt="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={
            mainMenuVisible ? "overlay-sub-menu active" : "overlay-sub-menu"
          }
          onClick={() => setMainMenuVisible(false)}
        ></div>
        {paginationData && (
          <div className="pagination">
            <button
              className="btn prev"
              onClick={() => handleChange("prev")}
              disabled={!paginationData.page_has_previous}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM17.685 19.5C18.12 19.935 18.12 20.655 17.685 21.09C17.46 21.315 17.175 21.42 16.89 21.42C16.605 21.42 16.32 21.315 16.095 21.09L10.8 15.795C10.365 15.36 10.365 14.64 10.8 14.205L16.095 8.91C16.53 8.475 17.25 8.475 17.685 8.91C18.12 9.345 18.12 10.065 17.685 10.5L13.185 15L17.685 19.5Z"
                  fill=" #AD471F"
                />
              </svg>
            </button>
            {prevNumber !== 0 && (
              <button
                className="btn prev-number"
                onClick={() => handleChange("prev")}
              >
                {prevNumber}
              </button>
            )}
            <button className="btn active">{activeNumber}</button>
            {paginationData && paginationData.page_has_next && (
              <button
                className="btn next-number"
                onClick={() => handleChange("next")}
              >
                {nextNumber}
              </button>
            )}

            {paginationData && (
              <button
                className="btn next"
                disabled={!paginationData.page_has_next}
                onClick={() => handleChange("next")}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM19.185 15.795L13.89 21.09C13.665 21.315 13.38 21.42 13.095 21.42C12.81 21.42 12.525 21.315 12.3 21.09C11.865 20.655 11.865 19.935 12.3 19.5L16.8 15L12.3 10.5C11.865 10.065 11.865 9.345 12.3 8.91C12.735 8.475 13.455 8.475 13.89 8.91L19.185 14.205C19.635 14.64 19.635 15.36 19.185 15.795Z"
                    fill=" #AD471F"
                  />
                </svg>
              </button>
            )}
          </div>
        )}
      </section>
    </main>
  );
};

export default AllHotelList;
