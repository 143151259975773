// React
import { useCallback, useEffect, useState } from "react";

// Axios
import axios from "axios";

// Icon
import addFile from "../../assets/images/icon/add-btn.svg";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const Favicon = () => {
  // Global State
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  /* ------------------------------- Local State ------------------------------ */
  // Back Data
  const [faviconImgData, setFaviconImgData] = useState(null);
  const [faviconImgError, setFaviconImgError] = useState(null);

  const [faviconImage, setFaviconImage] = useState("");
  const [faviconPreview, setFaviconPreview] = useState("");

  //Photo Choose Favicon
  const handleFaviconImg = (e) => {
    // Check if files were selected
    if (!e.target.files || e.target.files.length === 0) {
      console.error("No file selected.");
      return;
    }

    let file = e.target.files[0];
    setFaviconImage(file);

    let reader = new FileReader();

    reader.onload = () => {
      setFaviconPreview(reader.result);
    };

    reader.onerror = (err) => {
      console.error("FileReader error:", err);
    };

    reader.readAsDataURL(file);
  };

  // Set Favicon
  const handleFavIconSubmit = async (e) => {
    e.preventDefault();
    const logo = new FormData();
    logo.append("icon", faviconImage);
    await axios
      .put(process.env.REACT_APP_SITE_FAVICON_READ, logo)
      .then((res) => {
        notify("Favicon Update");
        setFaviconImgData(res.data.logo);
        setFaviconPreview("");
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              handleFavIconSubmit(e);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setFaviconImgError(err);
        }
      });
  };

  // Get Favicon Data
  const getFaviconData = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_SITE_FAVICON_UPDATE)
      .then((res) => {
        setFaviconImgData(res.data.icon);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getFaviconData();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [logout, navigate]);

  // Rendering Data
  useEffect(() => {
    // getSettingData();
    getFaviconData();
  }, [getFaviconData]);
  return (
    <form className="image-area boxes-style">
      <h2 className="main-caption">Favicon</h2>
      <div className="form-group">
        <label htmlFor="add-favicon-photo" className="add">
          <div className="icon">
            <img src={addFile} alt="addfile" />
          </div>
          Choose file
        </label>
        <input
          type="file"
          name="add-favicon-photo"
          id="add-favicon-photo"
          onChange={handleFaviconImg}
        />
      </div>
      <div className="img-cards-area">
        <div className="img-card">
          <div className="top"></div>
          <div className="middle">
            <img
              src={
                faviconPreview
                  ? faviconPreview
                  : `${process.env.REACT_APP_IMAGE}${faviconImgData}`
              }
              alt="logo"
            />
          </div>
          <div className="bottom"></div>
        </div>
      </div>
      <div className="form-footer">
        {faviconPreview && (
          <>
            {faviconImgError && (
              <p className="error-text">Update failed, please check again</p>
            )}

            <button onClick={(e) => handleFavIconSubmit(e)}>Save</button>
          </>
        )}
      </div>
    </form>
  );
};

export default Favicon;
