// React
import { useContext, useEffect, useRef, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import arrowDown from "../../assets/images/icon/arrow-down.svg";
import deleteIcon from "../../assets/images/icon/delete.svg";
import down from "../../assets/images/icon/arrow-down.svg";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import diseaseList from "../../db/DiseaseIconList";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const DiseaseSIdeBar = ({ type }) => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    diseaseData,
    setDiseaseData,
    selectDisease,
    setSelectDisease,
    diseaseVisible,
    setDiseaseVisible,
  } = useContext(MainContext);

  const { logout } = useAuth();

  const listAreaAz = useRef();
  const listAreaEn = useRef();
  const listAreaRu = useRef();

  // Local State
  const [accordionVisible, setAccordionVisible] = useState("az");
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [inputData, setInputData] = useState(null);
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const [character, setCharacter] = useState({
    disease_characteristics_az: "",
    disease_characteristics_en: "",
    disease_characteristics_ru: "",
  });
  const [characterError, setCharacterError] = useState({
    disease_characteristics_az: false,
    disease_characteristics_en: false,
    disease_characteristics_ru: false,
  });
  const [selectIcon, setSelectIcon] = useState(null);
  const [errorText, setErrorText] = useState("");

  // React hook form
  const diseaseSchema = object({
    disease_icon_path: string().required().trim(),
    disease_hero_az: string().required().trim(),
    disease_text_az: string().required().trim(),
    disease_hero_en: string().required().trim(),
    disease_text_en: string().required().trim(),
    disease_hero_ru: string().required().trim(),
    disease_text_ru: string().required().trim(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(diseaseSchema),
  });

  const addCharacter = () => {
    const exist = character[inputData.id]
      .split("/&?/")
      .find((val) => val === inputData.value);

    // If the value doesn't exist, add it
    if (!exist && inputData.value.trim !== 0) {
      const newValue = character[inputData.id]
        ? `${character[inputData.id]}/&?/${inputData.value}`
        : inputData.value;

      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        [inputData.id]: newValue,
      }));
    }

    // let exist = character[inputData.id]
    //   .split("/&?/")
    //   .find((value) => value !== inputData.value && return false);
    // if (inputData.value.trim !== 0) {
    //   setCharacter((prevCharacter) => ({
    //     ...prevCharacter,
    //     [inputData.id]:
    //       prevCharacter[inputData.id] +
    //       (character[inputData.id]
    //         ? "/&?/" + inputData.value
    //         : inputData.value),
    //   }));
    // }
  };

  const deleteCharacter = (text, key) => {
    const updatedData = character[key]
      .split("/&?/")
      .filter((value) => value !== text)
      .join("/&?/");

    setCharacter((prevCharacter) => ({
      ...prevCharacter,
      [key]: updatedData,
    }));

    // setNewData(updatedData);
  };

  useEffect(() => {
    setAccordionVisible("az");
    setSelectIcon(null);
    setCharacterError({
      disease_characteristics_az: false,
      disease_characteristics_en: false,
      disease_characteristics_ru: false,
    });
    setCharacter({
      disease_characteristics_az: "",
      disease_characteristics_en: "",
      disease_characteristics_ru: "",
    });
    reset();
    setErrorText("");
    if (diseaseVisible && type === "edit") {
      diseaseList.map(
        (icon) =>
          icon.icon_name === selectDisease.disease_icon_path &&
          setSelectIcon(icon)
      );
      setCharacter({
        disease_characteristics_az: selectDisease.disease_characteristics_az,
        disease_characteristics_en: selectDisease.disease_characteristics_en,
        disease_characteristics_ru: selectDisease.disease_characteristics_ru,
      });

      setValue("disease_icon_path", selectDisease.disease_icon_path);
      setValue("disease_hero_az", selectDisease.disease_hero_az);
      setValue("disease_text_az", selectDisease.disease_text_az);
      setValue("disease_hero_en", selectDisease.disease_hero_en);
      setValue("disease_text_en", selectDisease.disease_text_en);
      setValue("disease_hero_ru", selectDisease.disease_hero_ru);
      setValue("disease_text_ru", selectDisease.disease_text_ru);
    }
  }, [
    diseaseVisible,
    reset,
    selectDisease.disease_characteristics_az,
    selectDisease.disease_characteristics_en,
    selectDisease.disease_characteristics_ru,
    selectDisease.disease_hero_az,
    selectDisease.disease_hero_en,
    selectDisease.disease_hero_ru,
    selectDisease.disease_icon_path,
    selectDisease.disease_text_az,
    selectDisease.disease_text_en,
    selectDisease.disease_text_ru,
    setValue,
    type,
  ]);
  //   useEffect(() => {
  //     reset();
  //     if (emailVisible) {
  //       selectEmail.template_associated_descriptions.map((email) => {
  //         setValue(
  //           `description_text_${email.description_locale}`,
  //           email.description_subject
  //         );
  //         setValue(
  //           `description_subject_${email.description_locale}`,
  //           email.description_text
  //         );
  //       });
  //     }
  //   }, [
  //     accordionVisible,
  //     emailVisible,
  //     reset,
  //     selectEmail.template_associated_descriptions,
  //     setValue,
  //   ]);
  const updateDisease = async (data) => {
    const body = new FormData();
    body.append("disease_icon_path", data.disease_icon_path);
    body.append("disease_hero_az", data.disease_hero_az);
    body.append("disease_text_az", data.disease_text_az);
    body.append("disease_hero_en", data.disease_hero_en);
    body.append("disease_text_en", data.disease_text_en);
    body.append("disease_hero_ru", data.disease_hero_ru);
    body.append("disease_text_ru", data.disease_text_ru);
    body.append(
      "disease_characteristics_az",
      character.disease_characteristics_az
    );
    body.append(
      "disease_characteristics_en",
      character.disease_characteristics_en
    );
    body.append(
      "disease_characteristics_ru",
      character.disease_characteristics_ru
    );

    if (type === "create") {
      await axios
        .post(process.env.REACT_APP_SITE_DISEASE_CREATE, body)
        .then((res) => {
          setDiseaseData([res.data, ...diseaseData]);
          setSelectIcon(null);
          notify("Disease Create!");
          setDiseaseVisible(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                updateDisease(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        });
    } else {
      await axios
        .put(
          `${process.env.REACT_APP_SITE_DISEASE_UPDATE}/${selectDisease.id}`,
          body
        )
        .then((res) => {
          setDiseaseData((prevStateArray) =>
            prevStateArray.map((item) =>
              item.id === res.data.id ? res.data : item
            )
          );
          setSelectIcon(null);
          notify("Disease Update!");
          setSelectDisease({
            disease_icon_path: "",
            disease_hero_az: "",
            disease_text_az: "",
            disease_characteristics_az: "",
            disease_hero_en: "",
            disease_text_en: "",
            disease_characteristics_en: "",
            disease_hero_ru: "",
            disease_text_ru: "",
            disease_characteristics_ru: "",
          });
          setDiseaseVisible(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                updateDisease(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        });
    }
  };

  useEffect(() => {
    const updateDynamicHeight = () => {
      if (
        accordionVisible === "az" &&
        listAreaAz.current &&
        character.disease_characteristics_az
      ) {
        setDynamicHeight(listAreaAz.current.offsetHeight);
      } else if (
        accordionVisible === "en" &&
        listAreaEn.current &&
        character.disease_characteristics_en
      ) {
        setDynamicHeight(listAreaEn.current.offsetHeight);
      } else if (
        accordionVisible === "ru" &&
        listAreaRu.current &&
        character.disease_characteristics_ru
      ) {
        setDynamicHeight(listAreaRu.current.offsetHeight);
      } else {
        setDynamicHeight(0); // Explicitly setting to null if no condition matches
      }
    };

    updateDynamicHeight();
  }, [
    accordionVisible,
    character.disease_characteristics_az,
    character.disease_characteristics_en,
    character.disease_characteristics_ru,
    listAreaAz,
    listAreaEn,
    listAreaRu,
  ]);

  return (
    <div
      className={
        diseaseVisible
          ? " isOpenMenu disease-sidebar-menu scroll "
          : "disease-sidebar-menu  scroll "
      }
      onClick={() => dropDownVisible && setDropDownVisible(false)}
    >
      <div className="head">
        <h4 className="caption">Disease</h4>
        <div
          className="icon"
          onClick={(e) => {
            e.stopPropagation();
            setDiseaseVisible(false);

            setSelectDisease({
              disease_icon_path: "",
              disease_hero_az: "",
              disease_text_az: "",
              disease_characteristics_az: "",
              disease_hero_en: "",
              disease_text_en: "",
              disease_characteristics_en: "",
              disease_hero_ru: "",
              disease_text_ru: "",
              disease_characteristics_ru: "",
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="disease-form"
          onSubmit={handleSubmit(updateDisease)}
        >
          <div
            className={`form-group ${
              dropDownVisible ? "select open" : "select"
            } `}
          >
            <label htmlFor="disease_icon_path" className="inp-caption">
              Icon
            </label>
            <input
              type="text"
              id="disease_icon_path"
              name="disease_icon_path"
              readOnly
              className={
                errors.disease_icon_path
                  ? "inp select-inp error"
                  : "inp select-inp"
              }
              placeholder="Select Icon "
              onClick={() => setDropDownVisible(!dropDownVisible)}
              {...register("disease_icon_path")}
            />
            <div className="icon">
              <img src={arrowDown} alt="open-close-dropdown" />
            </div>
            {selectIcon && (
              <div
                className="disease-icon"
                onClick={() => setDropDownVisible(!dropDownVisible)}
              >
                <img src={selectIcon.icon_img} alt={selectIcon.icon_name} />
              </div>
            )}

            <div
              className="select-area"
              style={{ height: dropDownVisible ? 200 : 0 }}
              onClick={(e) => e.stopPropagation()}
            >
              <ul className="select-list scroll">
                {diseaseList.map((photo) => (
                  <li
                    className="select-item"
                    onClick={(e) => {
                      e.target.parentElement.parentElement.parentElement.children[1].classList.remove(
                        "error"
                      );
                      setValue("disease_icon_path", photo.icon_name);
                      setDropDownVisible(false);
                      setSelectIcon(photo);
                    }}
                    key={photo.id}
                  >
                    <div className="icon-view">
                      <img src={photo.icon_img} alt={photo.icon_name} />
                    </div>
                    {photo.icon_name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="side-bar-divader"></div>

          <div className="email-acordion-area">
            <div
              className={
                accordionVisible === "az"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "az" ? "" : "az")
                }
              >
                <h3 className="acordion-caption">Azerbaijan</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{
                  height:
                    accordionVisible === "az"
                      ? character.disease_characteristics_az
                        ? 380 + dynamicHeight + 25
                        : 380
                      : 0,
                }}
              >
                <div className="form-group">
                  <label htmlFor="disease_hero_az">Title</label>
                  <input
                    className={errors.disease_hero_az ? "inp error" : "inp"}
                    type="text"
                    name="disease_hero_az"
                    id="disease_hero_az"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("disease_hero_az")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="disease_text_az">Text</label>
                  <textarea
                    className={
                      errors.disease_text_az ? "scroll error" : "scroll"
                    }
                    name="disease_text_az"
                    id="disease_text_az"
                    cols="30"
                    rows="10"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("disease_text_az")}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="disease_characteristics_az">
                    Add Character
                  </label>
                  <div className="add-character-area">
                    <input
                      className={
                        characterError.disease_characteristics_az
                          ? "inp error"
                          : "inp"
                      }
                      type="text"
                      name="disease_characteristics_az"
                      id="disease_characteristics_az"
                      onChange={(e) => {
                        setInputData(e.target);
                        if (characterError.disease_characteristics_az) {
                          setCharacterError({
                            ...characterError,
                            disease_characteristics_az: false,
                          });
                        }
                      }}
                      autoComplete="off"
                    />
                    <div
                      className="add-button"
                      onClick={(e) => {
                        if (
                          e.target.parentElement.firstChild.value.trim() !== ""
                        ) {
                          addCharacter();
                        } else {
                          setCharacterError({
                            ...characterError,
                            disease_characteristics_az: true,
                          });
                        }

                        // (listAreaAz.current.offsetHeight);
                      }}
                    >
                      Add
                    </div>
                  </div>
                </div>
                {character.disease_characteristics_az && (
                  <div className="clarifyingArea " ref={listAreaAz}>
                    {character.disease_characteristics_az
                      .split("/&?/")
                      .map((item, index) => (
                        <div className="clarifying-btn" key={index}>
                          <span className="text">{item}</span>
                          <div
                            className="delete"
                            onClick={() =>
                              deleteCharacter(
                                item,
                                "disease_characteristics_az"
                              )
                            }
                          >
                            <img src={deleteIcon} alt="delete" />
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                accordionVisible === "en"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "en" ? "" : "en")
                }
              >
                <h3 className="acordion-caption">English</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{
                  height:
                    accordionVisible === "en"
                      ? character.disease_characteristics_en
                        ? 380 + dynamicHeight + 25
                        : 380
                      : 0,
                }}
              >
                <div className="form-group">
                  <label htmlFor="disease_hero_en">Title</label>
                  <input
                    className={errors.disease_hero_en ? "inp error" : "inp"}
                    type="text"
                    name="disease_hero_en"
                    id="disease_hero_en"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("disease_hero_en")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="disease_text_en">Text</label>
                  <textarea
                    className={
                      errors.disease_text_en ? "scroll error" : "scroll"
                    }
                    name="disease_text_en"
                    id="disease_text_en"
                    cols="30"
                    rows="10"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("disease_text_en")}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="disease_characteristics_en">
                    Add Character
                  </label>
                  <div className="add-character-area">
                    <input
                      className={
                        characterError.disease_characteristics_en
                          ? "inp error"
                          : "inp"
                      }
                      type="text"
                      name="disease_characteristics_en"
                      id="disease_characteristics_en"
                      onChange={(e) => {
                        setInputData(e.target);
                        if (characterError.disease_characteristics_en) {
                          setCharacterError({
                            ...characterError,
                            disease_characteristics_en: false,
                          });
                        }
                      }}
                      autoComplete="off"
                    />
                    <div
                      className="add-button"
                      onClick={(e) => {
                        if (
                          e.target.parentElement.firstChild.value.trim() !== ""
                        ) {
                          addCharacter();
                        } else {
                          setCharacterError({
                            ...characterError,
                            disease_characteristics_en: true,
                          });
                        }

                        // (listAreaAz.current.offsetHeight);
                      }}
                    >
                      Add
                    </div>
                  </div>
                </div>
                {character.disease_characteristics_en && (
                  <div className="clarifyingArea " ref={listAreaEn}>
                    {character.disease_characteristics_en
                      .split("/&?/")
                      .map((item, index) => (
                        <div className="clarifying-btn" key={index}>
                          <span className="text">{item}</span>
                          <div
                            className="delete"
                            onClick={() =>
                              deleteCharacter(
                                item,
                                "disease_characteristics_en"
                              )
                            }
                          >
                            <img src={deleteIcon} alt="delete" />
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                accordionVisible === "ru"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "ru" ? "" : "ru")
                }
              >
                <h3 className="acordion-caption">Russian</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{
                  height:
                    accordionVisible === "ru"
                      ? character.disease_characteristics_ru
                        ? 380 + dynamicHeight + 25
                        : 380
                      : 0,
                }}
              >
                <div className="form-group">
                  <label htmlFor="disease_hero_ru">Title</label>
                  <input
                    className={errors.disease_hero_ru ? "inp error" : "inp"}
                    type="text"
                    name="disease_hero_ru"
                    id="disease_hero_ru"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("disease_hero_ru")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="disease_text_ru">Text</label>
                  <textarea
                    className={
                      errors.disease_text_ru ? "scroll error" : "scroll"
                    }
                    name="disease_text_ru"
                    id="disease_text_ru"
                    cols="30"
                    rows="10"
                    maxLength={254}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("disease_text_ru")}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="disease_characteristics_ru">
                    Add Character
                  </label>
                  <div className="add-character-area">
                    <input
                      className={
                        characterError.disease_characteristics_ru
                          ? "inp error"
                          : "inp"
                      }
                      type="text"
                      name="disease_characteristics_ru"
                      id="disease_characteristics_ru"
                      onChange={(e) => {
                        setInputData(e.target);
                        if (characterError.disease_characteristics_ru) {
                          setCharacterError({
                            ...characterError,
                            disease_characteristics_ru: false,
                          });
                        }
                      }}
                      autoComplete="off"
                    />
                    <div
                      className="add-button"
                      onClick={(e) => {
                        if (
                          e.target.parentElement.firstChild.value.trim() !== ""
                        ) {
                          addCharacter();
                        } else {
                          setCharacterError({
                            ...characterError,
                            disease_characteristics_ru: true,
                          });
                        }

                        // (listAreaAz.current.offsetHeight);
                      }}
                    >
                      Add
                    </div>
                  </div>
                </div>
                {character.disease_characteristics_ru && (
                  <div className="clarifyingArea" ref={listAreaRu}>
                    {character.disease_characteristics_ru
                      .split("/&?/")
                      .map((item, index) => (
                        <div className="clarifying-btn" key={index}>
                          <span className="text">{item}</span>
                          <div
                            className="delete"
                            onClick={() =>
                              deleteCharacter(
                                item,
                                "disease_characteristics_ru"
                              )
                            }
                          >
                            <img src={deleteIcon} alt="delete" />
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-footer">
            <button
              onClick={(e) => {
                // e.stopPropagation();
                // e.preventDefault();
                // handleSubmit(updateDisease);
                if (errors.disease_hero_az || errors.disease_text_az) {
                  setAccordionVisible("az");
                } else if (errors.disease_hero_en || errors.disease_text_en) {
                  setAccordionVisible("en");
                } else if (errors.disease_hero_ru || errors.disease_text_ru) {
                  setAccordionVisible("ru");
                } else if (character.disease_characteristics_az === "") {
                  setAccordionVisible("az");
                  setCharacterError({
                    ...characterError,
                    disease_characteristics_az: true,
                  });
                } else if (character.disease_characteristics_en === "") {
                  setAccordionVisible("en");
                  setCharacterError({
                    ...characterError,
                    disease_characteristics_en: true,
                  });
                } else if (character.disease_characteristics_ru === "") {
                  setAccordionVisible("ru");
                  setCharacterError({
                    ...characterError,
                    disease_characteristics_ru: true,
                  });
                } else {
                  // handleSubmit(updateDisease);\
                  return null;
                }
              }}
            >
              Save
            </button>
            <p className="error-text"></p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DiseaseSIdeBar;
