import React, { useCallback, useContext, useEffect, useState } from "react";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Icon
import down from "../assets/images/icon/arrow-down.svg";
import menuIcon from "../assets/images/icon/burger-menu.svg";

import { MainContext } from "../utils/MainContext";
import axios from "axios";
import { notify } from "../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";

// Toastie
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../utils/AuthContext";

const GeneralSeo = () => {
  // Global State
  const { mainMenuVisible, setMainMenuVisible } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State && DataList
  const tabList = [
    { id: 1, tab: "Home", page: "home" },
    { id: 2, tab: "Naftalan History", page: "naftalan-history" },
    { id: 3, tab: "Naftalan Disease", page: "naftalan-disease" },
    { id: 4, tab: "Faq", page: "faq" },
    { id: 5, tab: "Contact", page: "contact" },
  ];

  // Navigate
  const navigate = useNavigate();

  // Local State
  const [errorText, setErrorText] = useState("");
  const [seoId, setSeoId] = useState(0);
  // Local State
  const [activeTab, setActiveTab] = useState({
    id: 1,
    tab: "Home",
    page: "home",
  });
  const [accordionVisible, setAccordionVisible] = useState("az");
  const [type, setType] = useState("");

  // Yup schema
  const seoPagesSchema = object({
    seo_title_az: string().required().trim(),
    seo_description_az: string().required().trim(),
    seo_title_en: string().required().trim(),
    seo_description_en: string().required().trim(),
    seo_title_ru: string().required().trim(),
    seo_description_ru: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(seoPagesSchema),
  });

  // Get Request
  const getSeoData = useCallback(async () => {
    reset();
    setType("");
    setSeoId(0);
    await axios
      .get(`${process.env.REACT_APP_SITE_PAGES_SEO_READ}/${activeTab.page}`)
      .then((res) => {
        if (res.data.length === 0) {
          setType("create");
        } else {
          setSeoId(res.data[0].id);
          setType("edit");
          setValue("seo_title_az", res.data[0].seo_title_az);
          setValue("seo_description_az", res.data[0].seo_description_az);
          setValue("seo_title_en", res.data[0].seo_title_en);
          setValue("seo_description_en", res.data[0].seo_description_en);
          setValue("seo_title_ru", res.data[0].seo_title_ru);
          setValue("seo_description_ru", res.data[0].seo_description_ru);
        }
      })
      .catch((err) => {});
  }, [activeTab.page, reset, setValue]);

  // Render Data
  useEffect(() => {
    setErrorText();
    getSeoData();
  }, [getSeoData]);

  // Create && Update Request
  const pageSeoCreateEdit = async (data) => {
    const formData = new FormData();
    formData.append("seo_page", activeTab.page);
    formData.append("seo_title_az", data.seo_title_az);
    formData.append("seo_description_az", data.seo_description_az);
    formData.append("seo_title_en", data.seo_title_en);
    formData.append("seo_description_en", data.seo_description_en);
    formData.append("seo_title_ru", data.seo_title_ru);
    formData.append("seo_description_ru", data.seo_description_ru);

    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    if (type === "create") {
      await axios
        .post(process.env.REACT_APP_SITE_PAGES_SEO_CREATE, formData)
        .then((res) => res && notify(`${activeTab.tab} Page Seo Create  !`))
        .catch((err) => {
          // Jwt Error  Check

          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => res && pageSeoCreateEdit(data))
              .catch((err) => err && navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            // Another Error Check
            setErrorText(err.response?.data.errors);
          }
        });
    } else {
      await axios
        .put(
          `${process.env.REACT_APP_SITE_PAGES_SEO_UPDATE}/${seoId}`,
          formData
        )
        .then((res) => res && notify(`${activeTab.tab} Page Seo Update!`))
        .catch((err) => {
          // Jwt Error  Check

          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => res && pageSeoCreateEdit(data))
              .catch((err) => err && navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            // Another Error Check
            setErrorText(err.response?.data.errors);
          }
        });
    }
  };

  return (
    <main>
      <section className="hotel-detail">
        <div className="sub-header">
          <div className="container">
            <div className="row">
              <div className="info">
                <button
                  className=" burger-menu "
                  onClick={() => setMainMenuVisible(true)}
                >
                  <img src={menuIcon} alt="menu" />
                </button>
                <h2 className="caption">Home</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="hotel-detail-header">
          <div className="container">
            <ul className="tab-list">
              {tabList.map((menuTab) => (
                <li
                  className={
                    activeTab.tab === menuTab.tab
                      ? "tab-item active"
                      : "tab-item"
                  }
                  onClick={() => setActiveTab(menuTab)}
                  key={menuTab.id}
                >
                  {menuTab.tab}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={
            mainMenuVisible ? "overlay-sub-menu active" : "overlay-sub-menu"
          }
          onClick={() => {
            mainMenuVisible && setMainMenuVisible(false);
          }}
        ></div>
      </section>

      <section className="naftalan-history">
        <form
          action=""
          className="naftalan-history-form boxes-style"
          onSubmit={handleSubmit(pageSeoCreateEdit)}
        >
          <div className="description-acordion-area">
            <div
              className={
                accordionVisible === "az"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "az" ? "" : "az")
                }
              >
                <h3 className="acordion-caption">Azerbaijan</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{ height: accordionVisible === "az" ? 300 : 0 }}
              >
                <div className="form-group">
                  <label htmlFor="seo_title_az">Title</label>
                  <input
                    name="seo_title_az"
                    id="seo_title_az"
                    autoComplete="off"
                    maxLength={254}
                    className={errors.seo_title_az ? "scroll error" : "scroll"}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("seo_title_az")}
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="seo_description_az">Text</label>
                  <textarea
                    name="seo_description_az"
                    id="seo_description_az"
                    cols="30"
                    rows="10"
                    className={
                      errors.seo_description_az ? "scroll error" : "scroll"
                    }
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("seo_description_az")}
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              className={
                accordionVisible === "en"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "en" ? "" : "en")
                }
              >
                <h3 className="acordion-caption">English</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{ height: accordionVisible === "en" ? 300 : 0 }}
              >
                <div className="form-group">
                  <label htmlFor="seo_title_en">Title</label>
                  <input
                    name="seo_title_en"
                    id="seo_title_en"
                    autoComplete="off"
                    maxLength={254}
                    className={errors.seo_title_en ? "scroll error" : "scroll"}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("seo_title_en")}
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="seo_description_en">Text</label>
                  <textarea
                    name="seo_description_en"
                    id="seo_description_en"
                    cols="30"
                    rows="10"
                    className={
                      errors.seo_description_en ? "scroll error" : "scroll"
                    }
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("seo_description_en")}
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              className={
                accordionVisible === "ru"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "ru" ? "" : "ru")
                }
              >
                <h3 className="acordion-caption">Russian</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{ height: accordionVisible === "ru" ? 300 : 0 }}
              >
                <div className="form-group">
                  <label htmlFor="seo_title_ru">Title</label>
                  <input
                    name="seo_title_ru"
                    id="seo_title_ru"
                    autoComplete="off"
                    maxLength={254}
                    className={errors.seo_title_ru ? "scroll error" : "scroll"}
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("seo_title_ru")}
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="seo_description_ru">Text</label>
                  <textarea
                    name="seo_description_ru"
                    id="seo_description_ru"
                    cols="30"
                    rows="10"
                    className={
                      errors.seo_description_ru ? "scroll error" : "scroll"
                    }
                    onChange={() => errorText !== "" && setErrorText("")}
                    {...register("seo_description_ru")}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="form-footer">
            <div className="handle-area">
              <p className="error-text">{errorText}</p>
              <button
                onClick={() => {
                  if (errors.seo_title_az || errors.seo_description_az) {
                    setAccordionVisible("az");
                  } else if (errors.seo_title_en || errors.seo_description_en) {
                    setAccordionVisible("en");
                  } else if (errors.seo_title_ru || errors.seo_description_ru) {
                    setAccordionVisible("ru");
                  } else {
                    return null;
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </main>
  );
};

export default GeneralSeo;
