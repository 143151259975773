import { useContext, useEffect, useState } from "react";
// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import axios from "axios";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { boolean, object, string } from "yup";

import { MainContext } from "../../utils/MainContext";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const DiscountFreeNight = ({ type }) => {
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    discountFreeNightSideBar,
    setDiscountFreeNightSideBar,
    // Discount
    discountData,
    setDiscountData,
    selectDiscount,
    setSelectDiscount,
  } = useContext(MainContext);

  const [errorText, setErrorText] = useState("");
  // Yup schema
  const freeNightSchema = object({
    offer_active: boolean().nullable().optional(),
    offer_name: string().required().trim(),
    offer_public_name_az: string().required().trim(),
    offer_public_name_ru: string().required().trim(),
    offer_public_name_en: string().required().trim(),
    offer_start: string().required().trim(),
    offer_end: string().required().trim(),
    offer_travel_dates_must_be_within_offer_dates: boolean(),
    offer_recurring_period: string().required().trim(),
    offer_free_night_quantity: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(freeNightSchema),
  });

  useEffect(() => {
    if (selectDiscount && type === "edit") {
      setValue("offer_active", selectDiscount.offer_active);
      setValue("offer_name", selectDiscount.offer_name);
      setValue("offer_public_name_az", selectDiscount.offer_public_name_az);
      setValue("offer_public_name_en", selectDiscount.offer_public_name_en);
      setValue("offer_public_name_ru", selectDiscount.offer_public_name_ru);
      setValue("offer_start", selectDiscount.offer_start);
      setValue("offer_end", selectDiscount.offer_end);
      setValue(
        "offer_travel_dates_must_be_within_offer_dates",
        selectDiscount.offer_travel_dates_must_be_within_offer_dates
      );
      setValue("offer_recurring_period", selectDiscount.offer_recurring_period);
      setValue(
        "offer_free_night_quantity",
        selectDiscount.offer_free_night_quantity
      );
    }
  }, [selectDiscount, setValue, type]);

  // Create && Edit
  const submit = async (data) => {
    const freeNightBody = new FormData();

    // freeNightBody
    freeNightBody.append("offer_active", data.offer_active);
    freeNightBody.append("offer_name", data.offer_name);
    freeNightBody.append("offer_public_name_az", data.offer_public_name_az);
    freeNightBody.append("offer_public_name_en", data.offer_public_name_en);
    freeNightBody.append("offer_public_name_ru", data.offer_public_name_ru);

    freeNightBody.append("offer_start", data.offer_start);
    freeNightBody.append("offer_end", data.offer_end);
    freeNightBody.append(
      "offer_travel_dates_must_be_within_offer_dates",
      data.offer_travel_dates_must_be_within_offer_dates
    );
    freeNightBody.append("offer_recurring_period", data.offer_recurring_period);
    freeNightBody.append(
      "offer_free_night_quantity",
      data.offer_free_night_quantity
    );

    if (type === "edit") {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SITE_DISCOUNT_FREE_NIGHT_UPDATE}${selectDiscount.id} `,
          freeNightBody
        );
        setDiscountData((prevData) => {
          return prevData.map((item) => {
            if (item.id === res.data.id) {
              return res.data;
            }
            return item;
          });
        });

        setSelectDiscount({
          offer_active: "",
          offer_name: "",
          offer_public_name_az: "",
          offer_public_name_ru: "",
          offer_public_name_en: "",
          offer_start: "",
          offer_end: "",
          offer_travel_dates_must_be_within_offer_dates: "",
          offer_recurring_period: "",
          offer_free_night_quantity: "",
          offer_percentage_value: "",
        });
        notify("Free Night Offer Update!");
        setDiscountFreeNightSideBar(false);
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              submit(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      }
    } else {
      await axios
        .post(
          process.env.REACT_APP_SITE_DISCOUNT_FREE_NIGHT_CREATE,
          freeNightBody
        )
        .then((res) => {
          setDiscountData([res.data, ...discountData]);
          reset();
          setDiscountFreeNightSideBar(false);
          setSelectDiscount({
            offer_active: "",
            offer_name: "",
            offer_public_name_az: "",
            offer_public_name_ru: "",
            offer_public_name_en: "",
            offer_start: "",
            offer_end: "",
            offer_travel_dates_must_be_within_offer_dates: "",
            offer_recurring_period: "",
            offer_free_night_quantity: "",
            offer_percentage_value: "",
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                submit(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        });
    }
  };
  return (
    <div
      className={
        discountFreeNightSideBar
          ? " isOpenMenu sidebar-discount-menu  scroll"
          : "sidebar-discount-menu   scroll"
      }
    >
      <div className="head">
        <h4 className="caption">Discount</h4>
        <div
          className="icon"
          onClick={() => {
            setDiscountFreeNightSideBar(false);
            setSelectDiscount({
              offer_active: "",
              offer_name: "",
              offer_public_name_az: "",
              offer_public_name_ru: "",
              offer_public_name_en: "",
              offer_start: "",
              offer_end: "",
              offer_travel_dates_must_be_within_offer_dates: "",
              offer_recurring_period: "",
              offer_free_night_quantity: "",
              offer_percentage_value: "",
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="discount-form"
          onSubmit={handleSubmit(submit)}
        >
          <div className="form-group">
            <label htmlFor="offer_name" className="inp-caption">
              Offer name
            </label>
            <input
              type="text"
              className={errors.offer_name ? "inp error" : "inp"}
              name="offer_name"
              id="offer_name"
              {...register("offer_name", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="side-bar-divader"></div>
          <div className="form-group">
            <label htmlFor="offer_public_name_az" className="inp-caption">
              Public Name Az
            </label>
            <input
              type="text"
              className={errors.offer_public_name_az ? "inp error" : "inp"}
              name="offer_public_name_az"
              id="offer_public_name_az"
              {...register("offer_public_name_az", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="offer_public_name_en" className="inp-caption">
              Public Name En
            </label>
            <input
              type="text"
              className={errors.offer_public_name_en ? "inp error" : "inp"}
              name="offer_public_name_en"
              id="offer_public_name_en"
              {...register("offer_public_name_en", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="offer_public_name_ru" className="inp-caption">
              Public Name Ru
            </label>
            <input
              type="text"
              className={errors.offer_public_name_ru ? "inp error" : "inp"}
              name="offer_public_name_ru"
              id="offer_public_name_ru"
              {...register("offer_public_name_ru", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="side-bar-divader"></div>
          <div className="form-group">
            <label htmlFor="offer_start" className="inp-caption">
              Offer start
            </label>
            <input
              type="date"
              className={errors.offer_start ? "inp error" : "inp"}
              name="offer_start"
              id="offer_start"
              {...register("offer_start", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="offer_end" className="inp-caption">
              Offer end
            </label>
            <input
              type="date"
              className={errors.offer_end ? "inp error" : "inp"}
              name="offer_end"
              id="offer_end"
              {...register("offer_end", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="side-bar-divader"></div>
          <div className="form-group">
            <label htmlFor="offer_recurring_period" className="inp-caption">
              "Period"
            </label>
            <input
              type="number"
              className={errors.offer_recurring_period ? "inp error" : "inp"}
              name={"offer_recurring_period"}
              id={"offer_recurring_period"}
              {...register("offer_recurring_period", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="offer_free_night_quantity" className="inp-caption">
              "Free night quantity"
            </label>
            <input
              type="text"
              className={errors.offer_free_night_quantity ? "inp error" : "inp"}
              name="offer_free_night_quantity"
              id="offer_free_night_quantity"
              {...register("offer_free_night_quantity", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="side-bar-divader"></div>

          <div className="form-group">
            <label htmlFor="offer_active" className="inp-caption">
              Offer active
            </label>
            <label htmlFor="offer_active" className="switch">
              <input
                type="checkbox"
                id="offer_active"
                name="offer_active"
                className="checkbox"
                {...register("offer_active")}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="form-group">
            <label
              htmlFor="offer_travel_dates_must_be_within_offer_dates"
              className="inp-caption"
            >
              Offer travel dates must be within offer dates
            </label>
            <label
              htmlFor="offer_travel_dates_must_be_within_offer_dates"
              className="switch"
            >
              <input
                type="checkbox"
                id="offer_travel_dates_must_be_within_offer_dates"
                name="offer_travel_dates_must_be_within_offer_dates"
                className="checkbox"
                {...register("offer_travel_dates_must_be_within_offer_dates")}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="form-footer">
            <button>Save</button>
            <p className="error-text">{errorText}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DiscountFreeNight;
