import React, { useContext, useEffect } from "react";
import { MainContext } from "../utils/MainContext";

// Icon
import menuIcon from "../assets/images/icon/burger-menu.svg";
import arrow from "../assets/images/icon/arrow-left-white.svg";

// Component
import Banner from "./home-page-section/Banner";
import NaftalanDiesas from "./home-page-section/NaftalanDiesas";
import NaftalanHistory from "./home-page-section/NaftalanHistory";

// Toastie
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomPAgeAllList from "./home-page-section/CustomPAgeAllList";
import CustomPageDetail from "./home-page-section/CustomPageDetail";
import FAQ from "./home-page-section/FAQ";

const WebsitePagesAndContent = () => {
  // Global State
  const {
    mainMenuVisible,
    setMainMenuVisible,
    homeActiveTab,
    setHomeActiveTab,
  } = useContext(MainContext);

  // Local State && DataList
  const tabList = [
    { id: 1, tab: "Promotion Banner" },
    { id: 2, tab: "Custom Webiste Pages" },
    { id: 3, tab: "Naftalan History Page" },
    { id: 4, tab: "Naftalan Diseases Page" },
    { id: 5, tab: "FAQ" },
  ];

  // // Params
  // const { hotelId } = useParams();

  useEffect(() => {
    setHomeActiveTab("Promotion Banner");
  }, [setHomeActiveTab]);

  return (
    <main>
      <section className="hotel-detail">
        <div className="sub-header">
          <div className="container">
            <div className="row">
              <div className="info">
                <button
                  className=" burger-menu "
                  onClick={() => setMainMenuVisible(true)}
                >
                  <img src={menuIcon} alt="menu" />
                </button>
                {homeActiveTab === "Custom Page Detail" && (
                  <button
                    className="back-btn"
                    onClick={() => setHomeActiveTab("Custom Webiste Pages")}
                  >
                    <img src={arrow} alt="back" />
                    Back
                  </button>
                )}

                <h2 className="caption"> Home</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="hotel-detail-header">
          <div className="container">
            <ul className="tab-list">
              {homeActiveTab !== "Hotel Add" ? (
                tabList.map((menuTab) => (
                  <li
                    className={
                      homeActiveTab === menuTab.tab ||
                      (homeActiveTab === "Custom Page Detail" &&
                        menuTab.tab === "Custom Webiste Pages")
                        ? "tab-item active"
                        : "tab-item"
                    }
                    onClick={() => setHomeActiveTab(menuTab.tab)}
                    key={menuTab.id}
                  >
                    {homeActiveTab === "Custom Page Detail" &&
                    menuTab.tab === "Custom Webiste Pages"
                      ? "Custom Page Detail"
                      : menuTab.tab}
                  </li>
                ))
              ) : (
                <li className="tab-item active">{homeActiveTab}</li>
              )}
            </ul>
          </div>
        </div>
        <div
          className={
            mainMenuVisible ? "overlay-sub-menu active" : "overlay-sub-menu"
          }
          onClick={() => {
            mainMenuVisible && setMainMenuVisible(false);
          }}
        ></div>
        <div className="hotel-detail-body">
          {homeActiveTab === "Promotion Banner" ? (
            <Banner />
          ) : homeActiveTab === "Naftalan Diseases Page" ? (
            <NaftalanDiesas />
          ) : homeActiveTab === "Naftalan History Page" ? (
            <NaftalanHistory />
          ) : homeActiveTab === "Custom Webiste Pages" ? (
            <CustomPAgeAllList />
          ) : homeActiveTab === "Custom Page Detail" ? (
            <CustomPageDetail />
          ) : homeActiveTab === "FAQ" ? (
            <FAQ />
          ) : null}
        </div>

        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Bounce}
        />
      </section>
    </main>
  );
};

export default WebsitePagesAndContent;
