import Disease from "./Disease";
import NaftalanDiseaseHistory from "./NaftalanDiseaseHistory";

const NaftalanDiesas = () => {
  return (
    <section className="banner">
      <div className="container">
        {/* Naftalan Disease History Page */}
        <NaftalanDiseaseHistory />
        <Disease />
      </div>
    </section>
  );
};

export default NaftalanDiesas;
