// React
import { useCallback, useContext, useEffect, useState } from "react";
// Context
import { MainContext } from "../utils/MainContext";

// Axios
import axios from "axios";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Icon
import folderImg from "../assets/images/icon/folder.svg";
import add from "../assets/images/icon/add-plus.svg";
import arrow from "../assets/images/icon/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";

const AddImg = () => {
  // Global state
  const {
    folderData,
    setFolderData,
    addPhotoVisible,
    setAddPhotoVisible,
    setHotelMainPhoto,
    setHotelAdditionPhoto,
    setRoomMainPhoto,
    setRoomAdditionPhoto,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Local State
  const [link, setLink] = useState([]);
  const [createFolderModalVisible, setCreateFolderModalVisible] =
    useState(false);

  //   Base Folder
  const getBaseFolderDAta = useCallback(async () => {
    axios
      .get(process.env.REACT_APP_SITE_FOLDER_READ_BASE)
      .then((res) => setFolderData(res.data))
      .catch((err) => {
        // Reflesh Token
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && getBaseFolderDAta())
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [logout, navigate, setFolderData]);

  // Folder Manipulation Walking
  const getFolderData = useCallback(
    async (folderId) => {
      axios
        .get(`${process.env.REACT_APP_SITE_FOLDER_READ_BY_ID}${folderId}`)
        .then((res) => {
          setFolderData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => res && getFolderData(folderId))
              .catch((err) => err && navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setFolderData]
  );

  // Rendering Data
  useEffect(() => {
    getBaseFolderDAta();
  }, [getBaseFolderDAta]);

  //   Back Step
  const backToStep = () => {
    const updateLink = [...link];
    updateLink.pop();
    if (updateLink.length === 0) {
      getBaseFolderDAta();
    } else {
      getFolderData(link[updateLink.length - 1].id);
    }
    setLink(updateLink);
  };

  // Yup schema
  const createSchema = object({
    folder_name: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(createSchema),
  });
  //   Create Folder

  const createFolder = async (data) => {
    const body = new FormData();
    body.append("name", data.folder_name);
    body.append("parent_folder", folderData.id ? folderData.id : "");

    await axios
      .post(process.env.REACT_APP_SITE_FOLDER_CREATE, body)
      .then((res) => {
        setCreateFolderModalVisible(false);
        const newSubfolder = { id: res.data.id, name: res.data.name };
        const newSubfolders = [...folderData.subfolders];
        newSubfolders.push(newSubfolder);
        setFolderData({
          ...folderData,
          subfolders: newSubfolders,
        });
        reset();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && createFolder(data))
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  };

  //Photo Choose
  const createImg = async (e) => {
    // // Check if files were selected
    // if (!e.target.files || e.target.files.length === 0) {
    //   console.error("No file selected.");
    //   return;
    // }

    let file = e.target.files[0];
    const body = new FormData();
    body.append("image", file);
    body.append("parent_folder", folderData.id ? folderData.id : "");
    await axios
      .post(process.env.REACT_APP_SITE_FOLDER_IMG_CREATE, body)
      .then((res) => {
        const newImages = [...folderData.images];
        newImages.push(res.data);
        setFolderData({
          ...folderData,
          images: newImages,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && createImg(e))
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  };

  const selectImg = (photo) => {
    if (addPhotoVisible.type === "main") {
      setHotelMainPhoto(photo);
      setAddPhotoVisible({
        type: "",
        visible: false,
      });
    } else if (addPhotoVisible.type === "main-room-photo") {
      setRoomMainPhoto(photo);
      setAddPhotoVisible({
        type: "",
        visible: false,
      });
    } else if (addPhotoVisible.type === "addition-room-photo") {
      setRoomAdditionPhoto((prevPhotos) => [...prevPhotos, photo]);
      setAddPhotoVisible({
        type: "",
        visible: false,
      });
    } else {
      setHotelAdditionPhoto((prevPhotos) => [...prevPhotos, photo]);
      setAddPhotoVisible({
        type: "",
        visible: false,
      });
    }
  };

  return (
    addPhotoVisible.visible && (
      <div
        className="add-img"
        onClick={() =>
          setAddPhotoVisible({
            type: "",
            visible: false,
          })
        }
      >
        <div className="add-img-content" onClick={(e) => e.stopPropagation()}>
          <div className="container scroll">
            <div className="close-modal"></div>
            <div className="add-area">
              <div className="edit-area">
                <h6 className="title">Add Folder</h6>
                <button
                  className="add-btn"
                  onClick={() => setCreateFolderModalVisible(true)}
                >
                  Add <img src={add} alt="add" />
                </button>
              </div>
            </div>
            <div className="edit-area">
              <h6 className="title">Add Photo</h6>
              <label
                htmlFor="img"
                className="add-btn"
                // onClick={() => setDiscountDetail(true)}
              >
                Add <img src={add} alt="add" />
              </label>
              <input type="file" name="img" id="img" onChange={createImg} />
            </div>
            <h2 className="hyper-link">
              <button onClick={() => backToStep()}>
                <img src={arrow} alt="" /> Back
              </button>{" "}
              | Base
              {link.length !== 0 &&
                `/ ${link.map((item) => item.name).join(" / ")}`}
            </h2>
            <div className="image-folder">
              <div className="row">
                {folderData.length !== 0 &&
                  folderData.subfolders.map((folder) => (
                    <div
                      className="folder-group"
                      key={folder.id}
                      onDoubleClick={() => {
                        getFolderData(folder.id);

                        setLink([
                          ...link,
                          { id: folder.id, name: folder.name },
                        ]);
                      }}
                    >
                      <div className="folder">
                        <img src={folderImg} alt={folder.name} />
                      </div>
                      <h2 className="folder-name">{folder.name}</h2>
                    </div>
                  ))}
                {folderData.length !== 0 &&
                  folderData.images.map((photo) => (
                    <div
                      className="image-group"
                      key={photo.id}
                      onDoubleClick={() => {
                        selectImg(photo);
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_IMAGE}${photo.image}`}
                        alt=""
                      />
                    </div>
                  ))}
              </div>
            </div>
            {createFolderModalVisible && (
              <div
                className="create-folder"
                onClick={() => {
                  setCreateFolderModalVisible(false);
                  reset();
                }}
              >
                <form
                  className="content"
                  noValidate
                  onSubmit={handleSubmit(createFolder)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <label htmlFor="folder_name">Folder Name</label>
                  <input
                    className={errors.folder_name ? "inp error" : "inp"}
                    type="text"
                    name="folder_name"
                    id="folder_name"
                    {...register("folder_name")}
                  />
                  <button className="submit">Create Folder</button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default AddImg;
