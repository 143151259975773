import { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../utils/MainContext";
import { useAuth } from "../utils/AuthContext";

// Icon
import menuIcon from "../assets/images/icon/burger-menu.svg";
import trash from "../assets/images/icon/trash.svg";
import arrow from "../assets/images/icon/arrow-left.svg";
import axios from "axios";
import { createRemoveAlert } from "../utils/SweetAlert";
// React Router Dom
import { useLocation, useNavigate } from "react-router-dom";
const Reservation = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const { mainMenuVisible, setMainMenuVisible } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [data, setData] = useState(null);
  const [prevNumber, setPrevNumber] = useState(0);
  const [activeNumber, setActiveNumber] = useState(1);
  const [nextNumber, setNextNumber] = useState(2);

  // Router
  const { pathname } = useLocation();
  // scroll Set Reset
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // Get DAta
  const getReservasionDAta = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_RESERVATION_READ_ALL}?page_length=10&page_number=${page_number}`
        )
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                getReservasionDAta(page_number);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate]
  );

  // RenderingDAta
  useEffect(() => {
    setPrevNumber(0);
    setActiveNumber(1);
    setNextNumber(2);
    getReservasionDAta(1);
  }, [getReservasionDAta]);

  //   Local State
  const [reservationDetailVisible, setReservationDetailVisible] =
    useState(false);
  const [selectReservasion, setSelectReservasion] = useState(null);

  // Delete Coupon
  const removeData = (dataId) => {
    createRemoveAlert(
      "Delete reservation!",
      "Are you sure you want to delete the reservation?",
      "Yes, Remove",
      async () => {
        await axios
          .delete(`${process.env.REACT_APP_RESERVATION_DELETE}${dataId}`)
          .then((res) => {
            if (res.status === 200) {
              let arr = data.page_data;
              const update = arr.filter((item) => item.id !== dataId);
              setData({ ...data, page_data: update });
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };

  /* ------------------------------- Change Page ------------------------------ */
  const handleChange = (step) => {
    let newPrevNumber = prevNumber;
    let newActiveNumber = activeNumber;
    let newNextNumber = nextNumber;

    if (step === "prev" && prevNumber > 0) {
      newPrevNumber = prevNumber - 1;
      newActiveNumber = activeNumber - 1;
      newNextNumber = nextNumber - 1;
    } else {
      newPrevNumber = prevNumber + 1;
      newActiveNumber = activeNumber + 1;
      newNextNumber = nextNumber + 1;
    }

    setPrevNumber(newPrevNumber);
    setActiveNumber(newActiveNumber);
    setNextNumber(newNextNumber);

    getReservasionDAta(newActiveNumber);
  };

  return (
    <main>
      <section className="reservation">
        <div className="sub-header">
          <div className="container">
            <div className="row">
              <div className="info">
                <button
                  className=" burger-menu "
                  onClick={() => setMainMenuVisible(true)}
                >
                  <img src={menuIcon} alt="menu" />
                </button>
                <h2 className="caption">Reservation</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="reservation-info-area">
          <div className="container">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Hotel name</th>
                  <th>Room name</th>
                  <th>Customer</th>
                  <th>Period</th>
                  <th>Price</th>
                  <th>Edit </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.page_data.map((reservasion) => (
                    <tr
                      onClick={() => {
                        setReservationDetailVisible(true);
                        setSelectReservasion(reservasion);
                      }}
                      key={reservasion.id}
                    >
                      <td className="carImg">{reservasion.id}</td>
                      <td>{reservasion.reservation_associated_hotel_name}</td>
                      <td>
                        {reservasion.reservation_associated_suite.suite_associated_descriptions.map(
                          (suit) =>
                            suit.description_locale === "en" &&
                            suit.description_suite_name
                        )}
                      </td>
                      <td>
                        {reservasion.reservation_contact_first_name +
                          " " +
                          reservasion.reservation_contact_last_name}
                      </td>

                      <td>
                        {reservasion.reservation_from} /{" "}
                        {reservasion.reservation_until}
                      </td>
                      <td className="price">
                        {reservasion.reservation_total_price}{" "}
                        {reservasion.reservation_currency_code}
                      </td>
                      <td
                        className="edit"
                        onClick={(e) => {
                          e.stopPropagation();

                          removeData(reservasion.id);
                        }}
                      >
                        <button>
                          <img src={trash} alt="trash" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {data && (
              <div className="pagination">
                <button
                  className="btn prev"
                  onClick={() => handleChange("prev")}
                  disabled={!data.page_has_previous}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM17.685 19.5C18.12 19.935 18.12 20.655 17.685 21.09C17.46 21.315 17.175 21.42 16.89 21.42C16.605 21.42 16.32 21.315 16.095 21.09L10.8 15.795C10.365 15.36 10.365 14.64 10.8 14.205L16.095 8.91C16.53 8.475 17.25 8.475 17.685 8.91C18.12 9.345 18.12 10.065 17.685 10.5L13.185 15L17.685 19.5Z"
                      fill=" #AD471F"
                    />
                  </svg>
                </button>
                {prevNumber !== 0 && (
                  <button
                    className="btn prev-number"
                    onClick={() => handleChange("prev")}
                  >
                    {prevNumber}
                  </button>
                )}
                <button className="btn active">{activeNumber}</button>
                {data && data.page_has_next && (
                  <button
                    className="btn next-number"
                    onClick={() => handleChange("next")}
                  >
                    {nextNumber}
                  </button>
                )}

                {data && (
                  <button
                    className="btn next"
                    disabled={!data.page_has_next}
                    onClick={() => handleChange("next")}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM19.185 15.795L13.89 21.09C13.665 21.315 13.38 21.42 13.095 21.42C12.81 21.42 12.525 21.315 12.3 21.09C11.865 20.655 11.865 19.935 12.3 19.5L16.8 15L12.3 10.5C11.865 10.065 11.865 9.345 12.3 8.91C12.735 8.475 13.455 8.475 13.89 8.91L19.185 14.205C19.635 14.64 19.635 15.36 19.185 15.795Z"
                        fill=" #AD471F"
                      />
                    </svg>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className={
            reservationDetailVisible || mainMenuVisible
              ? "overlay-sub-menu active"
              : "overlay-sub-menu"
          }
          onClick={() => {
            mainMenuVisible
              ? setMainMenuVisible(false)
              : setReservationDetailVisible(false);
          }}
        ></div>
        <div
          className={
            reservationDetailVisible
              ? " isOpenMenu sidebar-reservatoin-menu scroll"
              : "sidebar-reservatoin-menu  scroll"
          }
        >
          <div className="head">
            <h4 className="caption">Collection title</h4>
            <div
              className="icon"
              onClick={() => setReservationDetailVisible(false)}
            >
              <img src={arrow} alt=" close" />
            </div>
          </div>
          <div className="body">
            <table className="submmenu-table">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{selectReservasion && selectReservasion.id}</td>
                </tr>
                <tr>
                  <th>Hotel name</th>
                  <td>
                    {selectReservasion &&
                      selectReservasion.reservation_associated_hotel_name}
                  </td>
                </tr>
                <tr>
                  <th>Room name</th>
                  <td>
                    {selectReservasion &&
                      selectReservasion.reservation_associated_suite.suite_associated_descriptions.map(
                        (suit) =>
                          suit.description_locale === "en" &&
                          suit.description_suite_name
                      )}
                  </td>
                </tr>
                <tr>
                  <th>Customer</th>
                  <td>
                    {selectReservasion &&
                      selectReservasion.reservation_contact_first_name +
                        " " +
                        selectReservasion.reservation_contact_last_name}
                  </td>
                </tr>
                <tr>
                  <th>Period</th>
                  <td>
                    {" "}
                    {selectReservasion &&
                      selectReservasion.reservation_from} /{" "}
                    {selectReservasion && selectReservasion.reservation_until}
                  </td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>
                    {" "}
                    {selectReservasion &&
                      selectReservasion.reservation_total_price}{" "}
                    {selectReservasion &&
                      selectReservasion.reservation_currency_code}
                  </td>
                </tr>
                <tr>
                  <th>Number</th>
                  <td>
                    {selectReservasion &&
                      selectReservasion.reservation_contact_phone}
                  </td>
                </tr>
                <tr>
                  <th>Mail</th>
                  <td>
                    {selectReservasion &&
                      selectReservasion.reservation_contact_email}
                  </td>
                </tr>

                {selectReservasion &&
                  selectReservasion.reservation_contact_country && (
                    <tr>
                      <th>Country</th>
                      <td>{selectReservasion.reservation_contact_country}</td>
                    </tr>
                  )}
                {selectReservasion && selectReservasion.reservation_comment && (
                  <tr>
                    <th>Comment</th>
                    <td>{selectReservasion.reservation_comment}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Reservation;
