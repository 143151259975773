import React, { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../utils/MainContext";

// Icon
import menuIcon from "../assets/images/icon/burger-menu.svg";
import edit from "../assets/images/icon/edit.svg";
import ExchangeRateSideBarMenu from "../components/side-bar-menu/ExchangeRateSideBarMenu";
import axios from "axios";


// Toastie
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";

const ExchangeRate = () => {
   // Navigate
   const navigate=useNavigate()

  // Global State
  const {
    mainMenuVisible,
    setMainMenuVisible,
    exchangeRateVisible,
    setExchangeRateVisible,

    // data
    exchangeRateData,setExchangeRateData
  } = useContext(MainContext);
  const { logout } = useAuth();


  // Local State
  // const [data, setData] = useState([]);
  const [selectData, setSelectData] = useState({
    currency_code:""
  });
  // Get DAta
  const getCurrencyData = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SITE_EXCHANGE_RATE_READ_ALL}?locale=en`
      )
      .then((res) => setExchangeRateData(res.data))
      .catch((err) => {
        





       if (err.response.status === 401) {
        axios
        .get(process.env.REACT_APP_REFRESH_TOKEN)
        .then((res) => {getCurrencyData()
        })
        .catch((err) =>navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }




      });
  }, [logout, navigate, setExchangeRateData]);

  // RenderingData
  useEffect(() => {
    getCurrencyData();
  }, [getCurrencyData]);

  return (
    <main>
      <section className="exchange-rate">
        <div className="sub-header">
          <div className="container">
            <div className="row">
              <div className="info">
                <button    className=" burger-menu " onClick={() => setMainMenuVisible(true)}>
                  <img src={menuIcon} alt="menu" />
                </button>
                <h2 className="caption">Exchange Rate</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="exchange-rate-body">
          <div className="container">
            <table className="table">
              <thead>
                <tr>
                  <th className="first">Code</th>
                  <th className="first">Name</th>
                  <th className="first">Price</th>

                  <th className="edit">Edit </th>
                </tr>
              </thead>
              <tbody>
                {exchangeRateData &&
                  exchangeRateData.map((currency) => (
                    <tr key={currency.id}>
                      <td className="first">{currency.currency_code}</td>
                      <td className="first">{currency.currency_name}</td>
                      <td className="first">{currency.currency_price}</td>

                      <td className="edit">
                        <div className="btn-area">
                          <button
                            onClick={() => {
                              setExchangeRateVisible(true);
                              setSelectData(currency);
                            }}
                          >
                            <img src={edit} alt="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={
            exchangeRateVisible || mainMenuVisible
              ? "overlay-sub-menu active"
              : "overlay-sub-menu"
          }
          onClick={() => {
            mainMenuVisible
              ? setMainMenuVisible(false)
              : setExchangeRateVisible(false);
          }}
        ></div>
        <ExchangeRateSideBarMenu selectData={selectData} />
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
      </section>
    </main>
  );
};

export default ExchangeRate;
