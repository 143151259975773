import React, { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../../utils/MainContext";
import { createRemoveAlert } from "../../utils/SweetAlert";
import axios from "axios";

import trash from "../../assets/images/icon/trash.svg";
import add from "../../assets/images/icon/add-plus.svg";
import edit from "../../assets/images/icon/edit.svg";
import SocialMediaSideBar from "../../components/side-bar-menu/SocialMediaSideBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const SocialMedia = () => {
  // Global State
  const {
    socialMediaData,
    setSocialMediaData,
    socialMediaSideBarVisible,
    setSocialMediaSideBarVisible,
    setSelectSocialMedia,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Local State
  const [type, setType] = useState("");
  // Delete Coupon

  const getSocialMediaData = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_SITE_SOCIAL_MEDIA_READ)
      .then((res) => setSocialMediaData(res.data))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getSocialMediaData();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [navigate, setSocialMediaData, logout]);

  useEffect(() => {
    getSocialMediaData();
  }, [getSocialMediaData]);

  // Delete
  const removeData = (dataId, banner_name) => {
    createRemoveAlert(
      "Delete Social Media!",
      `Are you sure you want to delete the ${banner_name} Social Media?`,
      "Yes, Remove",
      async () => {
        await axios
          .delete(`${process.env.REACT_APP_SITE_SOCIAL_MEDIA_DELETE}/${dataId}`)
          .then((res) => {
            if (res.status === 200) {
              const update = socialMediaData.filter(
                (item) => item.id !== dataId
              );
              setSocialMediaData(update);
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId, banner_name);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };
  return (
    <div className="social-media boxes-style">
      <div className="edit-area">
        <h6 className="title">Add Social Media</h6>
        <button
          className="add-btn"
          onClick={() => {
            setSocialMediaSideBarVisible(true);
            setType("create");
            setSelectSocialMedia({
              social_media_path: "",
              social_url: "",
              id: "",
            });
          }}
        >
          Add <img src={add} alt="add" />
        </button>
      </div>
      <div className="social-media-body">
        <table className="table">
          <thead>
            <tr>
              <th className="first">ID</th>
              <th className="second">Social Media Type</th>
              <th className="edit">Edit && Delete </th>
            </tr>
          </thead>
          <tbody>
            {socialMediaData.length !== 0 &&
              socialMediaData.map((social) => (
                <tr key={social.id + "t"}>
                  <td className="first">{social.id}</td>
                  <td className="second">{social.social_media_path}</td>

                  <td className="edit">
                    <div className="btn-area">
                      <button
                        onClick={() =>
                          removeData(social.id, social.social_media_path)
                        }
                      >
                        <img src={trash} alt="delete" />
                      </button>
                      <button
                        onClick={() => {
                          setSocialMediaSideBarVisible(true);
                          setSelectSocialMedia(social);
                          setType("edit");
                        }}
                      >
                        <img src={edit} alt="edit" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div
        className={
          socialMediaSideBarVisible
            ? "overlay-sub-menu active"
            : "overlay-sub-menu"
        }
        onClick={() => {
          setSocialMediaSideBarVisible(false);
          setSelectSocialMedia({
            social_media_path: "",
            social_url: "",
            id: "",
          });
        }}
      ></div>
      <SocialMediaSideBar type={type} />
    </div>
  );
};

export default SocialMedia;
