// React
import { useCallback, useContext, useEffect, useState } from "react";

// Toastie
import { notify } from "../../utils/ToastIfyNonfoction";

// Icon
import axios from "axios";

import { MainContext } from "../../utils/MainContext";
import { useNavigate } from "react-router-dom";
import Editor from "../../components/Editor";
import { useAuth } from "../../utils/AuthContext";
const CustomPageDetailDescription = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    homeActiveTab,
    selectCustomPage,
    customPageSelectLocale,
    customPageEditorValue,
    setCustomPageEditorValue,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [descriptionData, setDescriptionData] = useState([]);
  const [renderData, setRenderData] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    if (homeActiveTab === "Custom Page Detail") {
      setRenderData(true);
    }
  }, [homeActiveTab]);

  // Yup schema

  const getDescriptionData = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SITE_CUSTOM_PAGE_DESCRIPTION_READ}/${selectCustomPage.id}`
      )
      .then((res) => {
        setDescriptionData(res.data);
        res.data.map((item) => {
          if (item.description_locale === customPageSelectLocale) {
            setCustomPageEditorValue(item.description_text);
          }
          return true;
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getDescriptionData();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [
    customPageSelectLocale,
    logout,
    navigate,
    selectCustomPage.id,
    setCustomPageEditorValue,
  ]);

  useEffect(() => {
    if (renderData) {
      setErrorText("");
      setCustomPageEditorValue("");
      getDescriptionData();
      setRenderData(false);
    }
  }, [getDescriptionData, renderData, setCustomPageEditorValue]);

  const editDescription = async () => {
    const body = new FormData();

    const checkVisibility = () => {
      if (descriptionData.length === 0) {
        return true;
      } else {
        for (const item of descriptionData) {
          if (item.description_locale === customPageSelectLocale) {
            return false;
          }
        }
        return true;
      }
    };
    if (checkVisibility()) {
      body.append("description_text", customPageEditorValue);
      body.append("description_associated_page", selectCustomPage.id);
      body.append("description_locale", customPageSelectLocale);

      try {
        const res = await axios.post(
          process.env.REACT_APP_SITE_CUSTOM_PAGE_DESCRIPTION_CREATE,
          body
        );
        if (res) {
          notify("Custom Page Description  Create!");
          setDescriptionData([...descriptionData, res.data]);
        }
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              editDescription();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      }
    } else {
      let descriptionId = descriptionData.find(
        (item) => item.description_locale === customPageSelectLocale && item.id
      );
      body.append("description_text", customPageEditorValue);
      body.append("description_associated_page", selectCustomPage.id);
      body.append("description_locale", customPageSelectLocale);
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SITE_CUSTOM_PAGE_DESCRIPTION_UPDATE}/${descriptionId.id}`,
          body
        );

        setDescriptionData((prevBannerData) =>
          prevBannerData.map((description) =>
            description.id === res.data.id ? res.data : description
          )
        );

        notify("Custom Page Description Update!");
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              editDescription();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      }
    }
  };

  return (
    <div className="description-acordion-area">
      <div className="accordion-group  active">
        <div className="axordion-head ">
          <h3 className="acordion-caption">
            {customPageSelectLocale === "az"
              ? "Azerbaijan"
              : customPageSelectLocale === "en"
              ? "English"
              : customPageSelectLocale === "ru"
              ? "Russian"
              : null}
          </h3>
        </div>
        <form className="acrodion-body" style={{ height: "auto" }}>
          <div className="form-group">
            <Editor locale={""} type={""} />
          </div>
          <div className="form-footer">
            <div className="handle-area">
              <p className="error-text"> {errorText}</p>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  editDescription();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomPageDetailDescription;
