import { useCallback, useContext, useEffect } from "react";

// Utils
import { MainContext } from "../utils/MainContext";

// Axios
import axios from "axios";

// Toastie
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icon
import menuIcon from "../assets/images/icon/burger-menu.svg";
import add from "../assets/images/icon/add-plus.svg";
import { useNavigate } from "react-router-dom";
import HotelAtributeGroupSideBar from "./attribute-section/HotelAtributeGroupSideBar";
import AttributePageSideBar from "./attribute-section/AttributePageSideBar";
import HotelAttribute from "./attribute-section/HotelAttribute";
import SuitAtribute from "./attribute-section/SuitAtribute";
import HotelAttribueGroup from "./attribute-section/HotelAttribueGroup";
import SuitAttributeGroup from "./attribute-section/SuitAttributeGroup";
import { useAuth } from "../utils/AuthContext";

const Atributes = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    mainMenuVisible,
    setMainMenuVisible,

    setAttributeData,
    setSelectAttribute,

    atributePageSideBarVisible,
    setAtributePageSideBarVisible,
    atributeActiveTab,
    setAtributeActiveTab,
    setAttributeProccesType,
    // Group
    atributeGroupSideBarVisible,
    setAtributeGroupSideBarVisible,
    setSelectAttributeGroup,

    // Pagination
    attributeprevNumber,
    setAttributePrevNumber,
    attributeActiveNumber,
    setAttributeActiveNumber,
    attributeNextNumber,
    setAttributeNextNumber,
    attributePaginationData,
    setAttributePaginationData,
    searchHotelAttributeGroup,
    searchSuitAttributeGroup,
    getAllHottelAtributeGroup,
    getAllSuitAtributeGroup,

    // Function
    getHotelAttribute,
    getSuitAttribute,
  } = useContext(MainContext);
  const { logout } = useAuth();

  const tabList = [
    { id: 1, tab: "Hotel Attributes" },
    { id: 2, tab: "Hotel Attribute Groups" },
    { id: 3, tab: "Suit Attributes" },
    { id: 4, tab: "Suit Attribute Groups" },
  ];

  // Search Percentage Night Data
  const searchSuitAttribute = useCallback(
    async (value) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_HOTEL_SUIT_ATTRIBUTE_SEARCH}${value}`
        )
        .then((res) => {
          setAttributeData(res.data);
          setAttributePaginationData(null);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                searchSuitAttribute(value);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setAttributeData, setAttributePaginationData]
  );

  // Search Free Night Data
  const searchHotelAttribute = useCallback(
    async (value) => {
      await axios
        .get(`${process.env.REACT_APP_SITE_HOTEL_ATTRIBUTE_SEARCH}${value}`)
        .then((res) => {
          setAttributeData(res.data);
          setAttributePaginationData(null);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                searchHotelAttribute(value);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setAttributeData, setAttributePaginationData]
  );

  // Controller
  const handleController = async (e) => {
    if (e.target.value.trim().length > 2) {
      if (atributeActiveTab === "Suit Attributes") {
        searchSuitAttribute(e.target.value);
      } else if (atributeActiveTab === "Hotel Attributes") {
        searchHotelAttribute(e.target.value);
      } else if (atributeActiveTab === "Hotel Attribute Groups") {
        searchHotelAttributeGroup(e.target.value);
      } else if (atributeActiveTab === "Suit Attribute Groups") {
        searchSuitAttributeGroup(e.target.value);
      } else {
        return null;
      }
    } else {
      if (atributeActiveTab === "Suit Attributes") {
        setAttributePrevNumber(
          attributePaginationData ? attributeprevNumber : 0
        );
        setAttributeActiveNumber(
          attributePaginationData ? attributeActiveNumber : 1
        );
        setAttributeNextNumber(
          attributePaginationData ? attributeNextNumber : 2
        );
        getSuitAttribute(attributePaginationData ? attributeActiveNumber : 1);
      } else if (atributeActiveTab === "Hotel Attributes") {
        setAttributePrevNumber(
          attributePaginationData ? attributeprevNumber : 0
        );
        setAttributeActiveNumber(
          attributePaginationData ? attributeActiveNumber : 1
        );
        setAttributeNextNumber(
          attributePaginationData ? attributeNextNumber : 2
        );
        getHotelAttribute(attributePaginationData ? attributeActiveNumber : 1);
      } else if (atributeActiveTab === "Hotel Attribute Groups") {
        getAllHottelAtributeGroup();
      } else if (atributeActiveTab === "Suit Attribute Groups") {
        getAllSuitAtributeGroup();
      } else {
        return null;
      }
    }
  };

  useEffect(() => {
    setAtributeActiveTab("Hotel Attributes");
    setAtributePageSideBarVisible(false);
    setAttributeProccesType("");
    setAtributeGroupSideBarVisible(false);
  }, [
    setAtributeActiveTab,
    setAtributeGroupSideBarVisible,
    setAtributePageSideBarVisible,
    setAttributeProccesType,
  ]);

  return (
    <main>
      <section className="discount">
        <div className="sub-header">
          <div className="container">
            <div className="row">
              <div className="info">
                <button
                  className=" burger-menu "
                  onClick={() => setMainMenuVisible(true)}
                >
                  <img src={menuIcon} alt="menu" />
                </button>
                <h2 className="caption">Attributes</h2>
              </div>
              <div className="search-form">
                <input
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={handleController}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="discount-header">
          <div className="container">
            <ul className="tab-list">
              {atributeActiveTab !== "Hotel Add" ? (
                tabList.map((menuTab) => (
                  <li
                    className={
                      atributeActiveTab === menuTab.tab ||
                      (atributeActiveTab === "Custom Page Detail" &&
                        menuTab.tab === "Custom Webiste Pages")
                        ? "tab-item active"
                        : "tab-item"
                    }
                    onClick={() => setAtributeActiveTab(menuTab.tab)}
                    key={menuTab.id}
                  >
                    {atributeActiveTab === "Custom Page Detail" &&
                    menuTab.tab === "Custom Webiste Pages"
                      ? "Custom Page Detail"
                      : menuTab.tab}
                  </li>
                ))
              ) : (
                <li className="tab-item active">{atributeActiveTab}</li>
              )}
            </ul>
            {atributeActiveTab === "Hotel Attributes" ? (
              <>
                <div className="edit-area">
                  <h6 className="title">Add Group</h6>
                  <button
                    className="add-btn"
                    onClick={() => {
                      setAtributeGroupSideBarVisible(true);
                      setAttributeProccesType("create");
                    }}
                  >
                    Add <img src={add} alt="add" />
                  </button>
                </div>
                <div className="edit-area">
                  <h6 className="title">Add Attribute</h6>
                  <button
                    className="add-btn"
                    onClick={() => {
                      setAtributePageSideBarVisible(true);
                      setAttributeProccesType("create");
                    }}
                  >
                    Add <img src={add} alt="add" />
                  </button>
                </div>
              </>
            ) : atributeActiveTab === "Suit Attributes" ? (
              <>
                <div className="edit-area">
                  <h6 className="title">Add Group</h6>
                  <button
                    className="add-btn"
                    onClick={() => {
                      setAtributeGroupSideBarVisible(true);
                      setAttributeProccesType("create");
                    }}
                  >
                    Add <img src={add} alt="add" />
                  </button>
                </div>
                <div className="edit-area">
                  <h6 className="title">Add Attribute</h6>
                  <button
                    className="add-btn"
                    onClick={() => {
                      setAtributePageSideBarVisible(true);
                      setAttributeProccesType("create");
                    }}
                  >
                    Add <img src={add} alt="add" />
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="discount-info-area">
          {/*  */}
          {atributeActiveTab === "Hotel Attributes" ? (
            <HotelAttribute />
          ) : atributeActiveTab === "Hotel Attribute Groups" ? (
            <HotelAttribueGroup />
          ) : atributeActiveTab === "Suit Attributes" ? (
            <SuitAtribute />
          ) : atributeActiveTab === "Suit Attribute Groups" ? (
            <SuitAttributeGroup />
          ) : null}
        </div>
        <div
          className={
            atributePageSideBarVisible ||
            mainMenuVisible ||
            atributeGroupSideBarVisible
              ? "overlay-sub-menu active"
              : "overlay-sub-menu"
          }
          onClick={() => {
            if (mainMenuVisible) {
              setMainMenuVisible(false);
            } else if (atributePageSideBarVisible) {
              setAtributePageSideBarVisible(false);
              setSelectAttribute({
                attribute_associated_group: "",
                attribute_name_az: "",
                attribute_name_en: "",
                attribute_name_ru: "",
                id: "",
              });
            } else {
              setAtributeGroupSideBarVisible(false);
              setSelectAttributeGroup({
                attribute_group: "",
                attribute_group_name_az: "",
                attribute_group_name_en: "",
                attribute_group_name_ru: "",
                id: "",
              });
            }
          }}
        ></div>
        <AttributePageSideBar />
        <HotelAtributeGroupSideBar />

        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Bounce}
        />
      </section>
    </main>
  );
};

export default Atributes;
