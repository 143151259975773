import CustomPageDetailDescription from "./CustomPageDetailDescription";
import CustomPageSeo from "./CustomPageSeo";

const CustomPageDetail = () => {
  return (
    <div className="custom-page-detail">
      <div className="container">
        <h4 className="custom-page-caption">Description</h4>
        <CustomPageDetailDescription />
        <h4 className="custom-page-caption">Seo</h4>
        <CustomPageSeo/>
      </div>
    </div>
  );
};

export default CustomPageDetail;
