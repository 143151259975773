import { useContext, useEffect } from "react";

// Utils
import { MainContext } from "../../utils/MainContext";
import { createRemoveAlert } from "../../utils/SweetAlert";

// Axios
import axios from "axios";

// Toastie
import "react-toastify/dist/ReactToastify.css";

// Icon
import trash from "../../assets/images/icon/trash.svg";
import edit from "../../assets/images/icon/edit.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const SuitAttributeGroup = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    // Group
    setAtributeGroupSideBarVisible,

    atributeActiveTab,
    setAttributeProccesType,
    setSelectAttributeGroup,

    // Pagination
    setAttributePrevNumber,
    setAttributeActiveNumber,
    setAttributeNextNumber,

    // Data
    attributeGroupData,
    setAttributeGroupData,

    // Funtion
    getHotelAttribute,

    getAllSuitAtributeGroup,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // RenderingDAta
  useEffect(() => {
    if (atributeActiveTab === "Suit Attribute Groups") {
      setAttributeGroupData([]);
      getAllSuitAtributeGroup();
    }
  }, [
    atributeActiveTab,
    getAllSuitAtributeGroup,
    getHotelAttribute,
    setAttributeActiveNumber,
    setAttributeGroupData,
    setAttributeNextNumber,
    setAttributePrevNumber,
  ]);

  // Delete Coupon
  const removeData = (dataId, attribute_group) => {
    createRemoveAlert(
      "Delete Attribute!",
      `Are you sure you want to delete the ${attribute_group} Attribute?`,
      "Yes, Remove",
      async () => {
        await axios
          .delete(
            `${process.env.REACT_APP_SITE_SUIT_ATTRIBUTE_GROUP_DELETE}/${dataId}`
          )
          .then((res) => {
            if (res.status === 200) {
              const updateCoupon = attributeGroupData.filter(
                (item) => item.id !== dataId
              );
              setAttributeGroupData(updateCoupon);
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId, attribute_group);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };

  return (
    <div className="container">
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Group label</th>
            <th>Group Name (Az)</th>
            <th>Group Name (En)</th>
            <th>Group Name (Ru)</th>
            <th>Edit && Delete </th>
          </tr>
        </thead>
        <tbody>
          {attributeGroupData.length !== 0 &&
            attributeGroupData.map((item) => (
              <tr key={item.id}>
                <td className="carImg">{item.id}</td>
                <td>{item.attribute_group}</td>
                <td>{item.attribute_group_name_az}</td>
                <td>{item.attribute_group_name_en}</td>
                <td>{item.attribute_group_name_ru}</td>

                <td className="edit">
                  <div className="btn-area">
                    <button
                      onClick={() => removeData(item.id, item.attribute_group)}
                    >
                      <img src={trash} alt="trash" />
                    </button>
                    <button
                      onClick={() => {
                        setAttributeProccesType("edit");
                        setSelectAttributeGroup(item);
                        setAtributeGroupSideBarVisible(true);
                      }}
                    >
                      <img src={edit} alt="edit" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SuitAttributeGroup;
