import React, { useContext } from "react";
import { MainContext } from "../utils/MainContext";

// Icon
import menuIcon from "../assets/images/icon/burger-menu.svg";
import arrow from "../assets/images/icon/arrow-left-white.svg";

// Components
import HotelDetailGeneral from "./hotel-detail-section/HotelDetailGeneral";
import HotelDetailDescription from "./hotel-detail-section/HotelDetailDescription";
import HotelDetailRooms from "./hotel-detail-section/HotelDetailRooms";
import HotelDetailRoomDetail from "./hotel-detail-section/HotelDetailRoomDetail";
import HotelDEtailPriceListSchedule from "./hotel-detail-section/HotelDEtailPriceListSchedule";
import HotelDEtailPriceList from "./hotel-detail-section/HotelDEtailPriceList";
import HotelDetailSeo from "./hotel-detail-section/HotelDetailSeo";
import HotelDetailRoomAdd from "./hotel-detail-section/HotelDetailRoomAdd";
import { useNavigate, useParams } from "react-router-dom";
// Toastie
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const HotelDetail = () => {
  // Global State
  const {
    mainMenuVisible,
    setMainMenuVisible,
    hotelDetailActiveTab,
    setHotelDetailActiveTab,
    hotelData,
    roomData,
  } = useContext(MainContext);

  // Local State && DataList
  const tabList = [
    { id: 1, tab: "General" },
    { id: 2, tab: "Description" },
    { id: 3, tab: "Rooms" },
    { id: 4, tab: "SEO" },
    { id: 5, tab: "Price list" },
    { id: 6, tab: "Price list schedule" },
  ];

  // Params
  const { hotelId } = useParams();

  // Navigate
  const navigate = useNavigate();

  return (
    <main>
      <section className="hotel-detail">
        <div className="sub-header">
          <div className="container">
            <div className="row">
              <div className="info">
                <button
                  className=" burger-menu "
                  onClick={() => setMainMenuVisible(true)}
                >
                  <img src={menuIcon} alt="menu" />
                </button>
                <button
                  className="back-btn"
                  onClick={() => {
                    if (
                      hotelDetailActiveTab === "Room Detail" ||
                      hotelDetailActiveTab === "Room Add"
                    ) {
                      setHotelDetailActiveTab("Rooms");
                    } else {
                      navigate("/hotel");
                    }
                  }}
                >
                  <img src={arrow} alt="back" />
                  Back
                </button>
                <h2 className="caption">
                  {" "}
                  {/* {hotelDetailActiveTab === "Room Detail"
                    ? roomData?.suite_associated_descriptions?.length !== 0
                      ? roomData.suite_associated_descriptions.map(
                          (data) =>
                            data.description_locale === "en" &&
                            data.description_suite_name
                        )
                      : `Suite : ${roomData.id} (Please Add Room Name)`
                    : hotelDetailActiveTab !== "Hotel Add"
                    ? hotelData?.hotel_name + " / Hotel"
                    : ""}{" "} */}
                  {/* 






                     */}
                  {/* {hotelDetailActiveTab === "Room Detail" ||
                  hotelDetailActiveTab === "Room Description Detail"
                    ? roomData
                      ? roomData.suite_associated_descriptions?.length !== 0
                        ? roomData.suite_associated_descriptions.some(
                            (data) => data.description_locale === "en"
                          )
                          ? roomData.suite_associated_descriptions.map((data) =>
                              data.description_locale === "en"
                                ? data.description_suite_name
                                : ""
                            )
                          : `Suite ID: ${roomData.id} (Please Add Room Name)`
                        : `Suite: ${roomData.id} (Please Add Room Name)`
                      : "Loading..."
                    : hotelDetailActiveTab !== "Hotel Add"
                    ? hotelData?.hotel_name + " / Hotel"
                    : ""} */}
                  {/* new */}
                  {/* {hotelDetailActiveTab === "Room Detail" ||
                  hotelDetailActiveTab === "Room Description Detail"
                    ? roomData
                      ? roomData.suite_associated_descriptions?.length !== 0
                        ? roomData.suite_associated_descriptions.some(
                            (data) => data.description_locale === "en"
                          )
                          ? `${
                              hotelData?.hotel_name
                            } / ${roomData.suite_associated_descriptions.map(
                              (data) =>
                                data.description_locale === "en"
                                  ? data.description_suite_name
                                  : ""
                            )}`
                          : `${hotelData?.hotel_name} / Suite ID: ${roomData.id} (Please Add Room Name)`
                        : `${hotelData?.hotel_name} / Suite: ${roomData.id} (Please Add Room Name)`
                      : "Loading..."
                    : hotelDetailActiveTab !== "Hotel Add"
                    ? `${hotelData?.hotel_name} / Hotel`
                    : ""} */}
                  {/* last */}
                  {hotelDetailActiveTab === "Room Detail" ||
                  hotelDetailActiveTab === "Room Description Detail"
                    ? roomData
                      ? roomData.suite_associated_descriptions?.length !== 0
                        ? roomData.suite_associated_descriptions.some(
                            (data) => data.description_locale === "en"
                          )
                          ? `${
                              hotelData?.hotel_name
                            } / ${roomData.suite_associated_descriptions
                              .filter(
                                (data) =>
                                  data.description_locale === "en" &&
                                  data.description_suite_name
                              )
                              .map((data) => data.description_suite_name)
                              .join(", ")}`
                          : `${hotelData?.hotel_name} / Suite ID: ${roomData.id} (Please Add Room Name)`
                        : `${hotelData?.hotel_name} / Suite: ${roomData.id} (Please Add Room Name)`
                      : "Loading..."
                    : hotelDetailActiveTab !== "Hotel Add"
                    ? `${hotelData?.hotel_name} / Hotel`
                    : ""}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="hotel-detail-header">
          <div className="container">
            <ul className="tab-list">
              {hotelDetailActiveTab !== "Hotel Add" ? (
                tabList.map((menuTab) => (
                  <li
                    className={
                      hotelDetailActiveTab === menuTab.tab ||
                      (hotelDetailActiveTab === "Room Detail" &&
                        menuTab.tab === "Rooms") ||
                      (hotelDetailActiveTab === "Room Add" &&
                        menuTab.tab === "Rooms")
                        ? "tab-item active"
                        : "tab-item"
                    }
                    // className={
                    //   hotelDetailActiveTab === menuTab.tab
                    //     ? "tab-item active"
                    //     : "tab-item"
                    // }
                    onClick={() => setHotelDetailActiveTab(menuTab.tab)}
                    key={menuTab.id}
                  >
                    {hotelDetailActiveTab === "Room Detail" &&
                    menuTab.tab === "Rooms"
                      ? "Room Detail"
                      : hotelDetailActiveTab === "Room Add" &&
                        menuTab.tab === "Rooms"
                      ? "Room Add"
                      : menuTab.tab}
                  </li>
                ))
              ) : (
                <li className="tab-item active">{hotelDetailActiveTab}</li>
              )}
            </ul>
          </div>
        </div>
        <div
          className={
            mainMenuVisible ? "overlay-sub-menu active" : "overlay-sub-menu"
          }
          onClick={() => {
            mainMenuVisible && setMainMenuVisible(false);
          }}
        ></div>
        <div className="hotel-detail-body">
          {hotelDetailActiveTab === "General" ||
          hotelDetailActiveTab === "Hotel Add" ? (
            <HotelDetailGeneral />
          ) : hotelDetailActiveTab === "Description" ? (
            <HotelDetailDescription hotelId={hotelId} />
          ) : hotelDetailActiveTab === "Rooms" ? (
            <HotelDetailRooms />
          ) : hotelDetailActiveTab === "Room Add" ? (
            <HotelDetailRoomAdd />
          ) : hotelDetailActiveTab === "Room Detail" ? (
            <HotelDetailRoomDetail />
          ) : hotelDetailActiveTab === "SEO" ? (
            <HotelDetailSeo />
          ) : hotelDetailActiveTab === "Price list" ? (
            <HotelDEtailPriceList hotelId={hotelId} />
          ) : hotelDetailActiveTab === "Price list schedule" ? (
            <HotelDEtailPriceListSchedule />
          ) : null}
        </div>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Bounce}
        />
      </section>
    </main>
  );
};

export default HotelDetail;
