// React
import { useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Icon
import arrow from "../../assets/images/icon/arrow-left.svg";
import arrowDown from "../../assets/images/icon/arrow-down.svg";
import addFile from "../../assets/images/icon/add-btn.svg";

// Axios
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import HotelEditor from "../../pages/hotel-detail-section/HotelEditor";
import { useAuth } from "../../utils/AuthContext";

const CommentSideBar = ({ type }) => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    commentSideBarVisible,
    setCommentSideBarVisible,
    selectComment,
    setSelectComment,
    setCommentData,
    hotelDescriptionEditorValue,
    setHotelDescriptionEditorValue,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [errorText, setErrorText] = useState("");
  const [dropDownVisible, setDropDownVisible] = useState(false);

  // img
  const [errorImg, setErrorImg] = useState(false);
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");

  //Photo Choose
  const handleImg = (e) => {
    // Check if files were selected
    if (!e.target.files || e.target.files.length === 0) {
      console.error("No file selected.");
      return;
    }
    if (errorImg) {
      setErrorImg(false);
    }

    let file = e.target.files[0];
    setImage(file);

    let reader = new FileReader();

    reader.onload = () => {
      setPreview(reader.result);
    };

    reader.onerror = (err) => {
      console.error("FileReader error:", err);
    };

    reader.readAsDataURL(file);
  };

  const locale = [
    { id: 1, local: "az" },
    { id: 2, local: "en" },
    { id: 2, local: "ru" },
  ];

  // Yup schema
  const schema = object({
    review_reviewer_name: string().required().trim(),
    review_star_rating: string().required().trim(),
    review_reviewer_origin: string().required().trim(),
    review_locale: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setPreview("");
    setImage("");
    setErrorText("");
    setErrorImg(false);
    setHotelDescriptionEditorValue("");
    if (commentSideBarVisible && type === "edit") {
      setImage(selectComment.review_reviewer_image);
      setValue("review_reviewer_name", selectComment.review_reviewer_name);
      setValue("review_star_rating", selectComment.review_star_rating);
      setValue("review_reviewer_origin", selectComment.review_reviewer_origin);
      setHotelDescriptionEditorValue(selectComment.review_text);
      setValue("review_locale", selectComment.review_locale);
    } else {
      reset();
    }
  }, [
    commentSideBarVisible,
    reset,
    selectComment.review_locale,
    selectComment.review_reviewer_image,
    selectComment.review_reviewer_name,
    selectComment.review_reviewer_origin,
    selectComment.review_star_rating,
    selectComment.review_text,
    setHotelDescriptionEditorValue,
    setValue,
    type,
  ]);

  //Setting Form Submit
  const commentRequest = async (data) => {
    const body = new FormData();
    body.append("review_reviewer_image", image);
    body.append("review_reviewer_name", data.review_reviewer_name);
    body.append("review_star_rating", data.review_star_rating);
    body.append("review_reviewer_origin", data.review_reviewer_origin);
    body.append("review_text", hotelDescriptionEditorValue);
    body.append("review_locale", data.review_locale);

    if (type === "create") {
      if (image === "" || preview === "") {
        setErrorImg(true);
      } else {
        try {
          const res = await axios.post(
            process.env.REACT_APP_SITE_HOTEL_REVIEW_CREATE,
            body
          );
          setCommentData((prevState) => ({
            ...prevState,
            page_data: [res.data, ...prevState.page_data],
          }));
          setSelectComment({
            review_reviewer_name: "",
            review_star_rating: "",
            review_reviewer_origin: "",
            review_text: "",
            review_locale: "",
          });
          reset();
          notify("Comment Create!");
          setCommentSideBarVisible(false);
        } catch (err) {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                commentRequest(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response.data.errors);
          }
        }
      }
    } else {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SITE_HOTEL_REVIEW_UPTADE}/${selectComment.id}`,
          body
        );

        setCommentData((prevState) => ({
          ...prevState,
          page_data: prevState.page_data.map((item) =>
            item.id === res.data.id ? res.data : item
          ),
        }));
        setSelectComment({
          review_reviewer_name: "",
          review_star_rating: "",
          review_reviewer_origin: "",
          review_text: "",
          review_locale: "",
        });
        notify("Comment Update!");
        setCommentSideBarVisible(false);
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              commentRequest(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response.data.errors);
        }
      }
    }
  };

  return (
    <div
      className={
        commentSideBarVisible
          ? " isOpenMenu comment-sidebar-menu scroll "
          : "comment-sidebar-menu scroll  "
      }
    >
      <div className="head ">
        <h4 className="caption">Comment</h4>
        <div
          className="icon"
          onClick={() => {
            setCommentSideBarVisible(false);
            setSelectComment({
              id: "",
              review_reviewer_image: "",
              review_reviewer_name: "",
              review_star_rating: "",
              review_reviewer_origin: "",
              review_text: "",
              review_locale: "",
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body ">
        <form
          action=""
          className="comment-form"
          onSubmit={handleSubmit(commentRequest)}
        >
          <div className="form-group">
            <label htmlFor="review_reviewer_name" className="inp-caption">
              Name
            </label>
            <input
              type="text"
              className={errors.review_reviewer_name ? "inp error" : "inp"}
              id="review_reviewer_name"
              name="review_reviewer_name"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("review_reviewer_name", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>

          <div className="side-bar-divader"></div>
          <div
            className={`form-group ${
              dropDownVisible ? "select open" : "select"
            } `}
          >
            <label htmlFor="review_locale" className="inp-caption">
              Locale
            </label>
            <input
              type="text"
              id="review_locale"
              name="review_locale"
              readOnly
              placeholder=" Select Locale"
              className={
                errors.review_locale ? "inp select-inp error" : "inp select-inp"
              }
              onClick={() =>
                setDropDownVisible(dropDownVisible ? null : "pricing_types")
              }
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("review_locale")}
            />
            <div
              className="icon"
              onClick={() =>
                setDropDownVisible(dropDownVisible ? null : "pricing_types")
              }
            >
              <img src={arrowDown} alt="open-close-dropdown" />
            </div>
            <div
              className="select-area"
              style={{
                height: dropDownVisible ? 120 : 0,
              }}
            >
              <ul className="select-list scroll">
                {locale.map((item) => (
                  <li
                    className="select-item"
                    onClick={(e) => {
                      e.target.parentElement.parentElement.parentElement.children[1].classList.remove(
                        "error"
                      );
                      setValue("review_locale", item.local);
                      setDropDownVisible(false);
                    }}
                    key={item.id}
                  >
                    {item.local}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="review_star_rating" className="inp-caption">
              Rating
            </label>
            <input
              type="number"
              className={errors.review_star_rating ? "inp error" : "inp"}
              id="review_star_rating"
              name="review_star_rating"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("review_star_rating", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="review_reviewer_origin" className="inp-caption">
              Origin
            </label>
            <input
              type="text"
              className={errors.review_reviewer_origin ? "inp error" : "inp"}
              id="review_reviewer_origin"
              name="review_reviewer_origin"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("review_reviewer_origin", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            />
          </div>
          <div className="side-bar-divader"></div>
          <div className="form-group">
            <label htmlFor="review_text" className="inp-caption">
              Text
            </label>
            <HotelEditor type={"comment"} />
            {/* <textarea
              type="number"
              className={errors.review_text ? "inp error scroll" : "inp scroll"}
              id="review_text"
              name="review_text"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("review_text", {
                onChange: (e) => {
                  errorText !== "" && setErrorText("");
                },
              })}
            /> */}
          </div>

          <div className="side-bar-divader"></div>
          <div className="image-area">
            <h2 className="main-caption">Profile Photo</h2>
            <div className="form-group">
              <label
                htmlFor="add-main-photo"
                className={errorImg ? "add error" : "add "}
              >
                <div className="icon">
                  <img src={addFile} alt="addfile" />
                </div>
                Choose file
              </label>
              <input
                type="file"
                name="add-main-photo"
                id="add-main-photo"
                onChange={handleImg}
              />
            </div>
          </div>
          {(image || preview) && (
            <div className="folder-img">
              <img
                src={
                  preview ? preview : `${process.env.REACT_APP_IMAGE}${image}`
                }
                alt="img"
              />
            </div>
          )}

          <div className="form-footer">
            <button>{type === "create" ? "Create" : "Save"}</button>
            <p className="error-text">{errorText}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommentSideBar;
