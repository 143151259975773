import React, { useCallback, useContext, useEffect, useState } from "react";

// Icon
import down from "../../assets/images/icon/arrow-down.svg";

import { MainContext } from "../../utils/MainContext";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import Editor from "../../components/Editor";
import { useAuth } from "../../utils/AuthContext";

const NaftalanDiseaseHistory = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    homeActiveTab,
    naftalanHistoryEditorValue,
    setNaftalanHistoryEditorValue,
  } = useContext(MainContext);

  const { logout } = useAuth();

  // Local State
  const [errorText, setErrorText] = useState("");
  const [accordionVisible, setAccordionVisible] = useState("az");

  const getNaftalanHistoryData = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_SITE_NAFTALAN_DISEASE_HISTORY_READ)
      .then((res) => {
        setNaftalanHistoryEditorValue({
          az: res.data.history_text_az,
          en: res.data.history_text_en,
          ru: res.data.history_text_ru,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && getNaftalanHistoryData())
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [logout, navigate, setNaftalanHistoryEditorValue]);

  useEffect(() => {
    if (homeActiveTab === "Naftalan Diseases Page") {
      setErrorText();
      setNaftalanHistoryEditorValue({ az: "", en: "", ru: "" });
      getNaftalanHistoryData();
    }
  }, [getNaftalanHistoryData, homeActiveTab, setNaftalanHistoryEditorValue]);

  const editDescription = async (data) => {
    const formData = new FormData();

    formData.append("history_text_az", naftalanHistoryEditorValue.az);
    formData.append("history_text_en", naftalanHistoryEditorValue.en);
    formData.append("history_text_ru", naftalanHistoryEditorValue.ru);
    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    await axios
      .put(process.env.REACT_APP_SITE_NAFTALAN_DISEASE_HISTORY_UPDATE, formData)
      .then((res) => res && notify("Naftalan Disease History Update!"))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              editDescription(data);
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      });
  };

  return (
    <div className="naftalan-history ">
      <div className="container">
        <form action="" className="naftalan-history-form ">
          <div className="description-acordion-area">
            <div
              className={
                accordionVisible === "az"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "az" ? "" : "az")
                }
              >
                <h3 className="acordion-caption">Azerbaijan</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{ height: accordionVisible === "az" ? 550 : 0 }}
              >
                <div className="form-group">
                  <label htmlFor="history_text_az">Text</label>
                  <Editor type={"naftalan-history"} locale={"az"} />
                </div>
              </div>
            </div>
            <div
              className={
                accordionVisible === "en"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "en" ? "" : "en")
                }
              >
                <h3 className="acordion-caption">English</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{ height: accordionVisible === "en" ? 550 : 0 }}
              >
                <div className="form-group">
                  <label htmlFor="history_text_en">Text</label>
                  <Editor type={"naftalan-history"} locale={"en"} />
                </div>
              </div>
            </div>
            <div
              className={
                accordionVisible === "ru"
                  ? "accordion-group  active"
                  : "accordion-group "
              }
            >
              <div
                className="axordion-head "
                onClick={() =>
                  setAccordionVisible(accordionVisible === "ru" ? "" : "ru")
                }
              >
                <h3 className="acordion-caption">Russian</h3>
                <div className="icon">
                  <img src={down} alt="down" />
                </div>
              </div>
              <div
                className="acrodion-body"
                style={{ height: accordionVisible === "ru" ? 550 : 0 }}
              >
                <div className="form-group">
                  <label htmlFor="history_text_ru">Text</label>
                  <Editor type={"naftalan-history"} locale={"ru"} />
                </div>
              </div>
            </div>
          </div>
          <div className="form-footer">
            <div className="handle-area">
              <p className="error-text">{errorText}</p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    naftalanHistoryEditorValue.az === "" ||
                    naftalanHistoryEditorValue.az === "<p><br></p>"
                  ) {
                    setAccordionVisible("az");
                  } else if (
                    naftalanHistoryEditorValue.en === "" ||
                    naftalanHistoryEditorValue.en === "<p><br></p>"
                  ) {
                    setAccordionVisible("en");
                  } else if (
                    naftalanHistoryEditorValue.ru === "" ||
                    naftalanHistoryEditorValue.ru === "<p><br></p>"
                  ) {
                    setAccordionVisible("ru");
                  } else {
                    editDescription();
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NaftalanDiseaseHistory;
