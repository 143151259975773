// React
import { useContext } from "react";

// Context
import { MainContext } from "../utils/MainContext";

// Icon
import menuIcon from "../assets/images/icon/burger-menu.svg";

import SettingSection from "./setting-section/SettingSection";
// import FeaturedHotelOffer from "./setting-section/FeaturedHotelOffer";
import Logo from "./setting-section/Logo";
import Favicon from "./setting-section/Favicon";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SocialMedia from "./setting-section/SocialMedia";

const Setting = () => {
  // Global State
  const { mainMenuVisible, setMainMenuVisible } = useContext(MainContext);

  return (
    <main>
      <section className="setting">
        <div className="sub-header">
          <div className="container">
            <div className="row">
              <div className="info">
                <button
                  className=" burger-menu "
                  onClick={() => setMainMenuVisible(true)}
                >
                  <img src={menuIcon} alt="menu" />
                </button>
                <h2 className="caption">Setting</h2>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            mainMenuVisible ? "overlay-sub-menu active" : "overlay-sub-menu"
          }
          onClick={() => mainMenuVisible && setMainMenuVisible(false)}
        ></div>
        <div className="container">
          {/* Setting */}
          <SettingSection />

          {/* Featured Offer Category */}
          {/* <FeaturedHotelOffer /> */}

          {/* Social Media */}
          <SocialMedia />

          {/* Logo */}
          <Logo />

          {/* Favicon */}
          <Favicon />
        </div>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </main>
  );
};

export default Setting;
