// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

import arrow from "../../assets/images/icon/arrow-left.svg";
import arrowDown from "../../assets/images/icon/arrow-down.svg";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";
import { notify } from "../../utils/ToastIfyNonfoction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const AttributePageSideBar = () => {
  // Global State
  const {
    attributeData,
    atributePageSideBarVisible,
    setAtributePageSideBarVisible,
    selectAttribute,
    setAttributeData,
    setSelectAttribute,
    attributeProccesType,
    atributeActiveTab,
    getAllHottelAtributeGroup,
    searchHotelAttributeGroup,
    attributeGroupData,
    searchSuitAttributeGroup,
    getAllSuitAtributeGroup,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [errorText, setErrorText] = useState("");
  const [dropDownVisible, setDropDownVisible] = useState(null);
  // const [listData, setListData] = useState([]);
  const [chooseGroup, setChooseGroup] = useState({
    id: "",
    attribute_group: "",
    attribute_group_name_az: "",
    attribute_group_name_en: "",
    attribute_group_name_ru: "",
    group_associated_attributes: [],
  });

  // Navigate
  const navigate = useNavigate();
  // Yup schema
  const attributeSchema = object({
    attribute_associated_group: string().required().trim(),
    attribute_name_az: string().required().trim(),
    attribute_name_en: string().required().trim(),
    attribute_name_ru: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(attributeSchema),
  });

  useEffect(() => {
    if (atributePageSideBarVisible) {
      reset();
      setChooseGroup({
        id: "",
        attribute_group: "",
        attribute_group_name_az: "",
        attribute_group_name_en: "",
        attribute_group_name_ru: "",
        group_associated_attributes: [],
      });
      if (selectAttribute.attribute_group && attributeProccesType === "edit") {
        setValue(
          "attribute_associated_group",
          selectAttribute.attribute_group_name_en
        );

        setChooseGroup({
          id: Number(selectAttribute.attribute_group_id),
          attribute_group: selectAttribute.attribute_group,
          attribute_group_name_az: selectAttribute.attribute_group_name_az,
          attribute_group_name_en: selectAttribute.attribute_group_name_en,
          attribute_group_name_ru: selectAttribute.attribute_group_name_ru,
          group_associated_attributes: [],
        });

        setValue("attribute_name_az", selectAttribute.attribute_name_az);
        setValue("attribute_name_en", selectAttribute.attribute_name_en);
        setValue("attribute_name_ru", selectAttribute.attribute_name_ru);
      }
    }
  }, [
    atributePageSideBarVisible,
    attributeData,
    atributeActiveTab,
    reset,
    selectAttribute,
    setValue,
    attributeProccesType,
  ]);

  // Control Select or Search
  const dataController = (inp) => {
    if (atributeActiveTab === "Hotel Attributes") {
      if (inp.trim().length > 1) {
        searchHotelAttributeGroup(inp);
      } else {
        getAllHottelAtributeGroup();
      }
    } else {
      if (inp.trim().length > 1) {
        searchSuitAttributeGroup(inp);
      } else {
        getAllSuitAtributeGroup();
      }
    }
  };

  // Render
  useEffect(() => {
    if (atributePageSideBarVisible) {
      if (atributeActiveTab === "Hotel Attributes") {
        getAllHottelAtributeGroup();
      } else {
        getAllSuitAtributeGroup();
      }
    }
  }, [
    atributeActiveTab,
    atributePageSideBarVisible,
    getAllHottelAtributeGroup,
    getAllSuitAtributeGroup,
  ]);

  // Create && Edit
  const submit = async (data) => {
    const body = new FormData();

    body.append("attribute_associated_group", chooseGroup.id);
    body.append("attribute_name_az", data.attribute_name_az);
    body.append("attribute_name_en", data.attribute_name_en);
    body.append("attribute_name_ru", data.attribute_name_ru);

    /* ------------------------------ Check Process ----------------------------- */
    // Edit
    if (attributeProccesType === "edit") {
      if (atributeActiveTab === "Hotel Attributes") {
        // Free Night Edit Put Method
        try {
          const res = await axios.put(
            `${process.env.REACT_APP_SITE_HOTEL_ATTRIBUTE_UPDATE}${selectAttribute.id}            `,
            body
          );
          setAttributeData((prevData) => {
            return prevData.map((item) => {
              if (item.id === res.data.id) {
                return res.data;
              }
              return item;
            });
          });

          setAtributePageSideBarVisible(false);
          setSelectAttribute({
            attribute_associated_group: "",
            attribute_name_az: "",
            attribute_name_en: "",
            attribute_name_ru: "",
            id: "",
          });
          notify("Hotel Attributes Update!");
        } catch (err) {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                submit(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        }
      }
      // Suit Attribute Edit Put Method
      else {
        try {
          const res = await axios.put(
            `${process.env.REACT_APP_SITE_HOTEL_SUIT_ATTRIBUTE_UPDATE}${selectAttribute.id}`,
            body
          );

          setAttributeData((prevData) => {
            return prevData.map((item) => {
              if (item.id === res.data.id) {
                return res.data;
              }
              return item;
            });
          });
          setAtributePageSideBarVisible(false);
          setSelectAttribute({
            attribute_associated_group: "",
            attribute_name_az: "",
            attribute_name_en: "",
            attribute_name_ru: "",
            id: "",
          });
          notify("Suit Attributes Update!");
        } catch (err) {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                submit(data);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          } else {
            setErrorText(err.response?.data.errors);
          }
        }
      }
    } else {
      /* --------------------------------- Create --------------------------------- */
      /* ----------------------- Hotel Attribute Create ----------------------- */
      if (atributeActiveTab === "Hotel Attributes") {
        await axios
          .post(process.env.REACT_APP_SITE_HOTEL_ATTRIBUTE_CREATE, body)
          .then((res) => {
            setAttributeData([res.data, ...attributeData]);
            reset();
            setAtributePageSideBarVisible(false);
            setSelectAttribute({
              attribute_associated_group: "",
              attribute_name_az: "",
              attribute_name_en: "",
              attribute_name_ru: "",
              id: "",
            });
            notify("Hotel Attributes Create!");
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  submit(data);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            } else {
              setErrorText(err.response?.data.errors);
            }
          });
      } else {
        /* ----------------------- Suit Attribute Create ----------------------- */
        await axios
          .post(process.env.REACT_APP_SITE_HOTEL_SUIT_ATTRIBUTE_CREATE, body)
          .then((res) => {
            setAttributeData([res.data, ...attributeData]);
            reset();
            setAtributePageSideBarVisible(false);
            setSelectAttribute({
              attribute_associated_group: "",
              attribute_name_az: "",
              attribute_name_en: "",
              attribute_name_ru: "",
              id: "",
            });
            notify("Suit Attributes Create!");
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  submit(data);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            } else {
              setErrorText(err.response?.data.errors);
            }
          });
      }
    }
  };

  return (
    <div
      className={
        atributePageSideBarVisible
          ? " isOpenMenu sidebar-discount-menu scroll "
          : "sidebar-discount-menu  scroll  "
      }
      onClick={() => dropDownVisible && setDropDownVisible(null)}
    >
      <div className="head">
        <h4 className="caption">
          {atributeActiveTab === "Hotel Attributes"
            ? "Hotel Attributes"
            : "Suit Attributes"}
        </h4>
        <div
          className="icon"
          onClick={() => {
            setAtributePageSideBarVisible(false);
            setSelectAttribute({
              attribute_associated_group: "",
              attribute_name_az: "",
              attribute_name_en: "",
              attribute_name_ru: "",
              id: "",
            });
          }}
        >
          <img src={arrow} alt=" close" />
        </div>
      </div>
      <div className="body">
        <form
          action=""
          className="discount-form"
          onSubmit={handleSubmit(submit)}
        >
          <div
            className={`form-group ${
              dropDownVisible ? "select open" : "select"
            } `}
          >
            <label htmlFor="attribute_associated_group" className="inp-caption">
              Attrbute Group
            </label>
            <input
              type="text"
              name="attribute_associated_group"
              id="attribute_associated_group"
              className={
                errors.attribute_associated_group
                  ? "inp select-inp error"
                  : "inp select-inp"
              }
              placeholder="Search and Select Character"
              {...register("attribute_associated_group")}
              onChange={(e) => dataController(e.target.value)}
              onClick={() => setDropDownVisible(true)}
              autoComplete="off"
            />
            <div
              className="icon"
              onClick={(e) => {
                e.stopPropagation();
                setDropDownVisible(!dropDownVisible);
              }}
            >
              <img src={arrowDown} alt="open-close-dropdown" />
            </div>
            <div
              className="select-area"
              style={{
                height: dropDownVisible
                  ? attributeGroupData.length === 0
                    ? 60
                    : attributeGroupData.length > 3
                    ? 111.6
                    : attributeGroupData.length * 45
                  : 0,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <ul className="select-list scroll">
                {attributeGroupData.length === 0 ? (
                  <li className="select-item">Not Found</li>
                ) : (
                  attributeGroupData.map((item) => (
                    <li
                      className="select-item"
                      onClick={(e) => {
                        e.target.parentElement.parentElement.parentElement.children[1].classList.remove(
                          "error"
                        );

                        setDropDownVisible(false);
                        setValue(
                          "attribute_associated_group",
                          item.attribute_group_name_en
                        );
                        setChooseGroup(item);
                        //   getComment(activeNumber, item.id);
                      }}
                      key={item.id}
                    >
                      {item.attribute_group_name_en}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="attribute_name_az" className="inp-caption">
              Attribute name Az
            </label>
            <input
              type="text"
              className={errors.attribute_name_az ? "inp error" : "inp"}
              name="attribute_name_az"
              id="attribute_name_az"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("attribute_name_az")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="attribute_name_en" className="inp-caption">
              Attribute name En
            </label>
            <input
              type="text"
              className={errors.attribute_name_en ? "inp error" : "inp"}
              name="attribute_name_en"
              id="attribute_name_en"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("attribute_name_en")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="attribute_name_ru" className="inp-caption">
              Attribute name Ru
            </label>
            <input
              type="text"
              className={errors.attribute_name_ru ? "inp error" : "inp"}
              name="attribute_name_ru"
              id="attribute_name_ru"
              onChange={() => errorText !== "" && setErrorText("")}
              {...register("attribute_name_ru")}
            />
          </div>

          <div className="form-footer">
            <button>Save</button>
            <p className="error-text">{errorText}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AttributePageSideBar;
