import { useContext, useEffect } from "react";
import { MainContext } from "../../utils/MainContext";

import axios from "axios";
import HotelAtribute from "./HotelAtribute";
import HotelImg from "./HotelImg";
import HotelCoupon from "./HotelCoupon";
import { useNavigate } from "react-router-dom";
import HotelDetailDescriptoinAccordion from "../../components/acordion/HotelDetailDescriptoinAcordion";
import { useAuth } from "../../utils/AuthContext";
import HotelVideo from "./HotelVideo";

const HotelDetailDescription = ({ hotelId }) => {
  // Global State
  const { setHotelData, setHotelMainPhoto, setHotelAdditionPhoto } =
    useContext(MainContext);
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  useEffect(() => {
    if (hotelId) {
      axios
        .get(`${process.env.REACT_APP_SITE_HOTEL_READ_BY_ID}${hotelId}`)
        .then((res) => {
          setHotelData(res.data);
          setHotelMainPhoto(res.data.hotel_associated_main_image);
          setHotelAdditionPhoto(res.data.hotel_associated_images);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => res && navigate("/hotel"))
              .catch((err) => err && navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    }
  }, [
    hotelId,
    logout,
    navigate,
    setHotelAdditionPhoto,
    setHotelData,
    setHotelMainPhoto,
  ]);

  return (
    <div className="hotel-detail-description">
      <div className="container">
        {/* Attribute Section */}
        <HotelAtribute hotelId={hotelId} />

        {/*Coupon Section  */}
        <HotelCoupon />

        {/* Description Section */}
        <div className="discription-area ">
          <h2 className="main-caption">Description & Short description</h2>
          <HotelDetailDescriptoinAccordion />
        </div>

        {/* Img Section */}
        <HotelImg hotelId={hotelId} />

        {/* Video */}
        <HotelVideo  hotelId={hotelId} />
      </div>
    </div>
  );
};

export default HotelDetailDescription;
