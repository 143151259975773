// React
import { useCallback, useContext, useEffect, useState } from "react";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Toastie
import { notify } from "../../utils/ToastIfyNonfoction";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { MainContext } from "../../utils/MainContext";
import HotelEditor from "../../pages/hotel-detail-section/HotelEditor";
import { useAuth } from "../../utils/AuthContext";
const HotelDetailDescriptoinAccordion = () => {
  // Global state
  const {
    hotelDEscriptionLocale,
    hotelDetailActiveTab,
    hotelDescriptionEditorValue,
    setHotelDescriptionEditorValue,
    setHotelDEscriptionLocale,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [descriptionData, setDescriptionData] = useState([]);
  const [renderData, setRenderData] = useState(false);
  const [errorText, setErrorText] = useState("");

  // Params
  const { hotelId } = useParams();

  //Navigate
  const navigate = useNavigate();

  // Render
  useEffect(() => {
    if (hotelDetailActiveTab === "Description" && hotelDEscriptionLocale) {
      setRenderData(true);
    }
  }, [hotelDEscriptionLocale, hotelDetailActiveTab]);

  // Yup schema
  const descriptionSchema = object({
    description_short: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(descriptionSchema),
  });
  const getDescriptionData = useCallback(async () => {
    await axios
      .get(`${process.env.REACT_APP_SITE_HOTEL_DESCRIPTION_READ}${hotelId}`)
      .then((res) => {
        setDescriptionData(res.data);
        if (res.data.length !== 0) {
          res.data.map((item) => {
            if (item.description_locale === hotelDEscriptionLocale) {
              setValue(`description_short`, item.description_short);
              setHotelDescriptionEditorValue(item.description_long);
            }

            return true;
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && getDescriptionData())
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [
    hotelDEscriptionLocale,
    hotelId,
    logout,
    navigate,
    setHotelDescriptionEditorValue,
    setValue,
  ]);

  useEffect(() => {
    if (renderData) {
      setErrorText("");
      reset();
      setHotelDescriptionEditorValue("");
      getDescriptionData();
      setRenderData(false);
    }
  }, [getDescriptionData, renderData, reset, setHotelDescriptionEditorValue]);

  const editDescription = async (data) => {
    const body = new FormData();

    const checkVisibility = () => {
      if (descriptionData.length === 0) {
        return true;
      } else {
        for (const item of descriptionData) {
          if (item.description_locale === hotelDEscriptionLocale) {
            return false;
          }
        }
        return true;
      }
    };
    if (checkVisibility()) {
      body.append("description_short", data.description_short);
      body.append("description_long", hotelDescriptionEditorValue);
      body.append("description_associated_hotel", hotelId);

      body.append("description_locale", hotelDEscriptionLocale);

      try {
        const res = await axios.post(
          process.env.REACT_APP_SITE_HOTEL_DESCRIPTION_CREATE,
          body
        );
        setDescriptionData([...descriptionData, res.data]);

        notify("Description Create!");
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && editDescription(data))
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      }
    } else {
      let descriptionId = descriptionData.find(
        (item) => item.description_locale === hotelDEscriptionLocale && item.id
      );
      body.append("description_short", data.description_short);
      body.append("description_long", hotelDescriptionEditorValue);
      body.append("description_associated_hotel", hotelId);

      body.append("description_locale", hotelDEscriptionLocale);
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SITE_HOTEL_DESCRIPTION_UPTADE}${descriptionId.id}`,
          body
        );
        setDescriptionData((prevBannerData) =>
          prevBannerData.map((description) =>
            description.id === res.data.id ? res.data : description
          )
        );
        notify("Description Uptade!");
      } catch (err) {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => res && editDescription(data))
            .catch((err) => err && navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        } else {
          setErrorText(err.response?.data.errors);
        }
      }
    }
  };

  return (
    <div className="description-acordion-area">
      <div className="accordion-group  active">
        <div className="axordion-head tablist ">
          <h3
            className={
              hotelDEscriptionLocale === "az"
                ? `acordion-caption active`
                : `acordion-caption `
            }
            onClick={() => setHotelDEscriptionLocale("az")}
          >
            Azerbaijan
          </h3>
          <h3
            className={
              hotelDEscriptionLocale === "en"
                ? `acordion-caption active`
                : `acordion-caption`
            }
            onClick={() => setHotelDEscriptionLocale("en")}
          >
            English
          </h3>
          <h3
            className={
              hotelDEscriptionLocale === "ru"
                ? `acordion-caption active`
                : `acordion-caption`
            }
            onClick={() => setHotelDEscriptionLocale("ru")}
          >
            Russian
          </h3>
        </div>
        <form
          className="acrodion-body"
          onSubmit={handleSubmit(editDescription)}
          style={{ height: "auto" }}
        >
          <div className="form-group">
            <label htmlFor="description_short">Short Description</label>
            <textarea
              name="description_short"
              id="description_short"
              maxLength={254}
              className={errors.description_short ? "scroll error" : "scroll"}
              {...register("description_short")}
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="description_long">Long description</label>
            <HotelEditor type={"Long description"} />
          </div>
          <div className="form-footer">
            <div className="handle-area">
              <p className="error-text"> {errorText}</p>

              <button>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default HotelDetailDescriptoinAccordion;
