// Icon
import trash from "../../assets/images/icon/trash.svg";
import add from "../../assets/images/icon/add-plus.svg";
import edit from "../../assets/images/icon/edit.svg";
import { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../../utils/MainContext";
import axios from "axios";

// Toastie
import { createRemoveAlert } from "../../utils/SweetAlert";
import FaqSideBar from "../../components/side-bar-menu/FaqSideBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
const FAQ = () => {
  // Global State
  const {
    homeActiveTab,
    faqData,
    setFaqData,
    setSelectFaq,
    faqVisible,
    setFaqVisible,
  } = useContext(MainContext);
  const { logout } = useAuth();

  // Navigate
  const navigate = useNavigate();

  // Local State
  const [type, setType] = useState("");
  // Local State
  const [prevNumber, setPrevNumber] = useState(0);
  const [activeNumber, setActiveNumber] = useState(1);
  const [nextNumber, setNextNumber] = useState(2);

  const getFaqData = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_SITE_FAQ_READ}?page_length=20&page_number=${page_number}`
        )
        .then((res) => setFaqData(res.data))
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                getFaqData(page_number);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setFaqData]
  );
  useEffect(() => {
    if (homeActiveTab === "FAQ") {
      getFaqData(1);
    }
  }, [getFaqData, homeActiveTab]);

  /* ------------------------------- Change Page ------------------------------ */
  const handleChange = (step) => {
    let newPrevNumber = prevNumber;
    let newActiveNumber = activeNumber;
    let newNextNumber = nextNumber;

    if (step === "prev" && prevNumber > 0) {
      newPrevNumber = prevNumber - 1;
      newActiveNumber = activeNumber - 1;
      newNextNumber = nextNumber - 1;
    } else {
      newPrevNumber = prevNumber + 1;
      newActiveNumber = activeNumber + 1;
      newNextNumber = nextNumber + 1;
    }

    setPrevNumber(newPrevNumber);
    setActiveNumber(newActiveNumber);
    setNextNumber(newNextNumber);

    getFaqData(newActiveNumber);
  };

  const removeData = (dataId) => {
    createRemoveAlert(
      "Delete Diseases!",
      `Are you sure you want to delete the ID:${dataId} Diseases?`,
      "Yes, Remove",
      async () => {
        await axios
          .delete(`${process.env.REACT_APP_SITE_FAQ_DELETE}/${dataId}`)
          .then((res) => {
            if (res.status === 200) {
              setFaqData((prevState) => ({
                ...prevState,
                page_data: prevState.page_data.filter(
                  (item) => item.id !== dataId
                ),
              }));
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };
  return (
    <div className="container">
      <div className="faq">
        <div className="edit-area">
          <h6 className="title">Add Diseases</h6>
          <button
            className="add-btn"
            onClick={() => {
              setFaqVisible(true);
              setType("create");
              setSelectFaq({
                question_title_az: "",
                question_answer_az: "",
                question_title_ru: "",
                question_answer_ru: "",
                question_title_en: "",
                question_answer_en: "",
                id: "",
              });
            }}
          >
            Add <img src={add} alt="add" />
          </button>
        </div>
        <div className="faq-body">
          <table className="table">
            <thead>
              <tr>
                <th className="first">ID</th>
                <th className="second">Title</th>
                <th className="edit">Edit && Delete </th>
              </tr>
            </thead>
            <tbody>
              {faqData &&
                faqData.page_data.map((faq) => (
                  <tr key={faq.id}>
                    <td className="first">{faq.id}</td>
                    <td className="second">{faq.question_title_en}</td>

                    <td className="edit">
                      <div className="btn-area">
                        <button onClick={() => removeData(faq.id)}>
                          <img src={trash} alt="delete" />
                        </button>
                        <button
                          onClick={() => {
                            setFaqVisible(true);
                            setSelectFaq(faq);
                            setType("edit");
                          }}
                        >
                          <img src={edit} alt="edit" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {faqData && (
            <div className="pagination">
              <button
                className="btn prev"
                onClick={() => handleChange("prev")}
                disabled={!faqData.page_has_previous}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM17.685 19.5C18.12 19.935 18.12 20.655 17.685 21.09C17.46 21.315 17.175 21.42 16.89 21.42C16.605 21.42 16.32 21.315 16.095 21.09L10.8 15.795C10.365 15.36 10.365 14.64 10.8 14.205L16.095 8.91C16.53 8.475 17.25 8.475 17.685 8.91C18.12 9.345 18.12 10.065 17.685 10.5L13.185 15L17.685 19.5Z"
                    fill=" #AD471F"
                  />
                </svg>
              </button>
              {prevNumber !== 0 && (
                <button
                  className="btn prev-number"
                  onClick={() => handleChange("prev")}
                >
                  {prevNumber}
                </button>
              )}
              <button className="btn active">{activeNumber}</button>
              {faqData && faqData.page_has_next && (
                <button
                  className="btn next-number"
                  onClick={() => handleChange("next")}
                >
                  {nextNumber}
                </button>
              )}

              {faqData && (
                <button
                  className="btn next"
                  disabled={!faqData.page_has_next}
                  onClick={() => handleChange("next")}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM19.185 15.795L13.89 21.09C13.665 21.315 13.38 21.42 13.095 21.42C12.81 21.42 12.525 21.315 12.3 21.09C11.865 20.655 11.865 19.935 12.3 19.5L16.8 15L12.3 10.5C11.865 10.065 11.865 9.345 12.3 8.91C12.735 8.475 13.455 8.475 13.89 8.91L19.185 14.205C19.635 14.64 19.635 15.36 19.185 15.795Z"
                      fill=" #AD471F"
                    />
                  </svg>
                </button>
              )}
            </div>
          )}
        </div>
        <div
          className={
            faqVisible ? "overlay-sub-menu active" : "overlay-sub-menu"
          }
          onClick={() => {
            setFaqVisible(false);
            setSelectFaq({
              question_title_az: "",
              question_answer_az: "",
              question_title_ru: "",
              question_answer_ru: "",
              question_title_en: "",
              question_answer_en: "",
              id: "",
            });
          }}
        ></div>
        <FaqSideBar type={type} />
      </div>
    </div>
  );
};

export default FAQ;
