// React
import { useCallback, useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Axios
import axios from "axios";

// Params
import { useNavigate, useParams } from "react-router-dom";

// Icon
import add from "../../assets/images/icon/add-plus.svg";
import trash from "../../assets/images/icon/trash.svg";
import edit from "../../assets/images/icon/edit.svg";
import { createRemoveAlert } from "../../utils/SweetAlert";
import { useAuth } from "../../utils/AuthContext";
const HotelDetailRooms = () => {
  // Navigate
  const navigate = useNavigate();

  // Params
  const { hotelId } = useParams();

  // Global State
  const { hotelDetailActiveTab, setHotelDetailActiveTab, setSelectRoomID } =
    useContext(MainContext);
  const { logout } = useAuth();

  // Local State
  const [roomData, setRoomData] = useState([]);

  const getRoomData = useCallback(async () => {
    await axios
      .get(`${process.env.REACT_APP_SITE_ROOM_READ_ALL_BY_HOTEL_ID}${hotelId}`)
      .then((res) => setRoomData(res.data))
      .catch((err) => {
        if (err.response.status === 401) {
          axios
            .get(process.env.REACT_APP_REFRESH_TOKEN)
            .then((res) => {
              getRoomData();
            })
            .catch((err) => navigate("/login"));
        } else if (err.response.status === 403) {
          logout();
          navigate("/login");
        }
      });
  }, [hotelId, logout, navigate]);

  // Rendering Data
  useEffect(() => {
    if (hotelDetailActiveTab === "Rooms" && hotelId) {
      getRoomData();
    }
  }, [getRoomData, hotelDetailActiveTab, hotelId]);

  // Delete Hotel
  const removeData = (dataId) => {
    createRemoveAlert(
      "Delete Room!",
      "Are you sure you want to delete the Room?",
      "Yes, Remove",
      async () => {
        await axios
          .delete(`${process.env.REACT_APP_SITE_ROOM_DELETE}${dataId}`)
          .then((res) => {
            if (res.status === 200) {
              const updateRoom = roomData.filter(
                (hotel) => hotel.id !== dataId
              );
              setRoomData(updateRoom);
            }
            // setAllHotel(res.data.page_data);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };

  return (
    <form className="hotel-detail-rooms">
      <div className="container">
        <div className="rooms-head">
          <h2 className="main-caption">Rooms</h2>
          <div className="add-area">
            <h4 className="add-caption">Add room</h4>
            <button
              className="add-btn"
              onClick={() => setHotelDetailActiveTab("Room Add")}
            >
              Add <img src={add} alt="add" />
            </button>
          </div>
        </div>
        <div className="rooms-body">
          <table className="table">
            <thead>
              <tr>
                <th className="first">Id</th>
                <th className="first">Room Name</th>
                <th className="first">Suit Type</th>
                <th className="first">Price Type</th>
                <th className="first">Active</th>
                <th className="edit">Edit && Delete </th>
              </tr>
            </thead>
            <tbody>
              {roomData.length !== 0 &&
                roomData.map((room) => (
                  <tr key={room.id}>
                    <td className="first">{room.id}</td>
                    {/* <td className="first">
                      {room.suite_associated_descriptions.length === 0
                        ? "Please fill in the room details"
                        : room.suite_associated_descriptions.map(
                            (name) =>
                              name.description_locale === "en" &&
                              name.description_suite_name
                          )}
                    </td> */}
                    <td className="first">
                      {room.suite_associated_descriptions.length === 0
                        ? "Please fill in the room details"
                        : room.suite_associated_descriptions
                            .map((name) => {
                              if (name.description_locale === "en") {
                                return name.description_suite_name;
                              }
                              return null;
                            })
                            .filter((description) => description !== null)
                            .length === 0
                        ? "Please fill in the room details"
                        : room.suite_associated_descriptions.map((name) => {
                            if (name.description_locale === "en") {
                              return (
                                <div key={name.description_suite_name}>
                                  {name.description_suite_name}
                                </div>
                              );
                            }
                            return null;
                          })}
                    </td>
                    <td className="first">{room.suite_type}</td>
                    <td className="first">{room.suite_pricing_type}</td>
                    <td className="first">
                      {" "}
                      {room.suite_active ? "Activated" : "Deactivate"}
                    </td>

                    <td className="edit">
                      <div className="btn-area">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            removeData(room.id);
                          }}
                        >
                          <img src={trash} alt="trash" />
                        </button>
                        <button
                          onClick={() => {
                            setHotelDetailActiveTab("Room Detail");

                            setSelectRoomID(room.id);
                          }}
                        >
                          <img src={edit} alt="edit" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
};

export default HotelDetailRooms;
