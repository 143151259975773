// React
import React, { useCallback, useContext, useEffect } from "react";

// Context
import { MainContext } from "../utils/MainContext";

// Axios
import axios from "axios";

// Component
import EmailSideBarMenu from "../components/side-bar-menu/EmailSideBarMenu";

// Icon
import menuIcon from "../assets/images/icon/burger-menu.svg";
import edit from "../assets/images/icon/edit.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";

const EmailTemplates = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    mainMenuVisible,
    setMainMenuVisible,
    emailData,
    setEmailData,
    selectEmail,
    setSelectEmail,
    emailVisible,
    setEmailVisible,
  } = useContext(MainContext);

  const { logout } = useAuth();

  // Locale Data

  // Get Email Data Schema
  const getEmailData = useCallback(
    async (page_number) => {
      await axios
        .get(process.env.REACT_APP_SITE_EMAIL_TEMPLATES_READ_ALL)
        .then((res) => {
          setEmailData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            axios
              .get(process.env.REACT_APP_REFRESH_TOKEN)
              .then((res) => {
                getEmailData(page_number);
              })
              .catch((err) => navigate("/login"));
          } else if (err.response.status === 403) {
            logout();
            navigate("/login");
          }
        });
    },
    [logout, navigate, setEmailData]
  );

  // Rendering DAta
  useEffect(() => {
    getEmailData();
  }, [getEmailData]);
  return (
    <main>
      <section className="email-templates">
        <div className="sub-header">
          <div className="container">
            <div className="row">
              <div className="info">
                <button
                  className=" burger-menu "
                  onClick={() => setMainMenuVisible(true)}
                >
                  <img src={menuIcon} alt="menu" />
                </button>
                <h2 className="caption">Email templates</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="email-templates-body">
          <div className="container">
            <table className="table">
              <thead>
                <tr>
                  <th className="first">ID</th>
                  <th className="second">Template name</th>
                  <th className="edit">Edit && Delete </th>
                </tr>
              </thead>
              <tbody>
                {emailData.length !== 0 &&
                  emailData.map((email) => (
                    <tr key={email.id}>
                      <td className="first">{email.id}</td>
                      <td className="second">{email.template_name}</td>

                      <td className="edit">
                        <div className="btn-area">
                          {/* <button>
                            <img src={trash} alt="delete" />
                          </button> */}
                          <button
                            onClick={() => {
                              setEmailVisible(true);
                              setSelectEmail(email);
                            }}
                          >
                            <img src={edit} alt="edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={
            emailVisible || mainMenuVisible
              ? "overlay-sub-menu active"
              : "overlay-sub-menu"
          }
          onClick={() => {
            mainMenuVisible
              ? setMainMenuVisible(false)
              : setEmailVisible(false);
          }}
        ></div>
        {selectEmail && <EmailSideBarMenu selectEmail={selectEmail} />}
      </section>
    </main>
  );
};

export default EmailTemplates;
