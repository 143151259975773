import React, { useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill tema stilleri
import { MainContext } from "../utils/MainContext";

const Editor = ({ type, locale }) => {
  const {
    customPageEditorValue,
    setCustomPageEditorValue,
    naftalanHistoryEditorValue,
    setNaftalanHistoryEditorValue,
  } = useContext(MainContext);
  // const [value, setValue] = useState("");
  const handleChange = (content, delta, source, editor) => {
    if (type === "naftalan-history") {
      setNaftalanHistoryEditorValue((prevData) => ({
        ...prevData,
        [`${locale}`]: content,
      }));
    } else {
      setCustomPageEditorValue(content);
    }
  };

  return (
    <div className="editor-component">
      <div className="editor-header">
        <h1>React Quill Demo</h1>
      </div>
      <div className="editor-area">
        <ReactQuill
          value={
            type === "naftalan-history"
              ? naftalanHistoryEditorValue[`${locale}`]
              : customPageEditorValue
          }
          onChange={handleChange}
          modules={Editor.modules}
          formats={Editor.formats}
        />
      </div>
    </div>
  );
};

Editor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      // { indent: "-1" },
      // { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  // "indent",
  "link",
  "image",
  "video",
];

export default Editor;

// src/App.js

// import React, { useState } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // Quill tema stilleri

// const Editor = () => {
//   const [value, setValue] = useState('');

//   const handleChange = (content, delta, source, editor) => {
//     setValue(content);
//   };

//   return (
//     <div className="App">
//       <header className="App-header">
//         <h1>React Quill Demo</h1>
//       </header>
//       <main>
//         <ReactQuill
//           value={value}
//           onChange={handleChange}
//           modules={Editor.modules}
//           formats={Editor.formats}
//         />
//         <div className="output">
//           <h3>HTML Output</h3>
//           <div dangerouslySetInnerHTML={{ __html: value }} />
//         </div>
//       </main>
//     </div>
//   );
// };

// Editor.modules = {
//   toolbar: [
//     [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
//     [{size: []}],
//     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//     [{'list': 'ordered'}, {'list': 'bullet'},
//      {'indent': '-1'}, {'indent': '+1'}],
//     ['link', 'image', 'video'],
//     ['clean']
//   ],
//   clipboard: {
//     matchVisual: false,
//   }
// };

// Editor.formats = [
//   'header', 'font', 'size',
//   'bold', 'italic', 'underline', 'strike', 'blockquote',
//   'list', 'bullet', 'indent',
//   'link', 'image', 'video'
// ];

// export default Editor;
