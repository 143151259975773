import React, { useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill tema stilleri
import { MainContext } from "../../utils/MainContext";
const HotelEditor = ({ type }) => {
  const {
    hotelDescriptionEditorValue,
    setHotelDescriptionEditorValue,
    roomDescriptionNoteEditorValue,
    setRoomDescriptionNoteEditorValue,
  } = useContext(MainContext);
  // const [value, setValue] = useState("");
  const handleChange = (content, delta, source, editor) => {
    if (type === "Note") {
      setRoomDescriptionNoteEditorValue(content);
    } else {
      setHotelDescriptionEditorValue(content);
    }
  };

  return (
    <div className="editor-component">
      <div className="editor-header">
        <h1>React Quill Demo</h1>
      </div>
      <div className="editor-area">
        <ReactQuill
          value={
            type === "Note"
              ? roomDescriptionNoteEditorValue
              : hotelDescriptionEditorValue
          }
          onChange={handleChange}
          modules={HotelEditor.modules}
          formats={HotelEditor.formats}
        />
      </div>
    </div>
  );
};

HotelEditor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      // { indent: "-1" },
      // { indent: "+1" },
    ],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

HotelEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  // "indent",
];

export default HotelEditor;
