import { useContext, useEffect } from "react";

// Utils
import { MainContext } from "../../utils/MainContext";
import { createRemoveAlert } from "../../utils/SweetAlert";

// Axios
import axios from "axios";

// Toastie
import "react-toastify/dist/ReactToastify.css";

// Icon
import trash from "../../assets/images/icon/trash.svg";
import edit from "../../assets/images/icon/edit.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";

const HotelAttribueGroup = () => {
  // Navigate
  const navigate = useNavigate();

  // Global State
  const {
    // Group
    setAtributeGroupSideBarVisible,

    atributeActiveTab,
    setAttributeProccesType,
    setSelectAttributeGroup,

    // Pagination
    setAttributePrevNumber,
    setAttributeActiveNumber,
    setAttributeNextNumber,

    // Data
    attributeGroupData,
    setAttributeGroupData,

    // Funtion
    getAllHottelAtributeGroup,
    getHotelAttribute,
  } = useContext(MainContext);

  const { logout } = useAuth();

  // RenderingDAta
  useEffect(() => {
    if (atributeActiveTab === "Hotel Attribute Groups") {
      setAttributeGroupData([]);
      getAllHottelAtributeGroup();
    }
  }, [
    atributeActiveTab,
    getAllHottelAtributeGroup,
    getHotelAttribute,
    setAttributeActiveNumber,
    setAttributeGroupData,
    setAttributeNextNumber,
    setAttributePrevNumber,
  ]);

  // Delete Coupon
  const removeData = (dataId, attribute_group) => {
    createRemoveAlert(
      "Delete Attribute!",
      `Are you sure you want to delete the ${attribute_group} Attribute?`,
      "Yes, Remove",
      async () => {
        await axios
          .delete(
            `${process.env.REACT_APP_SITE_HOTEL_ATTRIBUTE_GROUP_DELETE}/${dataId}`
          )
          .then((res) => {
            if (res.status === 200) {
              const updateCoupon = attributeGroupData.filter(
                (item) => item.id !== dataId
              );
              setAttributeGroupData(updateCoupon);
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(process.env.REACT_APP_REFRESH_TOKEN)
                .then((res) => {
                  removeData(dataId, attribute_group);
                })
                .catch((err) => navigate("/login"));
            } else if (err.response.status === 403) {
              logout();
              navigate("/login");
            }
          });
      }
    );
  };

  return (
    <div className="container">
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Group label</th>
            <th>Group Name (Az)</th>
            <th>Group Name (En)</th>
            <th>Group Name (Ru)</th>
            <th>Edit && Delete </th>
          </tr>
        </thead>
        <tbody>
          {attributeGroupData.length !== 0 &&
            attributeGroupData.map((item) => (
              <tr key={item.id}>
                <td className="carImg">{item.id}</td>
                <td>{item.attribute_group}</td>
                <td>{item.attribute_group_name_az}</td>
                <td>{item.attribute_group_name_en}</td>
                <td>{item.attribute_group_name_ru}</td>

                <td className="edit">
                  <div className="btn-area">
                    <button
                      onClick={() => removeData(item.id, item.attribute_group)}
                    >
                      <img src={trash} alt="trash" />
                    </button>
                    <button
                      onClick={() => {
                        setAttributeProccesType("edit");
                        setSelectAttributeGroup(item);
                        setAtributeGroupSideBarVisible(true);
                      }}
                    >
                      <img src={edit} alt="edit" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {/* {attributePaginationData && (
      <div className="pagination">
        <button
          className="btn prev"
          onClick={() => handleChange("prev")}
          disabled={!attributePaginationData.page_has_previous}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM17.685 19.5C18.12 19.935 18.12 20.655 17.685 21.09C17.46 21.315 17.175 21.42 16.89 21.42C16.605 21.42 16.32 21.315 16.095 21.09L10.8 15.795C10.365 15.36 10.365 14.64 10.8 14.205L16.095 8.91C16.53 8.475 17.25 8.475 17.685 8.91C18.12 9.345 18.12 10.065 17.685 10.5L13.185 15L17.685 19.5Z"
              fill=" #AD471F"
            />
          </svg>
        </button>
        {attributeprevNumber !== 0 && (
          <button
            className="btn prev-number"
            onClick={() => handleChange("prev")}
          >
            {attributeprevNumber}
          </button>
        )}
        <button className="btn active">{attributeActiveNumber}</button>
        {attributePaginationData && attributePaginationData.page_has_next && (
          <button
            className="btn next-number"
            onClick={() => handleChange("next")}
          >
            {attributeNextNumber}
          </button>
        )}

        {attributePaginationData && (
          <button
            className="btn next"
            disabled={!attributePaginationData.page_has_next}
            onClick={() => handleChange("next")}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM19.185 15.795L13.89 21.09C13.665 21.315 13.38 21.42 13.095 21.42C12.81 21.42 12.525 21.315 12.3 21.09C11.865 20.655 11.865 19.935 12.3 19.5L16.8 15L12.3 10.5C11.865 10.065 11.865 9.345 12.3 8.91C12.735 8.475 13.455 8.475 13.89 8.91L19.185 14.205C19.635 14.64 19.635 15.36 19.185 15.795Z"
                fill=" #AD471F"
              />
            </svg>
          </button>
        )}
      </div>
    )} */}
    </div>
  );
};

export default HotelAttribueGroup;
